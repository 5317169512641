import { component, factory } from 'tsdi';

/** @see https://github.com/fuse-box/fuse-box/issues/1220 */
export function workaroundProcessEnv(): void {
    // tslint:disable-next-line:no-any
    const global = window as any;

    global.process =
        typeof global.process === 'undefined' ? {} : global.process;
    global.process.env =
        typeof global.process.env === 'undefined' ? {} : global.process.env;
    global.process.cwd =
        typeof global.process.cwd === 'undefined'
            ? () => undefined
            : global.process.cwd;
}

@component
export class WindowFactory {
    @factory({ name: 'Window' })
    public getWindow(): Window {
        return window;
    }
}
