import {
    ChallengeTeamRuleSetDto,
    ChallengeType
} from '@core/api/ChallengeClient';
import type { ChallengeDetailsManagementDto } from '@core/api/ChallengeClient';
import { autodisposer } from '@core/reactions';
import { injectTSDI } from '@core/tsdi';
import { FieldState, FormState } from 'formstate';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { component } from 'tsdi';
import { ChallengeDataStore } from '../../data-store';
import { formErrors, isFormStateDirty, makeFormClean } from '@core/utils/form';
import { wrapInFormField } from '@core/forms';
import { atLeastOne, required } from '@core/utils/validators';
import { wrapRequest } from 'wrap-request';
import { Option } from '@components/multi-select';
import { GatewayApi } from '@core/gateway-api';
import { I18n } from '@core/i18n';
import { bind } from 'lodash-decorators';
import { StepStatus } from '../../store';

export type ChallengeTeamRulesFormType = ReturnType<
    TeamRulesStore['generateForm']
>;

@component
export class TeamRulesStore {
    constructor() {
        this.form = this.generateForm(
            this.challengeDataStore.challengeConfiguration
        );

        makeObservable(this, {
            form: observable,
            fields: computed,
            errors: computed
        });

        this.leaguesEntries.request();
    }

    public form: ChallengeTeamRulesFormType;
    public state: StepStatus = StepStatus.INCOMPLETE;

    private get challengeDataStore() {
        return injectTSDI(ChallengeDataStore);
    }
    private get __() {
        return injectTSDI(I18n).__;
    }

    private get challengeClient() {
        return injectTSDI(GatewayApi).challengeClient;
    }

    private generateForm(configuration?: ChallengeDetailsManagementDto) {
        return new FormState({
            leaderboardEnabled: new FieldState<boolean>(
                Boolean(
                    configuration?.ruleConfig?.teamRuleSet?.leaderboardEnabled
                ) || true
            ),
            teamIds: new FieldState<number[]>(
                configuration?.ruleConfig?.teamRuleSet?.teamIds || []
            ).validators(atLeastOne(this.__('team.rules.teamIds.validation'))),
            strategy: new FieldState<ChallengeType>(
                configuration?.ruleConfig?.teamRuleSet?.strategyConfig
                    .strategy || ChallengeType.HIGH_SCORE
            ).validators(required()),
            pointsGoal: new FieldState<number | undefined>(
                configuration?.ruleConfig?.teamRuleSet?.strategyConfig.pointsGoal
            ).validators((val) => this.pointsGoalValidator(val)),
            allowedWinnersCount: new FieldState<number | undefined>(
                configuration?.ruleConfig?.teamRuleSet?.strategyConfig.allowedWinnersCount
            ).validators((val) => this.allowedWinnersCountValidator(val))
        });
    }

    @bind
    private pointsGoalValidator(val: number | undefined): I18nKey | undefined {
        if (this.fields.strategy.value === ChallengeType.RACE && !val) {
            return 'challenge.field.goal';
        }
        return undefined;
    }
    @bind
    private allowedWinnersCountValidator(
        val: number | undefined
    ): I18nKey | undefined {
        if (this.fields.strategy.value === ChallengeType.HIGH_SCORE && !val) {
            return 'cp.challenge.field.number.of.winners.error';
        }
        return undefined;
    }

    public get fields() {
        return {
            leaderboardEnabled: wrapInFormField(this.form.$.leaderboardEnabled),
            teamIds: wrapInFormField(this.form.$.teamIds),
            strategy: wrapInFormField(this.form.$.strategy),
            pointsGoal: wrapInFormField(this.form.$.pointsGoal),
            allowedWinnersCount: wrapInFormField(
                this.form.$.allowedWinnersCount
            )
        };
    }

    public get errors() {
        return formErrors(this.form);
    }

    public leaguesEntries = wrapRequest(
        async () => {
            const response =
                await this.challengeClient?.management?.leagueManagementControllerGetLeaguesEntries();
            return (
                response?.data
                    .filter((league) => league.teams.length > 0)
                    .map(({ teams, ...rest }) => ({
                        ...rest,
                        children: teams
                    })) || []
            );
        },
        { defaultData: [] }
    );

    @bind
    public static async teamsRequest(teamIds?: number[]) {
        const challengeClient = injectTSDI(GatewayApi).challengeClient;
        const request =
            await challengeClient?.management?.teamManagementControllerGetTeams?.(
                {
                    page: 0,
                    size: 100,
                    ...(teamIds && { ids: teamIds })
                }
            );

        return (
            request?.data.content.map<Option<number>>((item) => ({
                label: item.name || '',
                value: item.id
            })) || []
        );
    }

    public generateRequestData(
        form: ChallengeTeamRulesFormType
    ): ChallengeTeamRuleSetDto {
        const {
            $: {
                leaderboardEnabled,
                strategy,
                pointsGoal,
                allowedWinnersCount,
                teamIds
            }
        } = form;

        return {
            leaderboardEnabled: leaderboardEnabled.value,
            strategyConfig: {
                strategy: strategy.value,
                pointsGoal: pointsGoal.value,
                allowedWinnersCount: allowedWinnersCount.value
            },
            teamIds: teamIds.value
        };
    }

    public get formIsDirty() {
        return isFormStateDirty(this.form.$);
    }

    @bind
    public async onChangeHandle() {
        const validation = await this.form.validate();
        if (validation.hasError) {
            this.state = StepStatus.ERROR;
        } else {
            this.state = StepStatus.MODIFIED;
        }
    }

    @bind
    public async updateForm(configuration?: ChallengeDetailsManagementDto) {
        this.form = this.generateForm(configuration);
        const validation = await this.form.validate();
        if (validation.hasError) {
            this.state = StepStatus.ERROR;
            return;
        }
        this.state = StepStatus.COMPLETED;
        makeFormClean(this.form);
        return;
    }

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.challengeDataStore.challengeConfiguration,
                async (configuration) => {
                    if (configuration) {
                        this.updateForm(configuration);
                    }
                }
            )
        ];
    }
}
