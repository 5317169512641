import { reaction, makeAutoObservable } from 'mobx';
import { component, initialize } from 'tsdi';
import { Api } from '../core/api';
import '../core/storage';
import { injectTSDI } from '../core/tsdi';
import { UserStore } from './../core/user';

const STORAGE_KEY_DRAWER_OPEN = 'drawer_open';

@component
export class AppStore {
    private get api() {
        return injectTSDI(Api);
    }

    private get localStorage() {
        return injectTSDI(Storage, 'LocalStorage');
    }

    private get userStore() {
        return injectTSDI(UserStore);
    }

    public drawerOpen = true;
    public mobile = false;

    public drawerToggle() {
        this.drawerOpen = !this.drawerOpen;

        this.localStorage.setItem(
            STORAGE_KEY_DRAWER_OPEN,
            String(this.drawerOpen)
        );
    }

    public get showTeamsNav() {
        return this.userStore.productType === 'TEAMS';
    }

    @initialize
    public init() {
        makeAutoObservable(this, {}, { autoBind: true });
        reaction(
            () => this.api.loggedIn,
            (loggedIn) => {
                const cookieValue = this.localStorage.getItem(
                    STORAGE_KEY_DRAWER_OPEN
                );
                const cookieDrawerOpen = cookieValue === 'true';

                if (!loggedIn) {
                    this.drawerOpen = false;
                } else {
                    this.drawerOpen = cookieValue ? cookieDrawerOpen : true;
                }
            },
            { fireImmediately: true }
        );
    }
}
