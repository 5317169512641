import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

interface SpinnerProps {
    color?: 'white';
}

export function Spinner(props: SpinnerProps) {
    return (
        <CircularProgress
            sx={{
                color: props.color ? '#fff' : undefined
            }}
            color="primary"
            size={24}
        />
    );
}

export const SpinnerContainer = styled.div<{ height?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ height = 400 }) => `${height}px`};
`;
