import { observer } from 'mobx-react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    InputAdornment,
    Switch as MaterialSwitch,
    SvgIcon,
    SvgIconProps,
    Typography
} from '@mui/material';
import { red } from '@mui/material/colors';

import * as React from 'react';

import { injectTSDI } from '@core/tsdi';
import { I18n } from '@core/i18n';
import { ChallengeRulesStore } from '../store';
import { TeamRulesStore } from './store';
import { Switch } from '@components/switch';
import styled from 'styled-components';
import { ReactComponent as FinishIcon } from '../../../../../assets/svg/icons/finish.svg';
import { ReactComponent as HighScore } from '../../../../../assets/svg/icons/high-score.svg';
import { ReactComponent as BikeIcon } from '../../../../../assets/svg/icons/bike.svg';
import { ReactComponent as StepsIcon } from '../../../../../assets/svg/icons/steps.svg';
import { ReactComponent as WorkoutIcons } from '../../../../../assets/svg/icons/workout.svg';
import { SelectBox } from '@components/select-box';
import { ChallengeType } from '@core/api/ChallengeClient';
import { TextField } from '@components/textfield';
import { round } from 'lodash';
import { UserStore } from '@core/user';

import { ChallengeDataStore } from '../../data-store';

import TreeSelect from '@components/tree-select';
import { Stack } from '@mui/system';
import { Spinner } from '@components/spinner';

const StyledBoxItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100px;
    padding: 10px;
    height: 100px;

    svg {
        font-size: 40px;
        margin-bottom: 4px;
    }
`;
interface BoxItemProps extends SvgIconProps {
    label: string;
    icon: React.ElementType<any>;
    viewBox?: string;
    dataTestId?: string;
}
const BoxItem = ({ label, icon: Icon, viewBox, dataTestId }: BoxItemProps) => (
    <StyledBoxItem data-testid={dataTestId}>
        <SvgIcon component={Icon} viewBox={viewBox} />
        <Typography>{label}</Typography>
    </StyledBoxItem>
);

const TeamRules = observer(() => {
    const { __, formatNumber } = injectTSDI(I18n);
    const { disabledRulesEdit } = injectTSDI(ChallengeDataStore);
    const {
        fields: { teamRules },
        validateRules
    } = injectTSDI(ChallengeRulesStore);
    const {
        fields: {
            leaderboardEnabled,
            strategy,
            pointsGoal,
            allowedWinnersCount,
            teamIds
        },
        form,

        leaguesEntries
    } = injectTSDI(TeamRulesStore);

    const {
        stepsToCyclingMetersMultiplier,
        studioVisitPointsPerMinuteMultiplier
    } = injectTSDI(UserStore);

    function formatStepCount(pointsGoal: number | undefined) {
        return <>{pointsGoal ? formatNumber(pointsGoal) : 0}</>;
    }
    function formatCyclingDistance(pointsGoal: number | undefined) {
        return (
            <>
                {pointsGoal &&
                    round(
                        stepsToCyclingMetersMultiplier * (pointsGoal / 1000),
                        2
                    )}
                km
            </>
        );
    }

    function formatStudioVisitDuration(pointsGoal: number | undefined) {
        return (
            <>
                {pointsGoal &&
                    round(pointsGoal / studioVisitPointsPerMinuteMultiplier, 0)}
                min
            </>
        );
    }

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={teamRules.value}
                disabled={disabledRulesEdit}
                onChange={(__, checked) => {
                    teamRules.onChange(checked);
                    validateRules();
                }}
                sx={teamRules.error ? { backgroundColor: red[100] } : undefined}
            >
                <AccordionSummary>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography>{__('challenge.team.rules')}</Typography>
                        <MaterialSwitch
                            color="primary"
                            checked={teamRules.value}
                            onChange={(__, checked) => {
                                teamRules.onChange(checked);
                                validateRules();
                            }}
                            data-testid="challenge-team-rules"
                        />
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid spacing={3} container>
                        <Grid item xs={12}>
                            {leaguesEntries.match({
                                fetched: (data) => (
                                    <TreeSelect
                                        label={__('common.teams')}
                                        options={data}
                                        onChange={(value) => {
                                            teamIds.onChange(value);
                                            validateRules();
                                        }}
                                        value={teamIds.value}
                                        error={teamIds.error}
                                        disabled={disabledRulesEdit}
                                        required
                                    />
                                ),
                                loading: () => (
                                    <Stack
                                        direction="row"
                                        minHeight={100}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Spinner />
                                    </Stack>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <SelectBox
                                label={__('challenge.field.type')}
                                options={[
                                    {
                                        label: __('challenge.type.high.score'),
                                        value: 'HIGH_SCORE',
                                        component: ({ label }) => (
                                            <BoxItem
                                                icon={HighScore}
                                                label={label}
                                                viewBox="-1 0 24 24"
                                                dataTestId="challenge-activity-highscore"
                                            />
                                        )
                                    },
                                    {
                                        label: __('challenge.type.race'),
                                        value: 'RACE',
                                        component: ({ label }) => (
                                            <BoxItem
                                                icon={FinishIcon}
                                                label={label}
                                                viewBox="-4 0 24 24"
                                                dataTestId="challenge-activity-race"
                                            />
                                        )
                                    }
                                ]}
                                value={strategy.value}
                                onChange={(value: ChallengeType) => {
                                    strategy.onChange(value);
                                    if (value === ChallengeType.HIGH_SCORE) {
                                        pointsGoal.onChange(0);
                                    }
                                    validateRules();
                                    form.validate();
                                }}
                                error={strategy.error}
                                helperText={getActivityTypeHelper(
                                    strategy.value
                                )}
                                disabled={disabledRulesEdit}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                label={__('challenge.field.goal')}
                                value={pointsGoal.value || ''}
                                onChange={(value) => {
                                    pointsGoal.onChange(Number(value));
                                    validateRules();
                                }}
                                error={pointsGoal.error}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box mr="5px" color="#9e9c9c">
                                                    {formatStepCount(
                                                        pointsGoal.value
                                                    )}
                                                </Box>
                                                <Box
                                                    mr={1}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <SvgIcon
                                                        component={StepsIcon}
                                                        viewBox="0 0 24 24"
                                                        style={{
                                                            fontSize: 16
                                                        }}
                                                    />
                                                </Box>
                                                <Box mr={1}>/</Box>
                                                <Box mr="5px" color="#9e9c9c">
                                                    {formatCyclingDistance(
                                                        pointsGoal.value
                                                    )}
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <SvgIcon
                                                        component={BikeIcon}
                                                        viewBox="0 -5 24 24"
                                                        style={{
                                                            fontSize: 16
                                                        }}
                                                    />
                                                </Box>
                                                <Box mr={1} ml={1}>
                                                    /
                                                </Box>
                                                <Box mr="5px" color="#9e9c9c">
                                                    {formatStudioVisitDuration(
                                                        pointsGoal.value
                                                    )}
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <SvgIcon
                                                        component={WorkoutIcons}
                                                        viewBox="0 -5 24 24"
                                                        style={{
                                                            fontSize: 16
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelShrink
                                disabled={
                                    disabledRulesEdit ||
                                    strategy.value === ChallengeType.HIGH_SCORE
                                }
                                required={strategy.value === ChallengeType.RACE}
                                data-testid={`points-goal-challenge-goal-field`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                label={__(
                                    'cp.challenge.field.number.of.winners'
                                )}
                                value={
                                    typeof allowedWinnersCount.value ===
                                    'number'
                                        ? allowedWinnersCount.value
                                        : ''
                                }
                                error={allowedWinnersCount.error}
                                onChange={(value) => {
                                    allowedWinnersCount.onChange(
                                        value.length === 0
                                            ? undefined
                                            : Number(value)
                                    );
                                    validateRules();
                                }}
                                disabled={
                                    allowedWinnersCount.value === 0 ||
                                    disabledRulesEdit
                                }
                                required={
                                    strategy.value === ChallengeType.HIGH_SCORE
                                }
                                InputLabelShrink
                                data-testid="challenge-winners-field"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Switch
                                label={__('cp.challenge.leaderboard')}
                                checked={leaderboardEnabled.value}
                                onChange={(_e, checked) => {
                                    leaderboardEnabled.onChange(checked);
                                    validateRules();
                                }}
                                disabled={disabledRulesEdit}
                                data-testid="team-leaderboard-enabled"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
});

export default TeamRules;

function getActivityTypeHelper(type: ChallengeType) {
    const { __ } = injectTSDI(I18n);
    switch (type) {
        case ChallengeType.HIGH_SCORE:
            return __('challenge.type.target.info');
        case ChallengeType.RACE:
            return __('challenge.type.high.score.info');
        case 'RACE':
        default:
            return __('challenge.type.race.info');
    }
}
