export function makeEnum<X extends string>(...x: X[]): { [K in X]: K } {
    // tslint:disable-next-line:no-any
    const o: any = {};

    for (const k in x) {
        if (x.hasOwnProperty(k)) {
            o[x[k]] = x[k];
        }
    }

    return o;
}

export type enumType<T> = T[keyof T];
