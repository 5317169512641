import {
    AppBar as MaterialAppBar,
    AppBarProps as MaterialAppBarProps,
    Toolbar as MaterialToolbar
} from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { drawerClosedWidth, drawerWidth } from '../../app/nav';
import { AppStore } from '../../app/store';
import { injectTSDI } from '../../core/tsdi';

interface StickyBarProps {
    children?: React.ReactNode;
    position?: MaterialAppBarProps['position'];
    maxWidth?: number;
}

interface AppBarProps {
    $drawerOpen: boolean;
}

const AppBar = styled(MaterialAppBar)<AppBarProps>`
    ${({ $drawerOpen, theme }) => css`
        top: auto;
        bottom: 0;
        z-index: 8;
        padding-left: calc(
            ${$drawerOpen ? drawerWidth : drawerClosedWidth}px +
                ${theme.spacing(2)}
        );
    `}
`;

const Toolbar = styled(MaterialToolbar)<{ $maxWidth?: number }>`
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : 'none')};
`;

export const StickyBar = observer(
    ({ children, position = 'fixed', maxWidth }: StickyBarProps) => {
        const { drawerOpen } = injectTSDI(AppStore);

        return (
            <AppBar
                position={position}
                color="default"
                sx={{ zIndex: 100 }}
                $drawerOpen={drawerOpen}
            >
                <Toolbar $maxWidth={maxWidth}>{children}</Toolbar>
            </AppBar>
        );
    }
);
