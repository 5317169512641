import { useMemo, useState } from 'react';
import { LanguageOption, TLanguage } from '../language-select';
import { I18nText } from '../../core/api/dtos';

export function useLocalizedField(
    value: I18nText,
    initialOptions: LanguageOption[],
    mainLanguageKey: TLanguage
) {
    const [language, setLanguage] = useState<TLanguage>(mainLanguageKey);

    const selectedValue = useMemo(() => {
        return value[language] || '';
    }, [value, language]);

    const options = useMemo(() => {
        return initialOptions.map((option) => {
            const content = value[option.value];
            return { ...option, filled: Boolean(content) };
        });
    }, [value]);

    return { language, setLanguage, selectedValue, options };
}
