import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AppRouter, Links } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';
import { capitalizeFirstLetter } from '../../core/utils/strings';
import { AppStore } from '../store';
import { NavList } from './nav-list';
import { useRouteEntries } from './useRouteEntries';

interface SectionNavListProps {
    link: keyof Links;
}
export const SectionNavList = observer(({ link }: SectionNavListProps) => {
    const router = injectTSDI(AppRouter);
    const appStore = injectTSDI(AppStore);
    const { routeEntries, onLinkClick } = useRouteEntries(link);

    if (routeEntries.length === 0) {
        return null;
    }

    return (
        <List>
            {routeEntries.map((entry) => {
                const selected = router.linkIsActive(entry.link);

                return (
                    <React.Fragment key={entry.title}>
                        <ListItemButton
                            onClick={() => void onLinkClick(entry.link)}
                            className={`menuItem-${entry.link}`}
                        >
                            <ListItemText
                                sx={{
                                    color: 'white',
                                    whiteSpace: 'nowrap'
                                }}
                                primary={
                                    selected ? (
                                        <b>
                                            <SectionTitle
                                                open={appStore.drawerOpen}
                                                title={entry.title}
                                                topLevel
                                            />
                                        </b>
                                    ) : (
                                        <SectionTitle
                                            open={appStore.drawerOpen}
                                            title={entry.title}
                                            topLevel
                                        />
                                    )
                                }
                                primaryTypographyProps={{
                                    variant: 'overline'
                                }}
                            />
                        </ListItemButton>

                        <NavList link={entry.link} />
                    </React.Fragment>
                );
            })}
        </List>
    );
});

interface SectionTitleProps {
    open: boolean;
    title: string;
    topLevel: boolean;
}

export const SectionTitle = ({ open, title, topLevel }: SectionTitleProps) => {
    if (open || !topLevel) {
        return <>{capitalizeFirstLetter(title)}</>;
    }
    return <MoreHorizIcon fontSize="small" />;
};
