import ErrorIcon from '@mui/icons-material/Error';
import { Box, SnackbarContent, SxProps, useTheme } from '@mui/material';

interface ErrorMessageProps {
    children: string;
    sx?: SxProps;
    'data-testid'?: string;
}

export function ErrorMessage(props: ErrorMessageProps) {
    const theme = useTheme();
    return (
        <SnackbarContent
            sx={{
                boxShadow: 'none',
                backgroundColor: theme.palette.error.dark,
                minWidth: 'auto !important',
                ...props.sx
            }}
            message={
                <Box
                    component="span"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <ErrorIcon
                        sx={{ opacity: 0.9, marginRight: theme.spacing(1) }}
                    />
                    <span
                        dangerouslySetInnerHTML={{ __html: props.children }}
                    ></span>
                </Box>
            }
            data-testid={props['data-testid']}
        />
    );
}
