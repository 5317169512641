import {
    FormControl,
    FormHelperText,
    Grid,
    RadioGroupProps,
    Typography
} from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Action } from '../action/action';
import { Label } from '../label';

// tslint:disable-next-line:no-any
export interface Option<T = any> {
    label: string;
    value: T;
    disabled?: boolean;
    component?: React.ComponentType<{ label: string }>;
    selected?: boolean;
}

// tslint:disable-next-line:no-any
interface BoxProps<T = any> extends Option<T> {
    onClick?(): void;
}

// tslint:disable-next-line:no-any
interface SelectBoxProps<T = any>
    extends Omit<RadioGroupProps, 'onChange' | 'value'> {
    label: string;
    options: Option<T>[];
    required?: boolean;
    disabled?: boolean;
    value?: T;
    error?: string;
    helperText?: string;
    onChange(value: T): void;
}

const StyledAction = styled(Action)<{ selected?: boolean }>`
    ${({ selected }) =>
        selected &&
        css`
            box-shadow: 0px 0px 0px 1.5px rgba(0, 0, 0, 0.52);
            background-color: ${lightBlue[50]};
        `}

    .MuiCardContent-root {
        padding: 0;
    }
`;

const BoxContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    min-height: 90px;
`;

const Box = <T extends any>({
    component: Component,
    label,
    selected,
    disabled,
    onClick
}: BoxProps<T>) => {
    return (
        <StyledAction selected={selected} disabled={disabled} onClick={onClick}>
            {Component ? (
                <Component label={label} />
            ) : (
                <BoxContent>
                    <Typography>{label}</Typography>
                </BoxContent>
            )}
        </StyledAction>
    );
};

export const SelectBox = <T extends any>({
    value,
    onChange,
    required,
    error,
    options,
    label,
    helperText,
    disabled
}: SelectBoxProps<T>) => {
    return (
        <FormControl
            component="fieldset"
            required={required}
            error={Boolean(error)}
        >
            <Label
                required={required}
                shrink
                error={Boolean(error)}
                disabled={disabled}
            >
                {label}
            </Label>

            <Grid container justifyContent="flex-start" spacing={2}>
                {options.map((option) => (
                    <Grid key={option.value as any} item>
                        <Box
                            {...option}
                            selected={option.value === value}
                            disabled={disabled}
                            onClick={() => onChange(option.value)}
                        />
                    </Grid>
                ))}
            </Grid>
            {helperText && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormHelperText
                            sx={{ fontStyle: 'italic', marginTop: '5px' }}
                            variant="standard"
                        >
                            {helperText}
                        </FormHelperText>
                    </Grid>
                </Grid>
            )}
        </FormControl>
    );
};
