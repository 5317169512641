import { FieldState, FormState } from 'formstate';
import { component } from 'tsdi';
import { CdnImageDto, I18nText } from '@core/api/dtos';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { wrapInFormField } from '@core/forms';
import { injectTSDI } from '@core/tsdi';
import { wrapRequest } from 'wrap-request';
import { autodisposer } from '@core/reactions';
import { required, requiredI18n } from '@core/utils/validators';
import { i18nTextToStringConverter, textToI18nTextConverter } from '@core/i18n';
import { ChallengeDataStore } from '../data-store';
import {
    ChallengeDetailsManagementDto,
    ChallengePresentationUpdateManagementDto
} from '@core/api/ChallengeClient';
import { GatewayApi } from '@core/gateway-api';
import { Api } from '@core/api';
import { formErrors, isFormStateDirty, makeFormClean } from '@core/utils/form';
import { StepStatus } from '../store';
import { bind } from 'lodash-decorators';
import { assertDefined } from '@core/utils/error';

type ChallengePresentationFormType = ReturnType<
    ChallengePresentationStore['generateForm']
>;

@component
export class ChallengePresentationStore {
    constructor() {
        this.form = this.generateForm(
            this.challengeDataStore.challengeConfiguration
        );

        if (!this.challengePhotos.fetched) {
            this.challengePhotos.request({ size: 100 });
        }

        makeObservable(this, {
            form: observable,
            state: observable,
            isActive: observable,
            fields: computed,
            errors: computed
        });
    }

    private get challengeClient() {
        return injectTSDI(GatewayApi).challengeClient;
    }
    private get client() {
        return injectTSDI(Api).client;
    }
    private get challengeDataStore() {
        return injectTSDI(ChallengeDataStore);
    }

    public form: ChallengePresentationFormType;
    public state: StepStatus = StepStatus.INCOMPLETE;
    public isActive: boolean = false;

    private generateForm(configuration?: ChallengeDetailsManagementDto) {
        if (!configuration) {
            this.state = StepStatus.INCOMPLETE;
            return new FormState({
                name: new FieldState<I18nText>(
                    textToI18nTextConverter()
                ).validators(requiredI18n()),
                photo: new FieldState<CdnImageDto | undefined>(
                    undefined
                ).validators(required('challenge.field.photo.validation')),
                description: new FieldState<I18nText>(
                    textToI18nTextConverter()
                ),
                prizeDescription: new FieldState<I18nText>(
                    textToI18nTextConverter()
                )
            });
        }
        if (!configuration.presentation?.displayName) {
            this.state = StepStatus.INCOMPLETE;
        } else {
            this.state = StepStatus.COMPLETED;
        }

        return new FormState({
            name: new FieldState<I18nText>(
                configuration.presentation?.displayName ||
                    textToI18nTextConverter(undefined, configuration.name)
            ).validators(requiredI18n()),
            photo: new FieldState<CdnImageDto | undefined>(
                configuration.presentation?.photo
            ).validators(required('challenge.field.photo.validation')),
            description: new FieldState<I18nText>(
                textToI18nTextConverter(configuration.presentation?.description)
            ),
            prizeDescription: new FieldState<I18nText>(
                textToI18nTextConverter(
                    configuration.presentation?.prizeDescription
                )
            )
        });
    }

    public get errors() {
        return formErrors(this.form);
    }

    public get formIsDirty() {
        return isFormStateDirty(this.form.$);
    }
    public get fields() {
        return {
            name: wrapInFormField(this.form.$.name),
            description: wrapInFormField(this.form.$.description),
            prizeDescription: wrapInFormField(this.form.$.prizeDescription),
            photo: wrapInFormField(this.form.$.photo)
        };
    }

    public challengePresentationRequest = wrapRequest(
        async ({
            challengeId,
            data
        }: {
            challengeId: number;
            data: ChallengePresentationUpdateManagementDto;
        }) => {
            const result =
                await this.challengeClient?.management.challengeManagementControllerFillPresentation(
                    challengeId,
                    data
                );

            return result?.data;
        }
    );

    @bind
    public async validatePresentation() {
        const validation = await this.form.validate();

        if (validation.hasError) {
            this.state = StepStatus.ERROR;
            return true;
        } else if (this.formIsDirty) {
            this.state = StepStatus.MODIFIED;
            return false;
        }
        this.state = StepStatus.COMPLETED;
        return false;
    }

    @bind
    public async updateChallengePresentation() {
        const validation = await this.form.validate();

        if (validation.hasError) {
            this.state = StepStatus.ERROR;
            return false;
        } else if (
            !validation.hasError &&
            this.challengeDataStore.challengeId &&
            this.formIsDirty
        ) {
            this.state = StepStatus.UPDATING;
            await this.challengePresentationRequest.request({
                challengeId: this.challengeDataStore.challengeId,
                data: this.generateRequestData(this.form)
            });
            this.state = StepStatus.COMPLETED;
            makeFormClean(this.form);
            return true;
        }
        return false;
    }

    @bind
    public onCollapsedToggle() {
        this.isActive = !this.isActive;
    }

    @bind
    public openStep() {
        this.isActive = true;
    }

    @bind
    public closeStep() {
        this.isActive = false;
    }

    private generateRequestData(
        form: ChallengePresentationFormType
    ): ChallengePresentationUpdateManagementDto {
        assertDefined(form.$.photo.value);
        return {
            name: i18nTextToStringConverter(form.$.name.value),
            displayName: form.$.name.value,
            description: form.$.description.value,
            prizeDescription: form.$.prizeDescription.value,
            photo: form.$.photo.value
        };
    }

    public challengePhotos = wrapRequest(
        async () => {
            const result =
                await this.client.ChallengePhotoController.getAvailablePhotos(
                    {}
                );

            return result.content.map((option) => option.cdnImage) || [];
        },
        {
            defaultData: []
        }
    );

    public imageUploadRequest = wrapRequest(
        async ([__, file]: [FormData, File]) => {
            const result =
                await this.challengeClient?.management.challengeImageManagementControllerUpload(
                    { file }
                );

            return result?.data as CdnImageDto;
        }
    );

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.challengeDataStore.challengeConfiguration,
                async (configuration) => {
                    if (configuration) {
                        this.form = this.generateForm(configuration);
                    }
                }
            )
        ];
    }
}
