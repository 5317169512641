import InfoIcon from '@mui/icons-material/Info';
import {
    TooltipProps as MaterialTooltipProps,
    Tooltip as MaterialTooltip,
    IconButton,
    Box
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

interface TooltipProps extends Omit<MaterialTooltipProps, 'children'> {
    children?: React.ReactElement<any, any>;
    showIcon?: boolean;
    ignored?: boolean;
}

const StyledIconButton = styled(IconButton)`
    padding: 0;
    margin-right: 2px;
`;

export const Tooltip = ({
    children,
    showIcon = true,
    ignored = false,
    ...props
}: TooltipProps) => {
    if (ignored) {
        return <>{children}</>;
    }
    return (
        <MaterialTooltip {...props} placement="top-start">
            <Box display="inline-flex" alignItems="center" height="100%">
                {showIcon && (
                    <StyledIconButton>
                        <InfoIcon viewBox="0 -2 27 27" />
                    </StyledIconButton>
                )}
                {children}
            </Box>
        </MaterialTooltip>
    );
};
