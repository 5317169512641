import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import styled, { css } from 'styled-components';

interface StyledMSLogoVerticalProps {
    fontSize: SvgIconProps['fontSize'];
}

const StyledMSLogoVertical = styled(SvgIcon)<StyledMSLogoVerticalProps>`
    ${(props) =>
        !props.fontSize &&
        css`
            font-size: 110px;
        `}
`;

export const MSLogoVertical = (props: SvgIconProps) => {
    const theme = useTheme();

    return (
        <StyledMSLogoVertical
            {...props}
            viewBox="0 0 110 71"
            fontSize={props.fontSize}
        >
            <path
                d="M17.0322 35.7189L34.0616 71.0013H0L17.0322 35.7189Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M13.9918 0L47.6227 71.0013H72.0364L37.423 0H13.9918Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M51.9525 0L85.5834 71.0013H110L75.3838 0H51.9525Z"
                fill={theme.palette.primary.main}
            />
        </StyledMSLogoVertical>
    );
};
