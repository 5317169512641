import { makeAutoObservable } from 'mobx';
import { component, initialize } from 'tsdi';
import './history';
import './location';
import { injectTSDI } from './tsdi';
import { enumType, makeEnum } from './utils/enum';

type UrlEnv = 'dev' | 'pre' | 'prod';
export type QueryParams = enumType<typeof QueryParamsEnum>;
const QueryParamsEnum = makeEnum('tenant', 'supportAccessToken');

/**
 * decode the component a couple of times
 * due to problems with broken emails when parts were encoded up to three times
 */
export function safeDecodeURIComponent(component: string) {
    return decodeURIComponent(
        decodeURIComponent(decodeURIComponent(component))
    );
}

function getQueryParamRegEx(param: string): RegExp {
    return new RegExp(`(?:^${param}|^\\?${param}|&${param})=([^&]+)`, 'ig');
}

@component
export class Url {
    public historyVersion = 0;

    private get history() {
        return injectTSDI(History, 'History');
    }

    private get location() {
        return injectTSDI(Location, 'Location');
    }

    @initialize
    public init() {
        makeAutoObservable(this);
    }

    public get urlParams(): string {
        return this.location.search.replace(/(^\?)/g, '');
    }

    public get env(): UrlEnv {
        const { hostname } = this.location;
        const matches = hostname.match(
            /(dev|pre|local)?\.?mysports-rewards\.com/
        );

        if (hostname.match(/localhost/)) {
            return 'dev';
        }

        if (matches) {
            const env = matches[1];

            if (env === 'local') {
                return 'dev';
            }

            if (env) {
                return env as UrlEnv;
            }
        }

        return 'prod';
    }

    public get isDev(): boolean {
        return this.env === 'dev';
    }

    public getQueryParam(key: string) {
        const url = new URL(this.location.href.replace('/#', ''));

        return url.searchParams.get(key);
    }

    public get queryParams(): string {
        return this.location.search.replace(/(^\?)/g, '');
    }

    private isBase64(val: string) {
        if (typeof val === 'string') {
            return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
                val
            );
        }
        return false;
    }

    public decode(val: string): string {
        try {
            const plain = safeDecodeURIComponent(val);

            if (this.isBase64(plain)) {
                return atob(plain);
            }

            return plain;
        } catch (_e) {
            return val;
        }
    }

    public omitQueryParam(...params: QueryParams[]): void {
        const decodedQueryParams = this.decode(this.queryParams);

        const newParams = params
            .reduce(
                (params, param) =>
                    params.replace(getQueryParamRegEx(param), ''),
                decodedQueryParams
            )
            .replace(/&+/g, '&')
            .replace(/^&|&$/, '');

        const url =
            `${newParams ? `?${newParams}` : ''}` || this.location.pathname;

        this.replaceState(url);
    }

    public omitAllQueryParams(): void {
        const params = Object.values(QueryParamsEnum);

        this.omitQueryParam(...params);
    }

    private replaceState(url: string) {
        const oldUrl = url.startsWith('?')
            ? this.location.search
            : `${this.location.pathname}${this.location.search}`;

        if (oldUrl !== url) {
            this.history.replaceState(null, '', url);
        }
    }
}
