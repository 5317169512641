import { component, factory } from 'tsdi';

const fakeStorage = (store: { [key: string]: string } = {}) => ({
    get length(): number {
        return Object.keys(store).length;
    },
    clear(): void {
        store = {};
    },
    getItem(key: string): string | null {
        return store[key];
    },
    key(index: number): string | null {
        return store[Object.keys(store)[index]];
    },
    removeItem(key: string): void {
        delete store[key];
    },
    setItem(key: string, data: string): void {
        store[key] = data;
    }
});

@component
export class SessionStorageFactory {
    @factory({ name: 'SessionStorage' })
    public getStorage(): Storage {
        try {
            // NOTE: working on sessionStorage might cause an error due to disabled cookies
            sessionStorage.getItem('test');

            return sessionStorage;
        } catch (e) {
            return fakeStorage();
        }
    }
}

@component
export class LocalStorageFactory {
    @factory({ name: 'LocalStorage' })
    public getStorage(): Storage {
        try {
            // NOTE: working on localStorage might cause an error due to disabled cookies
            localStorage.getItem('test');

            return localStorage;
        } catch (e) {
            return fakeStorage();
        }
    }
}
