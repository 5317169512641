import {
    Checkbox as MaterialCheckbox,
    CheckboxProps as MaterialCheckboxProps,
    FormControlLabel
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

interface CheckboxProps extends MaterialCheckboxProps {
    children: React.ReactNode;
    required?: boolean;
    center?: boolean;
    checked: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)<{ $center?: boolean }>`
    align-items: ${({ $center }) => ($center ? 'center' : 'flex-start')};
    .MuiTypography-root {
        p {
            margin-top: ${({ theme }) => theme.spacing(1)};
        }
    }
`;

export function Checkbox(props: CheckboxProps) {
    return (
        <StyledFormControlLabel
            control={
                <MaterialCheckbox
                    required={props.required}
                    color="primary"
                    onChange={props.onChange}
                />
            }
            checked={props.checked}
            disabled={props.disabled}
            label={<>{props.children}</>}
            $center={props.center}
            labelPlacement="end"
        />
    );
}
