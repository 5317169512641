import {
    FormControl,
    Select as MaterialSelect,
    MenuItem,
    Box,
    SvgIcon
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Option } from '../select';
import { ReactComponent as DE } from '../../../assets/svg/flags/de.svg';
import { ReactComponent as PL } from '../../../assets/svg/flags/pl.svg';
import { ReactComponent as EN } from '../../../assets/svg/flags/us.svg';
import { ReactComponent as ES } from '../../../assets/svg/flags/es.svg';
import { observer } from 'mobx-react';
import { I18nText } from '../../core/api/dtos';

export type TLanguage = keyof I18nText;

export interface LanguageOption extends Option<TLanguage> {
    filled?: boolean;
    main?: boolean;
}

interface LanguageSelectProps {
    options: LanguageOption[];
    value: TLanguage;
    onChange(language: TLanguage): void;
}
export function getLanguageIcon(language: TLanguage) {
    switch (language) {
        case 'de':
            return DE;
        case 'en':
            return EN;
        case 'pl':
            return PL;
        case 'es':
            return ES;
    }
}

const LanguageSelect = observer(
    ({ value, options, onChange }: LanguageSelectProps) => {
        return (
            <FormControl variant="standard">
                <MaterialSelect<TLanguage>
                    onChange={(e) => {
                        onChange(e.target.value as TLanguage);
                    }}
                    value={value || ''}
                    autoWidth
                    sx={{
                        '&::before': {
                            opacity: 0
                        },
                        '&::after': {
                            borderBottom: 'none'
                        },
                        '& .MuiSelect-select': {
                            padding: '0 !important',
                            '&[aria-expanded*="true"] .arrow-icon': {
                                transform: 'rotate(180deg)'
                            }
                        },
                        paddingBottom: 1
                    }}
                    IconComponent={() => null}
                    renderValue={(selected) => {
                        const allOptionsNotFilled = options.some(
                            (option: LanguageOption) => !option.filled
                        );
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}
                                data-testid="language-dropdown-button"
                            >
                                <ArrowDropDownIcon
                                    className="arrow-icon"
                                    sx={{
                                        color: allOptionsNotFilled
                                            ? red[700]
                                            : green[700]
                                    }}
                                />
                                <SvgIcon
                                    component={getLanguageIcon(selected)}
                                    viewBox="0 0 21 15"
                                />
                            </Box>
                        );
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.label}
                            value={option.value}
                            sx={{
                                maxWidth: 600,
                                display: 'block',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}
                            data-testid="language-select-option"
                        >
                            <Box
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 7,
                                            height: 7,
                                            borderRadius: '50%',
                                            backgroundColor: option.filled
                                                ? green[600]
                                                : red[700]
                                        }}
                                    />
                                    {option.label}
                                    <SvgIcon
                                        component={getLanguageIcon(
                                            option.value
                                        )}
                                        viewBox="0 0 21 15"
                                    />
                                </Box>
                            </Box>
                        </MenuItem>
                    ))}
                </MaterialSelect>
            </FormControl>
        );
    }
);

export default LanguageSelect;
