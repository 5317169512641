import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ZENDESK_KEY } from '../config';
import { I18n } from '../i18n';
import { injectTSDI } from '../tsdi';
import { UserStore } from '../user';

import { ZendeskStore } from './store';
interface ZendeskProps {
    children?: React.ReactNode;
}

export const Zendesk = observer(({ children }: ZendeskProps) => {
    const store = injectTSDI(ZendeskStore);
    const { me } = injectTSDI(UserStore);
    const { currentLocale } = injectTSDI(I18n);
    const [zeReady, setZeReady] = React.useState(false);

    const url = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;

    const onScriptLoad = () => {
        if (typeof window.zE === 'function') {
            window.zE('webWidget', 'setLocale', currentLocale);
            window.zE('webWidget', 'updateSettings', store.settings);
            setZeReady(true);
        }
    };

    useEffect(() => {
        if (typeof window.zE !== 'function') {
            const script = document.createElement('script');
            script.async = true;
            script.id = 'ze-snippet';
            script.src = url;
            script.addEventListener('load', onScriptLoad);

            document.body.appendChild(script);
        }
    }, []);

    React.useEffect(() => {
        if (zeReady && me.fetched && typeof window.zE === 'function') {
            // TODO prefil widget form with name and surname
            window.zE('webWidget', 'prefill', {
                email: {
                    value: me.$.email || ''
                },
                ...(me.$.firstName || me.$.firstName
                    ? {
                          name: {
                              value: `${me.$.firstName} ${me.$.lastName}`
                          }
                      }
                    : {})
            });
        }
    }, [zeReady, me.fetched]);

    return <>{children}</>;
});

export const ZendeskGlobalStyle = createGlobalStyle`
  iframe#launcher {
    display: none;
  }
`;
