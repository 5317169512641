import { Component } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import { injectTSDI } from '../../core/tsdi';
import {  computed, makeObservable, observable, reaction } from 'mobx';
import { AppRouter } from '../../core/router';
import { autodisposer } from '../../core/reactions';
import { GatewayApi } from '@core/gateway-api';
import {
    ChallengeDetailsManagementDto,
    ChallengeStatus
} from '@core/api/ChallengeClient';
import { Bind } from 'lodash-decorators';
import { UserStore } from '@core/user';

@Component
export class ChallengeDataStore {
    constructor() {
        makeObservable(this, {
            liveChallengeConfiguration: observable,
            challengeConfiguration: computed,
            challengeId: computed,
            disabledPresentationEdit: computed,
            disabledRulesEdit: computed
        });

        this.loadChallenge(this.router.params?.id);
    }

    private get router() {
        return injectTSDI(AppRouter);
    }
    private get isExternalSupport() {
        return injectTSDI(UserStore).isExternalSupport;
    }

    private get challengeClient() {
        return injectTSDI(GatewayApi).challengeClient;
    }

    public challengeConfigurationRequest = wrapRequest(async (id: number) => {
        const result =
            await this.challengeClient?.management.challengeManagementControllerGetDetails(
                id
            );
        this.liveChallengeConfiguration = result?.data;

        return result?.data;
    });
    private loadChallenge(id?: number) {
        if (id) {
            this.challengeConfigurationRequest.request(id);
        }
    }
    public get challengeConfiguration():
        | ChallengeDetailsManagementDto
        | undefined {
        return this.challengeConfigurationRequest.$;
    }
    public liveChallengeConfiguration:
        | ChallengeDetailsManagementDto
        | undefined;

    public get challengeId() {
        return this.liveChallengeConfiguration?.id;
    }

    public get disabledPresentationEdit(): boolean {
        return ChallengeDataStore.shouldDisablePresentationEdit(
            this.isExternalSupport,
            this.liveChallengeConfiguration?.state.status
        );
    }
    static shouldDisablePresentationEdit(
        isExternalSupport: boolean,
        status?: ChallengeStatus
    ): boolean {
        return (
            !isExternalSupport ||
            status === ChallengeStatus.CANCELED ||
            status === ChallengeStatus.FINISHED
        );
    }

    public get disabledRulesEdit(): boolean {
        return ChallengeDataStore.shouldDisableRulesEditCheck(
            this.isExternalSupport,
            this.liveChallengeConfiguration?.state.status
        );
    }

    static shouldDisableRulesEditCheck(
        isExternalSupport: boolean,
        status?: ChallengeStatus
    ): boolean {
        return (
            !isExternalSupport ||
            !(
                status === ChallengeStatus.DRAFT ||
                status === ChallengeStatus.SCHEDULED
            )
        );
    }

    @Bind
    public setChallengeStatus(status: ChallengeStatus) {
        if (this.liveChallengeConfiguration) {
            this.liveChallengeConfiguration.state.status = status;
        }
    }

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.router.params,
                async (params) => {
                    this.loadChallenge(params?.id);
                }
            )
        ];
    }
}
