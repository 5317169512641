import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { navigate } from 'takeme';
import { component, initialize } from 'tsdi';
import { getClient } from './api/client';
import {
    CompanyAccountDto,
    ConfigurationDto,
    ErrorResponse,
    MeResponseDto,
    ValidationErrorResponse
} from './api/dtos';
import { I18n } from './i18n';
import { AppRouter } from './router';
import './storage';
import { TenantStore } from './tenant';
import { injectTSDI } from './tsdi';
import { Url } from './url';
import { UserStore } from './user';

export type Role = CompanyAccountDto['role'];
type ProductType = 'CORPORATE' | 'TEAMS';
export type Permission =
    | 'LOGGED_IN'
    | 'IS_DEV'
    | 'EXTERNAL_SUPPORT'
    | ProductType
    | ConfigurationDto['enabledFeatures'][0];

export type RoutePermission =
    | Permission
    | { atLeastOne?: Permission[]; all?: Permission[] };

export const parseNull = (item: any): any => {
    if (item === null) {
        return undefined;
    }

    if (Array.isArray(item)) {
        return item.map(parseNull);
    }

    if (typeof item === 'object') {
        return Object.keys(item).reduce(
            (prev: object, key: string) => ({
                ...prev,
                [key]: parseNull(item[key])
            }),
            {}
        );
    }

    return item;
};

function getBody(params: { [key: string]: string }): string {
    return Object.entries(params)
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
}

@component
export class Api {
    private get i18n() {
        return injectTSDI(I18n);
    }

    private get url() {
        return injectTSDI(Url);
    }

    private get storage() {
        return injectTSDI(Storage, 'LocalStorage');
    }

    private get tenantStore() {
        return injectTSDI(TenantStore);
    }

    private get isExternalSupport() {
        return injectTSDI(UserStore).isExternalSupport;
    }

    private isWhitelistedNonTenantPath(value: string): boolean {
        return value.endsWith('/api/v1/passwordresets');
    }

    public loggedIn = this.retrieveLoggedIn();

    private retrieveLoggedIn() {
        return this.storage.getItem('loggedIn') === 'true';
    }

    public persistLoggedIn(val: boolean) {
        this.storage.setItem('loggedIn', val ? 'true' : 'false');
    }

    public xAuthToken = this.retrieveXAuthToken();

    private retrieveXAuthToken() {
        return this.storage.getItem('xAuthToken');
    }

    public persistXAuthToken(val: string) {
        this.xAuthToken = val;
        this.storage.setItem('xAuthToken', val);
    }

    public removeXauthToken() {
        this.xAuthToken = null;
        this.storage.removeItem('xAuthToken');
    }

    private userPermissionFeatures?: MeResponseDto['enabledFeatures'];
    private productType?: 'CORPORATE' | 'TEAMS';

    private _initialDataLoaded = false;

    public get initialDataLoaded() {
        return this._initialDataLoaded;
    }

    public set initialDataLoaded(val) {
        this._initialDataLoaded = val;
    }

    @initialize
    public init(): void {
        makeAutoObservable(this, {}, { autoBind: true });

        if (this.loggedIn && this.isPrivatePath) {
            this.logout();
        }
        if (!this.loggedIn) {
            this.removeXauthToken();
        }

        reaction(
            () => this.loggedIn,
            (loggedIn) => {
                this.persistLoggedIn(loggedIn);
            }
        );
    }

    public get isPrivatePath() {
        const router = injectTSDI(AppRouter);

        return router.isPrivatePath;
    }

    public get apiUrl() {
        const { env } = this.url;

        if (env === 'prod') {
            return `https://corporate.api.mysports-rewards.com`;
        }

        return `https://corporate.api.${env}.mysports-rewards.com`;
    }

    public get permissions(): Permission[] {
        const permissions: Permission[] = [];

        if (this.loggedIn) {
            permissions.push('LOGGED_IN');
        }

        if (this.productType) {
            permissions.push(this.productType);
        }

        if (this.userPermissionFeatures) {
            permissions.push(...this.userPermissionFeatures);
        }

        if (this.isExternalSupport) {
            permissions.push('EXTERNAL_SUPPORT');
        }

        if (this.url.isDev) {
            permissions.push('IS_DEV');
        }

        return permissions;
    }

    public setUserPermissionFeatures(
        features: MeResponseDto['enabledFeatures']
    ) {
        this.userPermissionFeatures = features;
    }

    public setProductType(role: ConfigurationDto['productType']): ProductType {
        return (this.productType = role);
    }

    public async setSessionCookie(sessionId: string, tenant: string) {
        this.url.omitAllQueryParams();
        await this.logout();
        this, this.removeXauthToken();

        runInAction(() => {
            this.tenantStore.updateTenant(tenant);
        });
        try {
            this.persistXAuthToken(sessionId);
            const response =
                await this.client.SupportAccessController.redeemSupportAccessToken(
                    {
                        supportAccessToken: sessionId
                    }
                );

            return response;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public get client() {
        return getClient(this.apiUrl, '', (pretend) =>
            pretend
                .interceptor(async (chain: any, request: any) => {
                    if (
                        this.tenantStore.tenant ||
                        this.isWhitelistedNonTenantPath(request.url)
                    ) {
                        return chain(request);
                    } else if (this.isPrivatePath) {
                        this.logout();
                        this.removeXauthToken();
                        navigate('/login');
                    }
                })
                .interceptor(async (chain: any, request: any) => {
                    const { options } = request;
                    this.fillGlobalHeaders(options);
                    return chain(request);
                })
                .decode(async (response: any) => {
                    const { status, headers } = response;
                    const contentType = headers.get('content-type');

                    if (status === 502) {
                        navigate('/maintenance');

                        throw new Error(`502 Bad Gateway`);
                    }

                    if (status === 401 || status === 403) {
                        if (this.isPrivatePath) {
                            this.logout();
                            this.removeXauthToken();
                            navigate('/login');
                        }
                        return;
                    }

                    if (!contentType) {
                        return;
                    }

                    if (contentType.includes('application/json')) {
                        const json = await response.json();

                        if (status === 400) {
                            const error = json as ValidationErrorResponse;
                            let message;
                            if (error.violations) {
                                message = error.violations
                                    ?.map(
                                        (violation) => violation.defaultMessage
                                    )
                                    .filter((m) => Boolean(m))
                                    .join('<br>');
                            } else {
                                message = error.defaultMessage;
                            }

                            throw new Error(message);
                        }

                        if (status > 400) {
                            this.handleError(json);
                        }

                        return parseNull(json);
                    }
                })
        );
    }

    private fillGlobalHeaders(options: RequestInit) {
        const tenant = this.tenantStore.tenant;
        if (options.headers && options.headers instanceof Headers) {
            options.headers.set('content-type', 'application/json');
            // always send cookies along with the request
            options.credentials = 'include';
            if (tenant) {
                options.headers.set('X-Tenant-ID', tenant);
            }
        }
    }

    public handleError(error: any) {
        throw new Error(this.parseError(error));
    }

    public parseError(error: any) {
        const parsedError = error as ErrorResponse;

        return (
            parsedError.defaultMessage || this.i18n.__('commons.error.runtime')
        );
    }

    public hasPermission(permission: RoutePermission): boolean {
        if (typeof permission === 'object') {
            if (
                permission.all &&
                !permission.all.every((p) => this.permissions.includes(p))
            ) {
                return false;
            }

            if (
                permission.atLeastOne &&
                !permission.atLeastOne.some((p) => this.permissions.includes(p))
            ) {
                return false;
            }

            return true;
        }

        return this.permissions.includes(permission);
    }

    public async login(
        username: string,
        password: string,
        _rememberToken = false
    ): Promise<void> {
        const response = await fetch(`${this.apiUrl}/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: getBody({
                username,
                password
            })
        });

        if (response.status !== 200) {
            const e = await response.json();
            const { message } = e;

            if (message) {
                throw new Error(message.defaultMessage);
            }
        }

        if (response.ok) {
            runInAction(() => {
                const xAuthToken =
                    response.headers.get('X-Auth-Token') || undefined;

                const tenant = response.headers.get('X-Tenant-ID') || undefined;
                if (!tenant) {
                    throw new Error('Tenant is not set');
                }
                if (!xAuthToken) {
                    throw new Error('xAuthToken is not set');
                }
                this.tenantStore.updateTenant(tenant);
                this.persistXAuthToken(xAuthToken);

                this.loggedIn = true;
            });
        }
    }

    public async logout(): Promise<void> {
        if (!this.loggedIn) {
            return;
        }

        const response = await fetch(`${this.apiUrl}/logout`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=UTF-8'
            }
        });

        if (response.status !== 200) {
            const e = await response.json();
            const { message } = e;

            if (message) {
                throw new Error(message);
            }
        }

        if (response.ok) {
            runInAction(() => {
                this.tenantStore.updateTenant(undefined);
                this.loggedIn = false;
                this.removeXauthToken();
            });
        }
    }
}
