/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CdnObjectDto {
    bucketName: string;
    objectKey: string;
}

export interface I18NText {
    en?: string;
    de?: string;
    pl?: string;
    es?: string;
}

export interface NewsTemplateDataDto {
    title: I18NText;
    content: I18NText;
    icon: string;
    shortDescription: I18NText;
    image: CdnObjectDto;
    tags: string[];
    /** @format date-time */
    publicationDateTime?: string;
}

export interface NewsTemplateDto {
    /** @format int64 */
    id: number;
    data: NewsTemplateDataDto;
}

export interface NewsTemplateReleaseCreateDto {
    forceUpdate: boolean;
    tenants: string[];
}

export interface NewsTemplateReleaseDto {
    /** @format int64 */
    id: number;
    status: NewsTemplateReleaseStatus;
    /** @format date-time */
    date: string;
    forceUpdate: boolean;
    tenants: string[];
}

export enum NewsTemplateReleaseStatus {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    RELEASED = 'RELEASED',
    ERROR = 'ERROR'
}

export enum TenantStatus {
    ACTIVE = 'ACTIVE',
    LOCKED = 'LOCKED',
    INACTIVE = 'INACTIVE'
}

export enum TenantType {
    CLIENT = 'CLIENT',
    FORMER_CLIENT = 'FORMER_CLIENT',
    MYSPORTS_BASE = 'MYSPORTS_BASE',
    PARTNER = 'PARTNER',
    DEMO = 'DEMO',
    OPEN_DEMO = 'OPEN_DEMO',
    UNMANAGED = 'UNMANAGED'
}

export enum TenantSource {
    ADMIN_WEBCLIENT = 'ADMIN_WEBCLIENT',
    TRY_CORPORATE_WEBCLIENT = 'TRY_CORPORATE_WEBCLIENT',
    TRY_REWARDS_WEBCLIENT = 'TRY_REWARDS_WEBCLIENT',
    NOX_API = 'NOX_API',
    OPEN_DEMO = 'OPEN_DEMO',
    E2E = 'E2E'
}

export enum TenantProductType {
    TEAMS = 'TEAMS',
    CORPORATE = 'CORPORATE'
}

export interface ContractDto {
    /** @format int32 */
    allowedLicenses?: number;
    contractModel: SizeRange;
    /** @format date-time */
    validFrom: string;
    /** @format date-time */
    validTo?: string;
}

export enum PromoterType {
    COMPANY = 'COMPANY',
    SPORT_ASSOCIATION = 'SPORT_ASSOCIATION'
}

export enum SizeRange {
    MICRO = 'MICRO',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    X_LARGE = 'X_LARGE',
    ENTERPRISE = 'ENTERPRISE'
}

export interface TenantCompanyDto {
    /** @format int64 */
    id: number;
    companyName: string;
    logo?: CdnObjectDto;
    header?: CdnObjectDto;
    promoterType: PromoterType;
    lexofficeLink?: string;
    hubspotLink?: string;
    /** @format int32 */
    connectedEmployeeCount: number;
    publicChallengeParticipationEnabled: boolean;
}

export interface TenantDto {
    name: string;
    status: TenantStatus;
    dbHostname: string;
    /** @format int32 */
    dbPort: number;
    dbReadonlyHostname?: string;
    /** @format int32 */
    dbReadonlyPort?: number;
    dbName: string;
    sslEnabled?: boolean;
    type: TenantType;
    productType: TenantProductType;
    source: TenantSource;
    /** @format date-time */
    createdDate: string;
    company: TenantCompanyDto;
    contract: ContractDto;
}

export interface NewsTemplatePageEntryDto {
    /** @format int64 */
    id: number;
    title: string;
    icon: string;
    shortDescription: string;
    /** @format date-time */
    createdDate: string;
}

export interface PageNewsTemplatePageEntryDto {
    content: NewsTemplatePageEntryDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int32 */
    totalPages?: number;
    /** @format int64 */
    totalElements?: number;
}

export interface NewsTemplateDetailsDto {
    /** @format int64 */
    id: number;
    data: NewsTemplateDataDto;
    releases: NewsTemplateReleaseDto[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/corporate-tenant-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(
            typeof value === 'number' ? value : `${value}`
        )}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${
                queryString ? `?${queryString}` : ''
            }`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal: cancelToken
                    ? this.createAbortSignal(cancelToken)
                    : requestParams.signal,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title MySports Rewards Corporate Tenant API
 * @version v1
 * @license MySports License (https://www.mysports.com)
 * @baseUrl /corporate-tenant-api
 * @contact MySports Contact <move@mysports-rewards.com>
 *
 * Service to manage corporate tenants master DB and handle tenant onboarding
 */
export class Api<SecurityDataType extends unknown> {
    http: HttpClient<SecurityDataType>;

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http;
    }

    corporate = {
        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerGet
         * @request GET:/corporate/v1/news-templates/{id}
         * @secure
         * @response `200` `NewsTemplateDetailsDto` OK
         */
        newsTemplateControllerGet: (id: number, params: RequestParams = {}) =>
            this.http.request<NewsTemplateDetailsDto, any>({
                path: `/corporate/v1/news-templates/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerUpdate
         * @request PUT:/corporate/v1/news-templates/{id}
         * @secure
         * @response `200` `NewsTemplateDto` OK
         */
        newsTemplateControllerUpdate: (
            id: number,
            data: NewsTemplateDataDto,
            params: RequestParams = {}
        ) =>
            this.http.request<NewsTemplateDto, any>({
                path: `/corporate/v1/news-templates/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerDelete
         * @request DELETE:/corporate/v1/news-templates/{id}
         * @secure
         * @response `204` `void` No Content
         */
        newsTemplateControllerDelete: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/corporate/v1/news-templates/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerGetPage
         * @request GET:/corporate/v1/news-templates
         * @secure
         * @response `200` `PageNewsTemplatePageEntryDto` OK
         */
        newsTemplateControllerGetPage: (
            query?: {
                /**
                 * @format int32
                 * @min 0
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 1000
                 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<PageNewsTemplatePageEntryDto, any>({
                path: `/corporate/v1/news-templates`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerCreate
         * @request POST:/corporate/v1/news-templates
         * @secure
         * @response `201` `NewsTemplateDto` Created
         */
        newsTemplateControllerCreate: (
            data: NewsTemplateDataDto,
            params: RequestParams = {}
        ) =>
            this.http.request<NewsTemplateDto, any>({
                path: `/corporate/v1/news-templates`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerRelease
         * @request POST:/corporate/v1/news-templates/{templateId}/releases
         * @secure
         * @response `200` `NewsTemplateReleaseDto` OK
         */
        newsTemplateControllerRelease: (
            templateId: number,
            data: NewsTemplateReleaseCreateDto,
            params: RequestParams = {}
        ) =>
            this.http.request<NewsTemplateReleaseDto, any>({
                path: `/corporate/v1/news-templates/${templateId}/releases`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags news-template-controller
         * @name NewsTemplateControllerUploadImage
         * @request POST:/corporate/v1/news-template-images
         * @secure
         * @response `200` `CdnObjectDto` OK
         */
        newsTemplateControllerUploadImage: (
            data: {
                /** @format binary */
                file: File;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<CdnObjectDto, any>({
                path: `/corporate/v1/news-template-images`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.FormData,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-corporate-controller
         * @name TenantCorporateControllerGetTenants
         * @request GET:/corporate/v1/tenants
         * @secure
         * @response `200` `(TenantDto)[]` OK
         */
        tenantCorporateControllerGetTenants: (
            query?: {
                searchString?: string;
                /** @uniqueItems true */
                names?: string[];
                /** @uniqueItems true */
                status?: TenantStatus[];
                /** @uniqueItems true */
                type?: TenantType[];
                /** @uniqueItems true */
                source?: TenantSource[];
                /** @uniqueItems true */
                productTypes?: TenantProductType[];
            },
            params: RequestParams = {}
        ) =>
            this.http.request<TenantDto[], any>({
                path: `/corporate/v1/tenants`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            })
    };
}
