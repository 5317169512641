import { component, initialize } from 'tsdi';
import {
    Api as GatewayApiClient,
    HttpClient as TenantHttpClient
} from './api/TenantClient';
import {
    Api as ChallengeApiClient,
    HttpClient as ChallengeHttpClient
} from './api/ChallengeClient';
import type { ApiConfig } from './api/TenantClient';
import { injectTSDI } from '@core/tsdi';
import { Api } from './api';
import { TenantStore } from '@core/tenant';
import { Url } from '@core/url';
import { navigate } from 'takeme';
import { bind } from 'lodash-decorators';
import { autodisposer } from '@core/reactions';
import { Lambda, makeObservable, observable, reaction } from 'mobx';
import { links } from '@core/router';

@component
export class GatewayApi {
    @initialize
    public init(): void {
        makeObservable(this, {
            gatewayClient: observable,
            challengeClient: observable
        });

        if (this.apiClient.loggedIn && this.apiClient.xAuthToken) {
            this.regenerateTenantClient();
            this.regenerateChallengeClient();
        }
    }

    private get apiClient() {
        return injectTSDI(Api);
    }

    private get tenantStore() {
        return injectTSDI(TenantStore);
    }

    private get url() {
        return injectTSDI(Url);
    }

    public gatewayClient: GatewayApiClient<unknown> | undefined;
    public challengeClient: ChallengeApiClient<unknown> | undefined;

    @bind
    public generateApiConfig(apiUrl: string, tenant?: string): ApiConfig {
        return {
            baseUrl: apiUrl,
            baseApiParams: {
                mode: 'cors',
                headers: {
                    ...(this.apiClient.xAuthToken
                        ? { SESSION: this.apiClient.xAuthToken }
                        : {}),
                    ...(tenant ? { 'x-tenant-id': tenant } : {})
                },
                format: 'json'
            },
            customFetch: (input, init) =>
                fetch(input, init)
                    .then((response) => {
                        if (response.ok) {
                            return response;
                        }
                        if (response.status === 401) {
                            navigate(links.dashboard());
                        }

                        return response;
                    })
                    .catch((error) => {
                        console.error('API request failed:', error);
                        throw error;
                    })
        };
    }

    private generateTenantClient(apiConfig: ApiConfig<unknown>) {
        const httpClient = new TenantHttpClient(apiConfig);

        return new GatewayApiClient(httpClient);
    }

    public regenerateTenantClient() {
        this.gatewayClient = this.generateTenantClient(
            this.generateApiConfig(
                this.corporateGatewayApiUrl,
                this.tenantStore.tenant
            )
        );
    }

    public get corporateGatewayApiUrl() {
        const { env } = this.url;

        if (env === 'prod') {
            return 'https://corporate-gateway.api.mysports-rewards.com/corporate-tenant-api';
        }

        return 'https://corporate-gateway.api.dev.mysports-rewards.com/corporate-tenant-api';
    }

    private generateChallengeClient(apiConfig: ApiConfig<unknown>) {
        const httpClient = new ChallengeHttpClient(apiConfig);

        return new ChallengeApiClient(httpClient);
    }

    public regenerateChallengeClient() {
        this.challengeClient = this.generateChallengeClient(
            this.generateApiConfig(
                this.challengeApiUrl,
                this.tenantStore.tenant
            )
        );
    }
    public get challengeApiUrl() {
        const { env } = this.url;

        if (env === 'prod') {
            return 'https://corporate-gateway.api.mysports-rewards.com/challenge-api';
        }
        return 'https://corporate-gateway.api.dev.mysports-rewards.com/challenge-api';
    }

    @autodisposer.tsdi
    public reactions(): Lambda[] {
        return [
            reaction(
                () => this.apiClient.xAuthToken,
                (xAuthToken) => {
                    if (xAuthToken) {
                        this.regenerateTenantClient();
                        this.regenerateChallengeClient();
                    } else {
                        this.gatewayClient = undefined;
                        this.challengeClient = undefined;
                    }
                }
            )
        ];
    }
}
