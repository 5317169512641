import {
    toZonedDateTime,
    ZonedDateTime,
    LocalDate,
    toLocalDate
} from '@ms-rewards/date-lib';
import { DateRange as PickerDateRange } from 'materialui-daterange-picker';
import { getTimeZone } from '../config';

export interface ZonedDateRange {
    startDate?: ZonedDateTime;
    endDate?: ZonedDateTime;
}

export interface LocalDateRange {
    startDate?: LocalDate;
    endDate?: LocalDate;
}

export interface Time {
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
}
export interface TimeRange {
    start: Time;
    end: Time;
}

export function getStartDayOfNextMonthInDateFormat(time?: Time): ZonedDateTime {
    const date = ZonedDateTime.now(getTimeZone());
    const startDay = date.addMonths(1).startOf('month');
    if (time) {
        return startDay.setHour(time.hours).setMinute(time.minutes);
    }
    return startDay;
}

export function getStartDayOfNextMonth(time?: Time): ZonedDateTime {
    return getStartDayOfNextMonthInDateFormat(time);
}

export function getEndDayOfNextMonthInDateFormat(time?: Time): ZonedDateTime {
    const date = ZonedDateTime.now(getTimeZone());
    const endDay = date.addMonths(1).endOf('month');

    if (time) {
        return endDay.setHour(time.hours).setMinute(time.minutes);
    }

    return endDay;
}

export function getEndDayOfNextMonth(time?: Time): ZonedDateTime {
    return getEndDayOfNextMonthInDateFormat(time);
}

export function setTime(
    range: PickerDateRange,
    timeRange: TimeRange
): PickerDateRange {
    range.endDate?.setHours(timeRange.end.hours);
    range.endDate?.setMinutes(timeRange.end.minutes);
    range.endDate?.setSeconds(timeRange.end.seconds);
    range.endDate?.setMilliseconds(timeRange.end.milliseconds);
    range.startDate?.setHours(timeRange.start.hours);
    range.startDate?.setMinutes(timeRange.start.minutes);
    range.startDate?.setSeconds(timeRange.start.seconds);
    range.startDate?.setMilliseconds(timeRange.start.milliseconds);
    return range;
}

export function setZonedTime(time: Time, date?: ZonedDateTime) {
    if (!date) {
        return;
    }
    return date
        .setHour(time.hours)
        .setMinute(time.minutes)
        .setSecond(time.seconds)
        .setMillisecond(time.milliseconds);
}

export function convertToZonedDateRange(
    date: PickerDateRange,
    timeRange?: TimeRange
): ZonedDateRange {
    if (timeRange) {
        const endDate = setZonedTime(
            timeRange.end,
            toZonedDateTime(date.endDate, getTimeZone())
        );
        const startDate = setZonedTime(
            timeRange.start,
            toZonedDateTime(date.startDate, getTimeZone())
        );
        return {
            startDate,
            endDate
        };
    }
    return {
        startDate: toZonedDateTime(date.startDate, getTimeZone()),
        endDate: toZonedDateTime(date.endDate, getTimeZone())
    };
}

export function convertToLocalDateRange(date: PickerDateRange): LocalDateRange {
    return {
        startDate: toLocalDate(date.startDate),
        endDate: toLocalDate(date.endDate)
    };
}

export function startOfYear(year: number) {
    return LocalDate.now(getTimeZone()).setYear(year).startOf('year');
}

export function endOfYear(year: number) {
    return LocalDate.now(getTimeZone()).setYear(year).endOf('year');
}

export function truncateDateToMidnight(val: ZonedDateTime) {
    return val.setHour(0).setMinute(0).setSecond(0).setMillisecond(0);
}
