import { getImage as spaGetImage } from '@ms-rewards/cdn-image-util';
import { CDN_DEV_URL, CDN_PROD_URL } from '../config';
import { injectTSDI } from '@core/tsdi';
import { Url } from '@core/url';

type Params = Parameters<typeof spaGetImage>;

export function getImage(imageDto?: Params[1], edits?: Params[2]) {
    const { env } = injectTSDI(Url);
    const CDN_URL = env === 'dev' ? CDN_DEV_URL : CDN_PROD_URL;

    return spaGetImage(CDN_URL, imageDto, edits);
}
