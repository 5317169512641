import { observer } from 'mobx-react';
import Joyride, { CallBackProps } from 'react-joyride';
import { interFont, theme } from '../app/theme';
import { I18n } from '../core/i18n';
import { injectTSDI } from '../core/tsdi';
import { TourStore } from './tour-store';

export const TourGuide = observer(() => {
    const {
        steps,
        stepIndex,
        getHelpers,
        continuous,
        run,
        scrollToFirstStep,
        showProgress,
        callback
    } = injectTSDI(TourStore);
    const { __ } = injectTSDI(I18n);

    return (
        <>
            <Joyride
                steps={steps}
                stepIndex={stepIndex}
                getHelpers={getHelpers}
                continuous={continuous}
                run={run}
                scrollToFirstStep={scrollToFirstStep}
                showProgress={showProgress}
                showSkipButton={false}
                disableScrolling={true}
                callback={callback as (props: CallBackProps) => void}
                disableOverlayClose={true}
                spotlightClicks={true}
                styles={{
                    options: {
                        zIndex: 10000,
                        primaryColor: theme.palette.primary.main
                    },
                    tooltip: {
                        fontFamily: interFont,
                        borderRadius: '4px'
                    },
                    buttonNext: {
                        fontSize: '15px',
                        fontFamily: interFont,
                        padding: '8px 20px',
                        outline: 'none'
                    },
                    buttonBack: { fontSize: '15px', fontFamily: interFont }
                }}
                locale={{
                    back: __('commons.back'),
                    close: __('commons.close'),
                    last: __('commons.finish'),
                    next: __('commons.forward'),
                    open: __('commons.open'),
                    skip: __('commons.skip')
                }}
            />
        </>
    );
});
