import { TextField, TextFieldProps } from './index';
import LanguageSelect from '../language-select';
import { Box, InputAdornment } from '@mui/material';
import { useLocalizedField } from '../hooks/use-localised-field';
import { injectTSDI } from '../../core/tsdi';
import { UserStore } from '../../core/user';
import { I18nText } from '../../core/api/dtos';

interface LocalizedTextFieldProps
    extends Omit<TextFieldProps, 'value' | 'onChange'> {
    value: I18nText;
    onChange(value: I18nText): void;
}

const LocalizedTextField = ({
    value,
    onChange,
    ...props
}: LocalizedTextFieldProps) => {
    const { supportedLanguageOptions, mainLanguageKey } = injectTSDI(UserStore);

    const { selectedValue, options, language, setLanguage } = useLocalizedField(
        value,
        supportedLanguageOptions,
        mainLanguageKey
    );

    function handleOnChange(e: string) {
        onChange({ ...value, [language]: e });
    }

    return (
        <TextField
            {...props}
            value={selectedValue}
            onChange={handleOnChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Box width={50} height={32} zIndex={8}>
                            <LanguageSelect
                                options={options}
                                value={language}
                                onChange={(language) => {
                                    setLanguage(language);
                                }}
                            />
                        </Box>
                    </InputAdornment>
                )
            }}
        />
    );
};

export default LocalizedTextField;
