import {
    Button as MaterialButton,
    ButtonProps as MaterialButtonProps
} from '@mui/material';
import * as React from 'react';
import { Spinner } from '../spinner';

interface ButtonProps {
    className?: string;
    children: React.ReactNode;
    loading?: boolean;
    color?: MaterialButtonProps['color'];
    size?: MaterialButtonProps['size'];
    type?: 'submit' | 'button' | 'reset';
    fullWidth?: boolean;
    disabled?: boolean;
    capitalize?: boolean;
    variant?: 'text' | 'contained' | 'outlined' | undefined;
    'data-testid'?: string;
    startIcon?: React.ReactNode;
    onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

export function Button(props: ButtonProps) {
    const {
        color = 'primary',
        fullWidth = true,
        size = 'large',
        variant = 'contained',
        disabled,
        className,
        type,
        capitalize,
        'data-testid': dataTestId,
        onClick,
        startIcon
    } = props;
    return (
        <MaterialButton
            fullWidth={fullWidth}
            variant={variant}
            className={className}
            color={color}
            type={type}
            size={size}
            onClick={onClick}
            disabled={disabled}
            disableElevation
            data-testid={dataTestId}
            startIcon={startIcon}
            sx={{
                textTransform: capitalize ? 'capitalize' : 'none',
                whiteSpace: 'nowrap'
            }}
        >
            {props.loading ? <Spinner color="white" /> : props.children}
        </MaterialButton>
    );
}
