import CreateIcon from '@mui/icons-material/Create';
import {
    FormControl,
    InputLabel,
    Select as MaterialSelect,
    MenuItem,
    Box,
    IconButton
} from '@mui/material';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from '../tooltip';

// tslint:disable-next-line:no-any
export interface Option<T = any> {
    label: string;
    value: T;
    disabled?: boolean;
}

// tslint:disable-next-line:no-any
interface SelectProps<T = any> {
    label: string;
    required?: boolean;
    shrink?: boolean;
    value: T;
    error?: string;
    options: Option<T>[];
    disabled?: boolean;
    tooltipText?: string;
    action?: React.ReactNode;
    dataTestId?: string;
    onItemEdit?(value: T): void;
    handleClearClick?(): void;
    onChange(value: T): void;
}

const StyledTooltip = styled(Tooltip)`
    .MuiSvgIcon-root {
        margin-right: 6px;
        transform: scale(1.34) translateX(2px);
    }
`;

export function Select(props: SelectProps) {
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const theme = useTheme();

    return (
        <FormControl
            variant="standard"
            fullWidth
            data-testid={props.dataTestId}
        >
            <InputLabel
                ref={inputLabel}
                shrink={props.shrink}
                required={props.required}
                sx={{ '& .MuiFormLabel-asterisk ': { verticalAlign: 'top' } }}
            >
                <Box display="inline-flex" alignItems="center">
                    {props.tooltipText && (
                        <StyledTooltip title={props.tooltipText} />
                    )}
                    {props.label}
                </Box>
            </InputLabel>

            <MaterialSelect
                required={props.required}
                error={Boolean(props.error)}
                // tslint:disable-next-line:no-any
                onChange={(e) => props.onChange(e.target.value)}
                value={props.value || ''}
                disabled={props.disabled}
                renderValue={(selected) => {
                    const selectedItem = props.options.find(
                        (option) => option.value === selected
                    );
                    return (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                            {selectedItem?.label}
                        </Box>
                    );
                }}
                endAdornment={
                    props.value &&
                    props.handleClearClick && (
                        <IconButton
                            onClick={props.handleClearClick}
                            sx={{ position: 'absolute', right: 20 }}
                        >
                            <ClearIcon />
                        </IconButton>
                    )
                }
            >
                {props.options.map((option) => (
                    <MenuItem
                        key={option.label}
                        value={option.value}
                        sx={{
                            display: 'block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}
                    >
                        <Box
                            display="inline-flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Box
                                sx={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden'
                                }}
                            >
                                {option.label}
                            </Box>
                            {props.onItemEdit && (
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        props.onItemEdit?.(option.value);
                                    }}
                                >
                                    <CreateIcon
                                        sx={{ color: theme.palette.grey[600] }}
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </MenuItem>
                ))}
                {props.action && (
                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            width: '100%',
                            zIndex: 20,
                            display: 'flex',
                            marginBottom: '-8px',
                            borderTop: `1px solid ${theme.palette.grey[300]}`
                        }}
                        bgcolor="white"
                    >
                        {props.action}
                    </Box>
                )}
            </MaterialSelect>
        </FormControl>
    );
}
