import {
    Box,
    Divider,
    Drawer,
    Grid,
    Link,
    Typography,
    useTheme
} from '@mui/material';
import { observer } from 'mobx-react';
import { MSLogoHorizontal } from '../../components/svg-icons/ms-logo-horizontal';
import { Api } from '../../core/api';
import { AppRouter } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';
import { AppStore } from '../store';
import { SectionNavList } from './section-nav-list';
import { SupportNavItem } from './support-nav-item';

export const drawerWidth = 265;
export const drawerClosedWidth = 57;

export const Nav = observer(() => {
    const theme = useTheme();
    const appStore = injectTSDI(AppStore);
    const { loggedIn } = injectTSDI(Api);
    const { createLink } = injectTSDI(AppRouter);

    if (!loggedIn) {
        return null;
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            open={appStore.drawerOpen}
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    background: 'rgb(36, 47, 58)',
                    ...(appStore.drawerOpen
                        ? {
                              width: drawerWidth,
                              transition: theme.transitions.create('width', {
                                  easing: theme.transitions.easing.sharp,
                                  duration:
                                      theme.transitions.duration.enteringScreen
                              })
                          }
                        : {
                              transition: theme.transitions.create('width', {
                                  easing: theme.transitions.easing.sharp,
                                  duration:
                                      theme.transitions.duration.leavingScreen
                              }),
                              overflowX: 'hidden',
                              width: drawerClosedWidth
                          })
                }
            }}
        >
            <Link
                color="inherit"
                href={createLink('indexPublic')}
                underline="none"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent={
                        appStore.drawerOpen ? 'flex-start' : 'center'
                    }
                    alignItems="center"
                    wrap="nowrap"
                    sx={{
                        height: 60,
                        padding: appStore.drawerOpen
                            ? theme.spacing(2)
                            : 'initial'
                    }}
                >
                    <MSLogoHorizontal short={!appStore.drawerOpen} />
                </Grid>
            </Link>
            <Divider />
            <Box paddingX={2} pt={2}>
                {appStore.drawerOpen ? (
                    <Typography variant="body2" color="white">
                        <b>PulseCheck</b> <i>Professional</i>
                    </Typography>
                ) : (
                    <Typography
                        variant="body2"
                        color="white"
                        textAlign="center"
                        fontSize={10}
                    >
                        <b>
                            Pulse
                            <br />
                            Check
                        </b>
                        <br />
                        <i>Pro</i>
                    </Typography>
                )}
            </Box>
            <SectionNavList link="indexPrivate" />
            <SupportNavItem />
        </Drawer>
    );
});
