import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';

interface MarkdownProps {
    children: string;
}

const StyledContainer = styled.div`
    p {
        margin-top: 0;
    }
    > *:first-child {
        margin-top: 0;
    }
`;
export const Markdown = ({ children }: MarkdownProps) => (
    <StyledContainer>
        <ReactMarkdown children={children} rehypePlugins={[rehypeRaw]} />
    </StyledContainer>
);
