import { Chip } from '@mui/material';
import { ChipTypeMap } from '@mui/material/Chip';
import { green, red, grey } from '@mui/material/colors';
import { theme } from '../../app/theme';

export type BadgeType = 'green' | 'red' | 'grey' | 'blue';

interface BadgeProps {
    type?: BadgeType;
    size?: ChipTypeMap['props']['size'];
    children?: string;
    className?: string;
}

export const Badge = (props: BadgeProps) => {
    function getClassName() {
        switch (props.type) {
            case 'green':
                return {
                    background: green[500],
                    color: '#fff'
                };
            case 'red':
                return {
                    background: red[500],
                    color: '#fff'
                };
            case 'grey':
                return {
                    background: grey[300]
                };
            case 'blue':
                return {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                };
        }
        return undefined;
    }

    if (!props.children) {
        return null;
    }

    return (
        <Chip
            className={props.className}
            sx={{
                ...getClassName()
            }}
            size={props.size}
            label={props.children.toUpperCase()}
        />
    );
};
