import EventIcon from '@mui/icons-material/Event';
import {
    FilledInput,
    FormControl,
    FormGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel
} from '@mui/material';
import { red } from '@mui/material/colors';
import { toZonedDateTime, ZonedDateTime } from '@ms-rewards/date-lib';
import {
    DateRangePicker,
    DateRange as PickerDateRange
} from 'materialui-daterange-picker';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';
import {
    getStartDayOfNextMonthInDateFormat,
    getEndDayOfNextMonthInDateFormat,
    TimeRange,
    ZonedDateRange,
    convertToZonedDateRange
} from '../../core/utils/date';
import { useOnClickOutside } from '../hooks/use-click-outside';
import { ZonedDateTimeRangeDto } from '../../core/api/ChallengeClient';
import { getTimeZone } from '../../core/config';

interface RangePickerProps {
    startDate?: ZonedDateTime;
    endDate?: ZonedDateTime;
    label: string;
    required?: boolean;
    disabled?: boolean;
    timeRange?: TimeRange;
    error?: string;
    minDate?: Date;
    onChange(range?: ZonedDateRange): void;
}

const StyledFilledInput = styled(FilledInput)``;

const StyledFormControl = styled(FormControl)`
    ${StyledFilledInput} {
        background-color: transparent;
        &.MuiFilledInput-root::hover,
        &.Mui-focused {
            background-color: transparent;
        }
        input {
            padding-left: 0;
        }
    }
`;

const DateRangePickerContainer = styled.div<{ open: boolean }>`
    position: absolute;
    top: 100%;
    display: ${({ open }) => (open ? 'block' : 'none')};
    z-index: 9;
    div[class*='materialui-daterange-picker-makeStyles-dateRangeBackdrop-'] {
        pointer-events: none;
    }
    ${({ theme }) => css`
        button[class*='materialui-daterange-picker-makeStyles-filled-'] {
            background-color: ${theme.palette.primary.main};
        }

        button[class*='materialui-daterange-picker-makeStyles-outlined-'] {
            border: 1px solid ${theme.palette.primary.main};
        }
    `};
`;

export const RangePicker = ({
    startDate,
    endDate,
    label,
    required,
    disabled,
    onChange,
    timeRange,
    minDate,
    error
}: RangePickerProps) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const toggle = () => setOpen(!open);
    const ref = React.useRef(null);

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        event.stopPropagation();
        setOpen(false);
    };

    useOnClickOutside(ref, handleClickOutside);

    const handleDateChange = (range: PickerDateRange) => {
        const zonedRange = convertToZonedDateRange(range, timeRange);
        onChange(zonedRange);
        setValue(formatDateRange(zonedRange));
        setOpen(false);
    };

    React.useEffect(() => {
        if (startDate && endDate) {
            setValue(
                formatDateRange({
                    startDate,
                    endDate
                })
            );
        }
    }, [startDate, endDate]);

    const handleMouseDownIcon = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <FormGroup style={{ position: 'relative' }}>
            <StyledFormControl
                variant="standard"
                fullWidth
                focused={open}
                disabled={disabled}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!disabled) {
                        toggle();
                    }
                }}
                required={required}
            >
                <InputLabel
                    htmlFor="filled-adornment-password"
                    shrink
                    required={required}
                    error={Boolean(error)}
                >
                    {label}
                </InputLabel>
                <StyledFilledInput
                    type="text"
                    value={value}
                    error={Boolean(error)}
                    /*TODO add form change event */
                    onChange={() => null}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => null}
                                onMouseDown={handleMouseDownIcon}
                                edge="end"
                                size="large"
                            >
                                <EventIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />

                {error && (
                    <FormHelperText style={{ color: red['500'] }}>
                        {error}
                    </FormHelperText>
                )}
            </StyledFormControl>
            <DateRangePickerContainer
                open={open}
                ref={ref}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <DateRangePicker
                    open
                    toggle={() => null}
                    initialDateRange={{
                        startDate: startDate?.toDate(),
                        endDate: endDate?.toDate()
                    }}
                    onChange={handleDateChange}
                    minDate={minDate}
                    definedRanges={[
                        {
                            startDate: getStartDayOfNextMonthInDateFormat(
                                timeRange?.start
                            ).toDate(),
                            endDate: getEndDayOfNextMonthInDateFormat(
                                timeRange?.end
                            ).toDate(),
                            label: 'Next Month'
                        }
                    ]}
                />
            </DateRangePickerContainer>
        </FormGroup>
    );
};

export function formatDateRange({ startDate, endDate }: ZonedDateRange) {
    const { formatDateRange } = injectTSDI(I18n);
    if (startDate && endDate) {
        return formatDateRange({ from: startDate, to: endDate });
    }
    return '';
}
export function zonedDateRangeDtoToZonedDateTimeRangeDto(
    date: ZonedDateTimeRangeDto
): ZonedDateRange {
    return {
        startDate: toZonedDateTime(date.from, getTimeZone()),
        endDate: toZonedDateTime(date.to, getTimeZone())
    };
}
