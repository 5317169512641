import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    Autocomplete as MaterialAutocomplete,
    Button,
    Chip,
    FormGroup,
    FormHelperText
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';

// tslint:disable-next-line:no-any
export interface MultiOption {
    title: string;
    value: string;
}

interface MultiAutocompleteProps {
    className?: string;
    label?: string;
    options?: string[];
    invalidOptions?: number[];
    value?: string[];
    inputValue?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    error?: string;
    minHeight?: number;
    helperText?: boolean;
    clearOnBlur?: boolean;

    'data-testid'?: string;
    onChange(value: string[]): void;
    onInputChange?(value: string): void;
}

export const MultiAutocomplete = React.forwardRef(
    (props: MultiAutocompleteProps, ref: React.Ref<HTMLInputElement>) => {
        const {
            options,
            value,
            label,
            placeholder,
            required,
            error,
            minHeight,
            invalidOptions,
            inputValue,
            disabled,
            clearOnBlur = true,
            onChange,
            onInputChange
        } = props;
        const { __ } = injectTSDI(I18n);
        const [text, setText] = React.useState<string>('');

        const saveNewLabel = (text: string, value: string[] = []) => {
            onChange([...value, text]);
            setText('');
        };
        const saveMultipleLabels = (
            newValue: string[] = [],
            value: string[] = []
        ) => {
            onChange([...value, ...newValue]);
            if (!inputValue) {
                setText('');
            }
        };

        React.useEffect(() => {
            setText(inputValue || '');
        }, [inputValue]);

        const handleInputChange = React.useCallback(
            (__: React.SyntheticEvent<Element, Event>, value: string) => {
                if (value === ',') {
                    return;
                }
                if (value.includes(',')) {
                    saveMultipleLabels(
                        value
                            .split(',')
                            .map((val) => val.trim())
                            .filter((val) => val)
                    );
                    return;
                }
                onInputChange?.(value);
                if (!inputValue) {
                    setText(value);
                }
            },
            []
        );

        return (
            <FormGroup id={props['data-testid']}>
                <MaterialAutocomplete
                    size="medium"
                    ref={ref}
                    multiple
                    fullWidth
                    data-testid={props['data-testid']}
                    value={value}
                    inputValue={text}
                    options={options || []}
                    disabled={disabled}
                    onInputChange={handleInputChange}
                    sx={{
                        '& .MuiFormControl-root ': {
                            position: 'relative',
                            minHeight: minHeight || 'auto',
                            '& .MuiInput-root': {
                                position: 'static',
                                paddingRight: '30px !important'
                            }
                        }
                    }}
                    popupIcon={false}
                    open={text.length > 0}
                    onKeyPress={(event) => {
                        if (
                            text.length > 0 &&
                            (event.key === 'Enter' || event.key === ',')
                        ) {
                            saveNewLabel(text, value);
                            event.preventDefault();
                        }
                    }}
                    onChange={(__, newValue) => {
                        onChange(newValue as string[]);
                    }}
                    renderTags={(tagValue, getTagProps) => {
                        return (
                            <>
                                {tagValue.map((option, index) => (
                                    <Chip
                                        label={option}
                                        color={
                                            invalidOptions &&
                                            invalidOptions.includes(index)
                                                ? 'error'
                                                : 'default'
                                        }
                                        {...getTagProps({ index })}
                                    />
                                ))}
                            </>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            disabled={disabled}
                            label={label}
                            required={required}
                            placeholder={
                                !value || value.length === 0
                                    ? placeholder
                                    : undefined
                            }
                            error={Boolean(error)}
                            variant="standard"
                            data-testid="autocomplete-input"
                            InputLabelProps={{
                                shrink: true
                            }}
                            fullWidth
                        />
                    )}
                    filterSelectedOptions
                    clearOnBlur={clearOnBlur}
                    noOptionsText={
                        text.length > 0 ? (
                            <Button
                                data-testid="add-tag-button"
                                fullWidth
                                endIcon={<AddIcon />}
                                onClick={() => {
                                    saveNewLabel(text, value);
                                }}
                            >
                                {text} {__('cp.news.tag')}
                            </Button>
                        ) : (
                            ''
                        )
                    }
                />
                {props.helperText && Boolean(props.error) && (
                    <FormHelperText sx={{ color: 'error.main' }}>
                        {props.error}
                    </FormHelperText>
                )}
            </FormGroup>
        );
    }
);
