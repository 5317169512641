import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';

// tslint:disable-next-line:no-any
export interface ContextMenuOption<T = any> {
    label: string | ((value: T) => string);
    value?: T;
    name?: string;
    dataTestId?: string;
    divider?: boolean;
    hidden?(value: T): boolean;
    action(value: T): void;
}

interface ContextMenuProps {
    children?: React.ReactNode;
    items: ContextMenuOption[];
}

export function ContextMenu(props: ContextMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const items = props.items.filter((item) =>
        item.hidden ? !item.hidden(item.value) : true
    );

    if (items.length === 0) {
        return null;
    }

    return (
        <>
            {props.children ? (
                <div onClick={onClick}>{props.children}</div>
            ) : (
                <IconButton
                    onClick={onClick}
                    size="large"
                    data-testid="table-context-menu-button"
                >
                    <MoreVertIcon />
                </IconButton>
            )}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {items.map((item, i) => (
                    <MenuItem
                        key={String(i)}
                        onClick={() => {
                            item.action(item.value);
                            onClose();
                        }}
                        sx={{
                            borderBottom: item.divider
                                ? `1px solid ${grey[300]}`
                                : 'none'
                        }}
                        data-testid={
                            item.dataTestId || 'table-context-menu-item'
                        }
                    >
                        {typeof item.label === 'string'
                            ? item.label
                            : item.label(item.value)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
