import { TeamRulesStore } from './team-rules/store';
import { FieldState, FormState } from 'formstate';
import { component } from 'tsdi';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { wrapInFormField } from '@core/forms';
import { injectTSDI } from '@core/tsdi';
import { wrapRequest } from 'wrap-request';
import { autodisposer } from '@core/reactions';
import { atLeastOne } from '@core/utils/validators';
import { ChallengeDataStore } from '../data-store';
import {
    ChallengeActivityType,
    ChallengeDetailsManagementDto,
    ChallengeRuleConfigDto,
    ZonedDateTimeRangeDto
} from '@core/api/ChallengeClient';
import { GatewayApi } from '@core/gateway-api';
import { formErrors, isFormStateDirty, makeFormClean } from '@core/utils/form';
import { StepStatus } from '../store';
import { bind } from 'lodash-decorators';
import { assertDefined } from '@core/utils/error';
import { toZonedDateTime, ZonedDateTime } from '@ms-rewards/date-lib';
import { getTimeZone } from '@core/config';
import {
    getEndDayOfNextMonthInDateFormat,
    getStartDayOfNextMonthInDateFormat,
    truncateDateToMidnight
} from '@core/utils/date';

export type ChallengeRulesFormType = ReturnType<
    ChallengeRulesStore['generateForm']
>;

export const DEFAULT_CHALLENGE_TIME = {
    start: { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 },
    end: { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }
};
@component
export class ChallengeRulesStore {
    constructor() {
        this.form = this.generateForm(
            this.challengeDataStore.challengeConfiguration
        );

        makeObservable(this, {
            form: observable,
            state: observable,
            isActive: observable,
            fields: computed,
            errors: computed
        });
    }
    private teamRulesStore = injectTSDI(TeamRulesStore);

    private get challengeClient() {
        return injectTSDI(GatewayApi).challengeClient;
    }

    private get challengeDataStore() {
        return injectTSDI(ChallengeDataStore);
    }

    public form: ChallengeRulesFormType;
    public state: StepStatus = StepStatus.INCOMPLETE;
    public isActive: boolean = false;

    private generateForm(configuration?: ChallengeDetailsManagementDto) {
        const complete =
            configuration?.ruleConfig?.personalRuleSet ||
            configuration?.ruleConfig?.teamRuleSet;

        this.state = complete ? StepStatus.COMPLETED : StepStatus.INCOMPLETE;

        const range = this.formatCurrentDate(
            configuration?.ruleConfig?.dateTimeRange
        );
        return new FormState({
            timeFrom: new FieldState<ZonedDateTime | undefined>(
                range.timeFrom
            ).validators(this.futureDateValidator),
            timeTo: new FieldState<ZonedDateTime | undefined>(
                range.timeTo
            ).validators(this.futureDateValidator),
            supportedActivityTypes: new FieldState<ChallengeActivityType[]>(
                configuration?.ruleConfig?.supportedActivityTypes || [
                    ChallengeActivityType.STEPS,
                    ChallengeActivityType.CYCLING,
                    ChallengeActivityType.STUDIO_VISIT
                ]
            ).validators(atLeastOne('challenge.field.type')),
            teamRules: new FieldState<boolean>(
                Boolean(configuration?.ruleConfig?.teamRuleSet)
            ).validators(this.rulesValidator),
            personalRules: new FieldState<boolean>(
                Boolean(configuration?.ruleConfig?.personalRuleSet)
            ).validators(this.rulesValidator)
        });
    }

    @bind
    private rulesValidator(): I18nKey | false {
        if (
            Boolean(this.fields.teamRules.value) ||
            Boolean(this.fields.personalRules.value)
        ) {
            return false;
        } else {
            return 'cp.challenge.field.start.end.date';
        }
    }

    @bind
    private futureDateValidator(
        val: ZonedDateTime | undefined
    ): I18nKey | false {
        if (this.challengeDataStore.disabledRulesEdit) {
            return false;
        } else if (!val) {
            return 'cp.challenge.field.start.end.date';
        }
        val = truncateDateToMidnight(val);
        const today = truncateDateToMidnight(
            toZonedDateTime(new Date(), getTimeZone()).setHour(12).subDays(1)
        ).toDate();

        if (val.isBefore(today)) {
            return 'cp.challenge.field.start.end.date.error';
        }

        return false;
    }

    public get errors() {
        return formErrors(this.form);
    }

    public get formIsDirty() {
        return isFormStateDirty(this.form.$) || this.teamRulesStore.formIsDirty;
    }
    public get fields() {
        return {
            supportedActivityTypes: wrapInFormField(
                this.form.$.supportedActivityTypes
            ),
            timeFrom: wrapInFormField(this.form.$.timeFrom),
            timeTo: wrapInFormField(this.form.$.timeTo),
            teamRules: wrapInFormField(this.form.$.teamRules),
            personalRules: wrapInFormField(this.form.$.personalRules)
        };
    }

    public challengeRulesRequest = wrapRequest(
        async ({
            challengeId,
            data
        }: {
            challengeId: number;
            data: ChallengeRuleConfigDto;
        }) => {
            const result =
                await this.challengeClient?.management.challengeManagementControllerFillRuleConfig(
                    challengeId,
                    data
                );

            return result?.data;
        }
    );

    private teamRulesCheck(teamFormValidation: boolean) {
        return this.form.$.teamRules.value ? teamFormValidation : false;
    }

    @bind
    public async validateRules() {
        const validation = await this.form.validate();
        const teamRulesValidation = await this.teamRulesStore.form.validate();
        const teamRulesCheck = this.teamRulesCheck(
            teamRulesValidation.hasError
        );

        if (validation.hasError || teamRulesCheck) {
            this.state = StepStatus.ERROR;
            return true;
        } else if (this.formIsDirty) {
            this.state = StepStatus.MODIFIED;
            return false;
        }

        this.state = StepStatus.COMPLETED;
        makeFormClean(this.form);
        return false;
    }

    @bind
    public async updateChallengeRules() {
        const validation = await this.validateRules();

        if (validation) {
            this.state = StepStatus.ERROR;
        } else if (this.challengeDataStore.challengeId && this.formIsDirty) {
            this.state = StepStatus.UPDATING;
            const challenge = await this.challengeRulesRequest.request({
                challengeId: this.challengeDataStore.challengeId,
                data: this.generateFullRequestData()
            });
            this.form = this.generateForm(challenge);
            if (challenge?.ruleConfig) {
                await this.teamRulesStore.updateForm(challenge);
            }
            this.validateRules();
            this.onCollapsedToggle();
        }
    }

    @bind
    public onCollapsedToggle() {
        this.isActive = !this.isActive;
    }

    @bind
    public openStep() {
        this.isActive = true;
    }

    @bind
    public closeStep() {
        this.isActive = false;
    }

    private generateFullRequestData(): ChallengeRuleConfigDto {
        return {
            ...ChallengeRulesStore.generateRequestData(this.form),
            ...(this.form.$.teamRules.value && {
                teamRuleSet: this.teamRulesStore.generateRequestData(
                    this.teamRulesStore.form
                )
            })
        };
    }

    public static generateRequestData(
        form: ChallengeRulesFormType
    ): ChallengeRuleConfigDto {
        const {
            $: { timeFrom, timeTo, supportedActivityTypes }
        } = form;
        assertDefined(timeFrom.value);
        assertDefined(timeTo.value);
        return {
            supportedActivityTypes: supportedActivityTypes.value,
            dateTimeRange: {
                from: timeFrom.value.toJSON(),
                to: timeTo.value.toJSON()
            }
        };
    }

    private formatCurrentDate(range?: ZonedDateTimeRangeDto) {
        if (range) {
            return {
                timeFrom: toZonedDateTime(range.from, getTimeZone()),
                timeTo: toZonedDateTime(range.to, getTimeZone())
            };
        }
        return {
            timeFrom: getStartDayOfNextMonthInDateFormat(
                DEFAULT_CHALLENGE_TIME.start
            ),
            timeTo: getEndDayOfNextMonthInDateFormat(DEFAULT_CHALLENGE_TIME.end)
        };
    }

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.challengeDataStore.challengeConfiguration,
                async (configuration) => {
                    if (configuration) {
                        this.form = this.generateForm(configuration);
                    }
                }
            )
        ];
    }
}
