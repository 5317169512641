import { injectTSDI } from './tsdi';
import { UserStore } from './user';

export const CDN_DEV_URL = 'https://cdn.dev.mysports-rewards.com';
export const CDN_PROD_URL = 'https://cdn.mysports-rewards.com';

export function getTimeZone() {
    return injectTSDI(UserStore).getTimeZone;
}

export const ZENDESK_KEY = 'f641221b-e29a-4a8a-a6c2-ec1f46432e6d';

export const MIXPANEL_DEV_TOKEN = 'bcc7631e93b12bea6c4723a4acdd61af';
export const MIXPANEL_PROD_TOKEN = '7e365d23f640f2464787a706a3512817';
