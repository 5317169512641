import {
    Pretend,
    IPretendRequest,
    Post,
    Delete,
    Get,
    Put,
    FormData
} from 'pretend';

import * as dtos from './dtos';
import { LocalDate, parseApiDates } from '@ms-rewards/date-lib';

const parseResult = (props: string[]) => {
    return (
        _target: Object,
        _key: string | symbol,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor: TypedPropertyDescriptor<any>
    ) => {
        const fn = descriptor.value;

        descriptor.value = async function(...args: unknown[]) {
            const result = await fn.apply(this, args);

            const tupleProps = props.map(prop => prop.split('.'));

            return parseApiDates(result, tupleProps);
        };

        return descriptor;
    };
};

export interface AchievementConfigController {
    create(
        requestBody: dtos.AchievementConfigDto
    ): Promise<dtos.AchievementConfigDetailsDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.AchievementConfigDetailsDto>;

    getDictionary(): Promise<dtos.DictionaryEntryDto[]>;

    getPage(query: {
        employeeGroupId?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageAchievementConfigDetailsDto>;

    update(
        id: number,
        requestBody: dtos.AchievementConfigDto
    ): Promise<dtos.AchievementConfigDetailsDto>;
}

export interface AnalyticsController {
    getAnalyticsFilters(): Promise<dtos.AnalyticsFiltersDto>;

    getLiveData(query: {
        dateFrom?: LocalDate;
        dateTo?: LocalDate;
    }): Promise<dtos.LiveDataKpiDto[]>;

    getParticipationChart(query: {
        dateFrom?: LocalDate;
        dateTo?: LocalDate;
    }): Promise<dtos.ChartDto>;

    getParticipationTable(query: {
        dateFrom?: LocalDate;
        dateTo?: LocalDate;
    }): Promise<dtos.TableRow[]>;

    getRewardsChart(query: {
        dateFrom?: LocalDate;
        dateTo?: LocalDate;
    }): Promise<dtos.RewardsChartDto>;

    getRewardsTable(query: {
        dateFrom?: LocalDate;
        dateTo?: LocalDate;
    }): Promise<dtos.RewardsTableRow[]>;
}

export interface AuthorizationEndpoint {
    login(): Promise<void>;

    logout(): Promise<void>;
}

export interface BusinessUnitController {
    create(
        requestBody: dtos.BusinessUnitCreateDto
    ): Promise<dtos.BusinessUnitWithServicePackagesDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.BusinessUnitDto>;

    getDictionary(): Promise<dtos.PageDictionaryEntryDto>;

    getPage(query: {
        employeeGroupId?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageBusinessUnitPageDto>;

    update(
        id: number,
        requestBody: dtos.BusinessUnitDto
    ): Promise<dtos.BusinessUnitWithServicePackagesDto>;
}

export interface CdnImageController {
    // tslint:disable:no-any
    uploadImage(
        image: any,
        query: {
            folder:
                | 'PROMOTER_LOGO'
                | 'PROMOTER_HEADER'
                | 'CUSTOM_CHALLENGE_PHOTO'
                | 'NEWS_PHOTO'
                | 'ORGANIZER_LOGO'
                | 'ORGANIZER_HEADER'
                | 'SPONSOR_LOGO'
                | 'SPONSOR_HEADER';
        }
    ): Promise<dtos.CdnImageDto>;
    // tslint:enable:no-any
}

export interface ChallengeController {
    cancel(id: number): Promise<void>;

    convertToDraft(id: number): Promise<void>;

    createCopyForNextPeriod(
        id: number,
        requestBody: dtos.ChallengeCopyRequest
    ): Promise<dtos.ChallengeDetailsDto>;

    createDraft(
        requestBody: dtos.ChallengeNameStep
    ): Promise<dtos.ChallengeDetailsDto>;

    delete(id: number): Promise<void>;

    draw(
        id: number,
        requestBody: dtos.ChallengeDrawRequestDto
    ): Promise<dtos.ChallengeLeaderboardEntryDto[]>;

    fillNameStep(
        id: number,
        requestBody: dtos.ChallengeNameStep
    ): Promise<dtos.ChallengeDetailsDto>;

    fillOrganizersStep(
        id: number,
        requestBody: dtos.ChallengeOrganizerStepRequest
    ): Promise<dtos.ChallengeDetailsDto>;

    fillParticipantsStep(
        id: number,
        requestBody: dtos.ChallengeParticipantsStep
    ): Promise<dtos.ChallengeDetailsDto>;

    fillPresentationStep(
        id: number,
        requestBody: dtos.ChallengePresentationStep
    ): Promise<dtos.ChallengeDetailsDto>;

    fillRulesStep(
        id: number,
        requestBody: dtos.ChallengeRulesStep
    ): Promise<dtos.ChallengeDetailsDto>;

    fillSponsorsStep(
        id: number,
        requestBody: dtos.ChallengeSponsorsStepRequest
    ): Promise<dtos.ChallengeDetailsDto>;

    getChallengeConfiguration(id: number): Promise<dtos.ChallengeDetailsDto>;

    getChallengesPage(query: {
        status?: string[];
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageChallengeListDto>;

    getLeaderboard(
        id: number,
        query: {
            tags?: string[];
            page?: number;
            size?: number;
            search?: string;
            sort?: string;
        }
    ): Promise<dtos.PageChallengeLeaderboardEntryDto>;

    getPromoterLeaderboard(
        id: number,
        query: { page?: number; size?: number; search?: string; sort?: string }
    ): Promise<dtos.PageChallengeLeaderboardEntryDto>;

    getWinnersReport(id: number): Promise<string>;

    grantManualReward(
        id: number,
        userId: string,
        requestBody: dtos.ChallengeManualRewardRequestDto
    ): Promise<dtos.ChallengeLeaderboardEntryDto>;

    schedule(id: number): Promise<void>;

    tagManualWinner(
        id: number,
        userId: string,
        requestBody: dtos.ChallengeManualWinnerRewardRequestDto
    ): Promise<dtos.ChallengeLeaderboardEntryDto>;

    tagParticipant(
        id: number,
        userId: string,
        requestBody: dtos.ChallengeParticipantTagRequestDto
    ): Promise<dtos.ChallengeLeaderboardEntryDto>;
}

export interface ChallengePhotoController {
    getAvailablePhotos(query: {
        size?: number;
        continuationToken?: string;
    }): Promise<dtos.ChallengeImageSearchResponse>;
}

export interface CompanyAccountController {
    create(
        requestBody: dtos.CompanyAccountDto
    ): Promise<dtos.CompanyAccountDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.CompanyAccountDto>;

    getMeInfo(): Promise<dtos.MeResponseDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageCompanyAccountDto>;

    update(
        id: number,
        requestBody: dtos.CompanyAccountDto
    ): Promise<dtos.CompanyAccountDto>;
}

export interface CompanyAccountEmailController {
    /**
     * @deprecated
     */
    confirmEmail(
        email: string,
        requestBody: dtos.EmailVerifyDto
    ): Promise<void>;

    confirmEmailChange(
        email: string,
        requestBody: dtos.EmailVerifyDto
    ): Promise<void>;

    confirmEmailRegistration(
        email: string,
        requestBody: dtos.EmailVerifyDto
    ): Promise<void>;

    updateEmail(
        id: number,
        requestBody: dtos.EmailUpdateDto
    ): Promise<dtos.CompanyAccountDto>;
}

export interface CompanyAccountPasswordController {
    updatePassword(
        email: string,
        requestBody: dtos.PasswordUpdateDto
    ): Promise<dtos.CompanyAccountDto>;
}

export interface CompanyController {
    /**
     * @deprecated
     */
    get(id: number): Promise<dtos.PromoterDto>;

    /**
     * @deprecated
     */
    getConfiguration(id: number): Promise<dtos.PromoterConfigurationDto>;

    /**
     * @deprecated
     */
    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PagePromoterDto>;

    /**
     * @deprecated
     */
    update(
        id: number,
        requestBody: dtos.PromoterUpdateDto
    ): Promise<dtos.PromoterDto>;

    /**
     * @deprecated
     */
    updateConfiguration(
        id: number,
        requestBody: dtos.PromoterConfigurationDto
    ): Promise<dtos.PromoterConfigurationDto>;
}

export interface ConfigurationController {
    getConfiguration(): Promise<dtos.ConfigurationDto>;
}

export interface CountryController {
    getAvailableCountries(): Promise<dtos.CountryOptionDto[]>;
}

export interface CurrencyController {
    getCurrencies(): Promise<dtos.CurrencySelectEntryDto[]>;
}

export interface EmployeeController {
    /**
     * @deprecated
     */
    block(id: number): Promise<dtos.EmployeeDto>;

    confirmEmail(email: string, query: { code: string }): Promise<{}>;

    /**
     * @deprecated
     */
    delete(id: number): Promise<void>;

    /**
     * @deprecated
     */
    get(id: number): Promise<dtos.EmployeeDto>;

    /**
     * @deprecated
     */
    getPage(query: {
        employeeGroupId?: number;
        prioritizeGroupWithId?: number;
        showBlocked?: boolean;
        mySportsId?: string;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageEmployeeDto>;

    /**
     * @deprecated
     */
    unblock(id: number): Promise<dtos.EmployeeDto>;
}

export interface EmployeeGroupController {
    /**
     * @deprecated
     */
    assign(
        groupId: number,
        requestBody: dtos.EmployeeGroupAssignDto
    ): Promise<void>;

    /**
     * @deprecated
     */
    create(
        requestBody: dtos.EmployeeGroupCreatorDto
    ): Promise<dtos.EmployeeGroupCreatorDto>;

    /**
     * @deprecated
     */
    delete(id: number): Promise<void>;

    /**
     * @deprecated
     */
    get(id: number): Promise<dtos.EmployeeGroupCreatorDto>;

    /**
     * @deprecated
     */
    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
        businessUnitId?: number;
    }): Promise<dtos.PageEmployeeGroupPageDto>;

    /**
     * @deprecated
     */
    update(
        id: number,
        requestBody: dtos.EmployeeGroupCreatorDto
    ): Promise<dtos.EmployeeGroupCreatorDto>;
}

export interface NewsAdminController {
    create(requestBody: dtos.NewsDataDto): Promise<dtos.NewsDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.NewsDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageBaseNewsDto>;

    update(id: number, requestBody: dtos.NewsDataDto): Promise<dtos.NewsDto>;

    // tslint:disable:no-any
    uploadImage(image: any): Promise<dtos.CdnImageDto>;
    // tslint:enable:no-any
}

export interface OrganizerController {
    create(requestBody: dtos.OrganizerCreateDto): Promise<dtos.OrganizerDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.OrganizerDetailsDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageOrganizerPageElementDto>;

    update(
        id: number,
        requestBody: dtos.OrganizerDto
    ): Promise<dtos.OrganizerDto>;

    // tslint:disable:no-any
    uploadImage(image: any): Promise<dtos.CdnImageDto>;
    // tslint:enable:no-any
}

export interface ParticipantController {
    block(id: number): Promise<dtos.ParticipantDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.ParticipantDto>;

    getPage(query: {
        employeeGroupId?: number;
        businessUnitId?: number;
        marketId?: number;
        prioritizeGroupWithId?: number;
        showBlocked?: boolean;
        mySportsId?: string;
        excludeMySportsIds?: string;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageParticipantDto>;

    inviteParticipantsV2(
        requestBody: dtos.ParticipantInvitationRequestDto
    ): Promise<dtos.ParticipantInvitationResponseDto>;

    unblock(id: number): Promise<dtos.ParticipantDto>;
}

export interface ParticipantEmailController {
    confirmEmail(email: string, query: { code: string }): Promise<{}>;

    confirmEmailOld(email: string, query: { code: string }): Promise<{}>;
}

export interface ParticipantLicenseController {
    obtain(
        requestBody: dtos.ParticipantLicenseCreateDto
    ): Promise<dtos.ParticipantLicenseResponseDto>;

    release(
        licenseId: number,
        requestBody: dtos.ParticipantLicenseChangeDetailsDto
    ): Promise<void>;

    update(
        licenseId: number,
        requestBody: dtos.ParticipantLicenseUpdateDto
    ): Promise<dtos.ParticipantLicenseResponseDto>;
}

export interface PasswordResetController {
    createPasswordResetCode(requestBody: dtos.PasswordResetDto): Promise<void>;
}

export interface PayoutEntryController {
    delete(entryId: number): Promise<void>;

    updatePayoutAmount(
        entryId: number,
        requestBody: dtos.AmountChangeRequest
    ): Promise<dtos.PayoutEntryDto>;
}

export interface PayoutRunController {
    create(
        requestBody: dtos.PayoutRunCreateDto
    ): Promise<dtos.CreatedResourceDto>;

    createParticipantEntry(
        id: number,
        requestBody: dtos.PayoutRunAddParticipantDto
    ): Promise<dtos.PayoutEntryDto[]>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.PayoutRunDetailsDto>;

    getAggregations(id: number): Promise<dtos.PayoutRunAggregationsDto>;

    getEntries(
        id: number,
        query: {
            newEmployee?: boolean;
            page?: number;
            size?: number;
            search?: string;
            sort?: string;
        }
    ): Promise<dtos.PagePayoutEntryDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PagePayoutRunPageDto>;

    getReport(
        payoutRunId: number,
        query: { type?: 'EXCEL' | 'CSV' }
    ): Promise<void>;

    payout(id: number): Promise<dtos.PayoutRunDetailsDto>;
}

export interface PromoterController {
    get(id: number): Promise<dtos.PromoterDto>;

    getConfiguration(id: number): Promise<dtos.PromoterConfigurationDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PagePromoterDto>;

    update(
        id: number,
        requestBody: dtos.PromoterUpdateDto
    ): Promise<dtos.PromoterDto>;

    updateConfiguration(
        id: number,
        requestBody: dtos.PromoterConfigurationDto
    ): Promise<dtos.PromoterConfigurationDto>;
}

export interface PromoterPresentationController {
    getPresentation(id: number): Promise<dtos.PromoterPresentationDto>;

    updatePresentation(
        id: number,
        requestBody: dtos.PromoterPresentationDto
    ): Promise<dtos.PromoterPresentationDto>;
}

export interface ReportsController {
    getExampleReport(): Promise<string>;
}

export interface RequestChallengeController {
    requestChallenge(requestBody: dtos.RequestChallengeDto): Promise<void>;
}

export interface RewardConfigV1Controller {
    /**
     * @deprecated
     */
    create(
        requestBody: dtos.RewardConfigCreateOrUpdateDto
    ): Promise<dtos.RewardConfigDto>;

    /**
     * @deprecated
     */
    delete(id: number): Promise<void>;

    /**
     * @deprecated
     */
    getRewardConfig(id: number): Promise<dtos.RewardConfigDto>;

    /**
     * @deprecated
     */
    getRewardConfigsPage(query: {
        prioritizeId?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageRewardConfigDto>;

    /**
     * @deprecated
     */
    update(
        id: number,
        requestBody: dtos.RewardConfigCreateOrUpdateDto
    ): Promise<dtos.RewardConfigDto>;
}

export interface RewardConfigV2Controller {
    create(
        requestBody: dtos.RewardConfigCreateOrUpdateV2Dto
    ): Promise<dtos.RewardConfigDto>;

    delete(id: number): Promise<void>;

    getRewardConfig(id: number): Promise<dtos.RewardConfigDto>;

    getRewardConfigsPage(query: {
        prioritizeId?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageRewardConfigDto>;

    update(
        id: number,
        requestBody: dtos.RewardConfigCreateOrUpdateV2Dto
    ): Promise<dtos.RewardConfigDto>;
}

export interface ServicePackageController {
    assign(
        id: number,
        requestBody: dtos.ServicePackageAssignDto
    ): Promise<void>;

    create(
        requestBody: dtos.ServicePackageCreateDto
    ): Promise<dtos.ServicePackageDetailsDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.ServicePackageDetailsDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
        businessUnitId?: number;
    }): Promise<dtos.PageServicePackagePageDto>;

    markBusinessUnitDefault(id: number): Promise<void>;

    update(
        id: number,
        requestBody: dtos.ServicePackageUpdateDto
    ): Promise<dtos.ServicePackageDetailsDto>;
}

export interface SponsorController {
    create(requestBody: dtos.SponsorCreateDto): Promise<dtos.SponsorDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.SponsorDetailsDto>;

    getPage(query: {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageSponsorPageElementDto>;

    update(id: number, requestBody: dtos.SponsorDto): Promise<dtos.SponsorDto>;

    // tslint:disable:no-any
    uploadImage(image: any): Promise<dtos.CdnImageDto>;
    // tslint:enable:no-any
}

export interface SponsorshipProgramController {
    create(
        requestBody: dtos.SponsorshipProgramDto
    ): Promise<dtos.SponsorshipProgramDetailsDto>;

    delete(id: number): Promise<void>;

    get(id: number): Promise<dtos.SponsorshipProgramDetailsDto>;

    getDictionary(): Promise<dtos.DictionaryEntryDto[]>;

    getPage(query: {
        employeeGroupId?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageSponsorshipProgramDetailsDto>;

    update(
        id: number,
        requestBody: dtos.SponsorshipProgramDto
    ): Promise<dtos.SponsorshipProgramDetailsDto>;
}

export interface StepsSponsorshipController {
    getMinStepsOptions(): Promise<dtos.MinStepsOptionDto[]>;
}

export interface SupportAccessController {
    redeemSupportAccessToken(query: {
        supportAccessToken: string;
    }): Promise<void>;
}

export interface TenantController {
    getTenants(query: {
        names?: string[];
        status?: string[];
        type?: string[];
        source?: string[];
        productTypes?: string[];
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
    }): Promise<dtos.PageTenantSearchSimpleDto>;
}

export interface TenantLicenseController {
    getCurrentTenantLicenseUsageInfo(): Promise<dtos.LicenseUsageInfoDto>;
}

export interface TourGuideController {
    complete(tourId: 'FIRST_LOGIN'): Promise<void>;

    getStatus(tourId: 'FIRST_LOGIN'): Promise<dtos.TourGuideStatusDto>;

    skip(tourId: 'FIRST_LOGIN'): Promise<void>;

    view(tourId: 'FIRST_LOGIN'): Promise<void>;
}

export interface MySportsCorporateAPI {
    AchievementConfigController: AchievementConfigController;
    AnalyticsController: AnalyticsController;
    AuthorizationEndpoint: AuthorizationEndpoint;
    BusinessUnitController: BusinessUnitController;
    CdnImageController: CdnImageController;
    ChallengeController: ChallengeController;
    ChallengePhotoController: ChallengePhotoController;
    CompanyAccountController: CompanyAccountController;
    CompanyAccountEmailController: CompanyAccountEmailController;
    CompanyAccountPasswordController: CompanyAccountPasswordController;
    CompanyController: CompanyController;
    ConfigurationController: ConfigurationController;
    CountryController: CountryController;
    CurrencyController: CurrencyController;
    EmployeeController: EmployeeController;
    EmployeeGroupController: EmployeeGroupController;
    NewsAdminController: NewsAdminController;
    OrganizerController: OrganizerController;
    ParticipantController: ParticipantController;
    ParticipantEmailController: ParticipantEmailController;
    ParticipantLicenseController: ParticipantLicenseController;
    PasswordResetController: PasswordResetController;
    PayoutEntryController: PayoutEntryController;
    PayoutRunController: PayoutRunController;
    PromoterController: PromoterController;
    PromoterPresentationController: PromoterPresentationController;
    ReportsController: ReportsController;
    RequestChallengeController: RequestChallengeController;
    RewardConfigV1Controller: RewardConfigV1Controller;
    RewardConfigV2Controller: RewardConfigV2Controller;
    ServicePackageController: ServicePackageController;
    SponsorController: SponsorController;
    SponsorshipProgramController: SponsorshipProgramController;
    StepsSponsorshipController: StepsSponsorshipController;
    SupportAccessController: SupportAccessController;
    TenantController: TenantController;
    TenantLicenseController: TenantLicenseController;
    TourGuideController: TourGuideController;
}

// tslint:disable:no-any

class AchievementConfigControllerBlueprint
    implements AchievementConfigController {
    @Post('/api/v1/achievement-configs')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/achievement-configs/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/achievement-configs/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/achievement-configs-dictionary')
    public getDictionary(): any {
        /**/
    }

    @Get('/api/v1/achievement-configs', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/achievement-configs/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class AnalyticsControllerBlueprint implements AnalyticsController {
    @Get('/api/v1/analytics/filters')
    public getAnalyticsFilters(): any {
        /**/
    }

    @Get('/api/v1/analytics/live-data', true)
    public getLiveData(_p0: any): any {
        /**/
    }

    @Get('/api/v1/analytics/participation/chart', true)
    public getParticipationChart(_p0: any): any {
        /**/
    }

    @Get('/api/v1/analytics/participation/table', true)
    public getParticipationTable(_p0: any): any {
        /**/
    }

    @Get('/api/v1/analytics/rewards/chart', true)
    public getRewardsChart(_p0: any): any {
        /**/
    }

    @Get('/api/v1/analytics/rewards/table', true)
    public getRewardsTable(_p0: any): any {
        /**/
    }
}

class AuthorizationEndpointBlueprint implements AuthorizationEndpoint {
    @Post('/login')
    public login(): any {
        /**/
    }

    @Post('/logout')
    public logout(): any {
        /**/
    }
}

class BusinessUnitControllerBlueprint implements BusinessUnitController {
    @Post('/api/v1/business-units')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/business-units/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/business-units/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/business-units-dictionaries')
    public getDictionary(): any {
        /**/
    }

    @Get('/api/v1/business-units', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/business-units/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class CdnImageControllerBlueprint implements CdnImageController {
    @Post('/api/v1/cdn-images', true)
    public uploadImage(@FormData('image') _p0: any, _p1: any): any {
        /**/
    }
}

class ChallengeControllerBlueprint implements ChallengeController {
    @Post('/api/v1/challenges/{id}/cancel')
    public cancel(_p0: any): any {
        /**/
    }

    @Post('/api/v1/challenges/{id}/converttodraft')
    public convertToDraft(_p0: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Post('/api/v1/challenges/{id}/copy')
    public createCopyForNextPeriod(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Post('/api/v1/challenges')
    public createDraft(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/challenges/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @parseResult(['wonDate'])
    @Post('/api/v1/challenges/{id}/draw')
    public draw(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Put('/api/v1/challenges/{id}/name')
    public fillNameStep(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Put('/api/v1/challenges/{id}/organizers')
    public fillOrganizersStep(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Put('/api/v1/challenges/{id}/participants')
    public fillParticipantsStep(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Put('/api/v1/challenges/{id}/presentation')
    public fillPresentationStep(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Put('/api/v1/challenges/{id}/rules')
    public fillRulesStep(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Put('/api/v1/challenges/{id}/sponsors')
    public fillSponsorsStep(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'publicationDateTime',
        'rulesStep.dateTimeRange.from',
        'rulesStep.dateTimeRange.to',
        'rulesStep.publicationDateTime'
    ])
    @Get('/api/v1/challenges/{id}')
    public getChallengeConfiguration(_p0: any): any {
        /**/
    }

    @parseResult([
        'content.dateTimeRange.from',
        'content.dateTimeRange.to',
        'content.publicationDateTime'
    ])
    @Get('/api/v1/challenges', true)
    public getChallengesPage(_p0: any): any {
        /**/
    }

    @parseResult(['content.wonDate'])
    @Get('/api/v1/challenges/{id}/leaderboard', true)
    public getLeaderboard(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult(['content.wonDate'])
    @Get('/api/v1/challenges/{id}/promoter-leaderboard', true)
    public getPromoterLeaderboard(_p0: any, _p1: any): any {
        /**/
    }

    @Get('/api/v1/challenges/{id}/winners-report')
    public getWinnersReport(_p0: any): any {
        /**/
    }

    @parseResult(['wonDate'])
    @Post('/api/v1/challenges/{id}/participants/{userId}/rewards')
    public grantManualReward(_p0: any, _p1: any, _p2: any): any {
        /**/
    }

    @Post('/api/v1/challenges/{id}/schedule')
    public schedule(_p0: any): any {
        /**/
    }

    @parseResult(['wonDate'])
    @Post('/api/v1/challenges/{id}/participants/{userId}/manual-winner')
    public tagManualWinner(_p0: any, _p1: any, _p2: any): any {
        /**/
    }

    @parseResult(['wonDate'])
    @Put('/api/v1/challenges/{id}/participants/{userId}')
    public tagParticipant(_p0: any, _p1: any, _p2: any): any {
        /**/
    }
}

class ChallengePhotoControllerBlueprint implements ChallengePhotoController {
    @Get('/api/v1/challenges/photos', true)
    public getAvailablePhotos(_p0: any): any {
        /**/
    }
}

class CompanyAccountControllerBlueprint implements CompanyAccountController {
    @Post('/api/v1/companyaccounts')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/companyaccounts/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/companyaccounts/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/companyaccounts/me')
    public getMeInfo(): any {
        /**/
    }

    @Get('/api/v1/companyaccounts', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/companyaccounts/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class CompanyAccountEmailControllerBlueprint
    implements CompanyAccountEmailController {
    @Post('/api/v1/companyaccounts/{email}/verify')
    public confirmEmail(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/companyaccounts/{email}/verify/changemail')
    public confirmEmailChange(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/companyaccounts/{email}/verify/registration')
    public confirmEmailRegistration(_p0: any, _p1: any): any {
        /**/
    }

    @Put('/api/v1/companyaccounts/{id}/email')
    public updateEmail(_p0: any, _p1: any): any {
        /**/
    }
}

class CompanyAccountPasswordControllerBlueprint
    implements CompanyAccountPasswordController {
    @Put('/api/v1/companyaccounts/{email}/password')
    public updatePassword(_p0: any, _p1: any): any {
        /**/
    }
}

class CompanyControllerBlueprint implements CompanyController {
    @Get('/api/v1/companies/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/companies/{id}/configuration')
    public getConfiguration(_p0: any): any {
        /**/
    }

    @Get('/api/v1/companies', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/companies/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }

    @Put('/api/v1/companies/{id}/configuration')
    public updateConfiguration(_p0: any, _p1: any): any {
        /**/
    }
}

class ConfigurationControllerBlueprint implements ConfigurationController {
    @Get('/api/v1/config')
    public getConfiguration(): any {
        /**/
    }
}

class CountryControllerBlueprint implements CountryController {
    @Get('/api/v1/countries')
    public getAvailableCountries(): any {
        /**/
    }
}

class CurrencyControllerBlueprint implements CurrencyController {
    @Get('/api/v1/currencies')
    public getCurrencies(): any {
        /**/
    }
}

class EmployeeControllerBlueprint implements EmployeeController {
    @parseResult(['connectedDate', 'dateOfBirth', 'lastSyncDate'])
    @Post('/api/v1/employees/{id}/block')
    public block(_p0: any): any {
        /**/
    }

    @Get('/api/v1/employees/{email}/verify', true)
    public confirmEmail(_p0: any, _p1: any): any {
        /**/
    }

    @Delete('/api/v1/employees/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @parseResult(['connectedDate', 'dateOfBirth', 'lastSyncDate'])
    @Get('/api/v1/employees/{id}')
    public get(_p0: any): any {
        /**/
    }

    @parseResult([
        'content.connectedDate',
        'content.dateOfBirth',
        'content.lastSyncDate'
    ])
    @Get('/api/v1/employees', true)
    public getPage(_p0: any): any {
        /**/
    }

    @parseResult(['connectedDate', 'dateOfBirth', 'lastSyncDate'])
    @Post('/api/v1/employees/{id}/unblock')
    public unblock(_p0: any): any {
        /**/
    }
}

class EmployeeGroupControllerBlueprint implements EmployeeGroupController {
    @Post('/api/v1/employeegroups/{groupId}/employees')
    public assign(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/employeegroups')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/employeegroups/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/employeegroups/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/employeegroups', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/employeegroups/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class NewsAdminControllerBlueprint implements NewsAdminController {
    @parseResult(['publicationDateTime'])
    @Post('/api/v1/news')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/news/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @parseResult(['publicationDateTime'])
    @Get('/api/v1/news/{id}')
    public get(_p0: any): any {
        /**/
    }

    @parseResult(['content.createdDate', 'content.publicationDateTime'])
    @Get('/api/v1/news', true)
    public getPage(_p0: any): any {
        /**/
    }

    @parseResult(['publicationDateTime'])
    @Put('/api/v1/news/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/news-images')
    public uploadImage(@FormData('image') _p0: any): any {
        /**/
    }
}

class OrganizerControllerBlueprint implements OrganizerController {
    @Post('/api/v1/organizers')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/organizers/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/organizers/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/organizers', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/organizers/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/organizer-images')
    public uploadImage(@FormData('image') _p0: any): any {
        /**/
    }
}

class ParticipantControllerBlueprint implements ParticipantController {
    @parseResult([
        'connectedDate',
        'dateOfBirth',
        'lastSyncDate',
        'participantLicense.validRange.from',
        'participantLicense.validRange.to'
    ])
    @Post('/api/v1/participants/{id}/block')
    public block(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/participants/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @parseResult([
        'connectedDate',
        'dateOfBirth',
        'lastSyncDate',
        'participantLicense.validRange.from',
        'participantLicense.validRange.to'
    ])
    @Get('/api/v1/participants/{id}')
    public get(_p0: any): any {
        /**/
    }

    @parseResult([
        'content.connectedDate',
        'content.dateOfBirth',
        'content.lastSyncDate',
        'content.participantLicense.validRange.from',
        'content.participantLicense.validRange.to'
    ])
    @Get('/api/v1/participants', true)
    public getPage(_p0: any): any {
        /**/
    }

    @parseResult([
        'invitedParticipants.connectedDate',
        'invitedParticipants.dateOfBirth',
        'invitedParticipants.lastSyncDate',
        'invitedParticipants.participantLicense.validRange.from',
        'invitedParticipants.participantLicense.validRange.to'
    ])
    @Post('/api/v2/participants')
    public inviteParticipantsV2(_p0: any): any {
        /**/
    }

    @parseResult([
        'connectedDate',
        'dateOfBirth',
        'lastSyncDate',
        'participantLicense.validRange.from',
        'participantLicense.validRange.to'
    ])
    @Post('/api/v1/participants/{id}/unblock')
    public unblock(_p0: any): any {
        /**/
    }
}

class ParticipantEmailControllerBlueprint
    implements ParticipantEmailController {
    @Get('/api/v1/participants/{email}/verify', true)
    public confirmEmail(_p0: any, _p1: any): any {
        /**/
    }

    @Get('/api/v1/participant-emails/{email}/verify', true)
    public confirmEmailOld(_p0: any, _p1: any): any {
        /**/
    }
}

class ParticipantLicenseControllerBlueprint
    implements ParticipantLicenseController {
    @parseResult(['validRange.from', 'validRange.to'])
    @Post('/api/v2/participant-licenses')
    public obtain(_p0: any): any {
        /**/
    }

    @Delete('/api/v2/participant-licenses/{licenseId}', true, false)
    public release(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult(['validRange.from', 'validRange.to'])
    @Put('/api/v2/participant-licenses/{licenseId}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class PasswordResetControllerBlueprint implements PasswordResetController {
    @Post('/api/v1/passwordresets')
    public createPasswordResetCode(_p0: any): any {
        /**/
    }
}

class PayoutEntryControllerBlueprint implements PayoutEntryController {
    @Delete('/api/v1/payoutentries/{entryId}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @parseResult(['employee.dateOfBirth'])
    @Put('/api/v1/payoutentries/{entryId}/amount')
    public updatePayoutAmount(_p0: any, _p1: any): any {
        /**/
    }
}

class PayoutRunControllerBlueprint implements PayoutRunController {
    @Post('/api/v1/payoutruns')
    public create(_p0: any): any {
        /**/
    }

    @parseResult(['employee.dateOfBirth'])
    @Post('/api/v1/payoutruns/{id}/entries')
    public createParticipantEntry(_p0: any, _p1: any): any {
        /**/
    }

    @Delete('/api/v1/payoutruns/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @parseResult(['yearMonth'])
    @Get('/api/v1/payoutruns/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/payoutruns/{id}/aggregations')
    public getAggregations(_p0: any): any {
        /**/
    }

    @parseResult(['content.employee.dateOfBirth'])
    @Get('/api/v1/payoutruns/{id}/entries', true)
    public getEntries(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult(['content.yearMonth'])
    @Get('/api/v1/payoutruns', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Get('/api/v1/payoutruns/{payoutRunId}/report', true)
    public getReport(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult(['yearMonth'])
    @Post('/api/v1/payoutruns/{id}/payout')
    public payout(_p0: any): any {
        /**/
    }
}

class PromoterControllerBlueprint implements PromoterController {
    @Get('/api/v1/promoters/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/promoters/{id}/configuration')
    public getConfiguration(_p0: any): any {
        /**/
    }

    @Get('/api/v1/promoters', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/promoters/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }

    @Put('/api/v1/promoters/{id}/configuration')
    public updateConfiguration(_p0: any, _p1: any): any {
        /**/
    }
}

class PromoterPresentationControllerBlueprint
    implements PromoterPresentationController {
    @Get('/api/v1/promoters/{id}/presentation')
    public getPresentation(_p0: any): any {
        /**/
    }

    @Put('/api/v1/promoters/{id}/presentation')
    public updatePresentation(_p0: any, _p1: any): any {
        /**/
    }
}

class ReportsControllerBlueprint implements ReportsController {
    @Get('/api/v1/reports/example')
    public getExampleReport(): any {
        /**/
    }
}

class RequestChallengeControllerBlueprint
    implements RequestChallengeController {
    @Post('/api/v1/challenge-requests')
    public requestChallenge(_p0: any): any {
        /**/
    }
}

class RewardConfigV1ControllerBlueprint implements RewardConfigV1Controller {
    @Post('/api/v1/reward-configs')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/reward-configs/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/reward-configs/{id}')
    public getRewardConfig(_p0: any): any {
        /**/
    }

    @Get('/api/v1/reward-configs', true)
    public getRewardConfigsPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/reward-configs/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class RewardConfigV2ControllerBlueprint implements RewardConfigV2Controller {
    @Post('/api/v2/reward-configs')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v2/reward-configs/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v2/reward-configs/{id}')
    public getRewardConfig(_p0: any): any {
        /**/
    }

    @Get('/api/v2/reward-configs', true)
    public getRewardConfigsPage(_p0: any): any {
        /**/
    }

    @Put('/api/v2/reward-configs/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class ServicePackageControllerBlueprint implements ServicePackageController {
    @Post('/api/v1/service-packages/{id}/employees')
    public assign(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/service-packages')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/service-packages/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/service-packages/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/service-packages', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Post('/api/v1/service-packages/{id}/mark-default')
    public markBusinessUnitDefault(_p0: any): any {
        /**/
    }

    @Put('/api/v1/service-packages/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class SponsorControllerBlueprint implements SponsorController {
    @Post('/api/v1/sponsors')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/sponsors/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/sponsors/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/sponsors', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/sponsors/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }

    @Post('/api/v1/sponsor-images')
    public uploadImage(@FormData('image') _p0: any): any {
        /**/
    }
}

class SponsorshipProgramControllerBlueprint
    implements SponsorshipProgramController {
    @Post('/api/v1/sponsorshipprograms')
    public create(_p0: any): any {
        /**/
    }

    @Delete('/api/v1/sponsorshipprograms/{id}', false, false)
    public delete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/sponsorshipprograms/{id}')
    public get(_p0: any): any {
        /**/
    }

    @Get('/api/v1/sponsorshipprograms/dictionary')
    public getDictionary(): any {
        /**/
    }

    @Get('/api/v1/sponsorshipprograms', true)
    public getPage(_p0: any): any {
        /**/
    }

    @Put('/api/v1/sponsorshipprograms/{id}')
    public update(_p0: any, _p1: any): any {
        /**/
    }
}

class StepsSponsorshipControllerBlueprint
    implements StepsSponsorshipController {
    @Get('/api/v1/stepsponsorship/minstepsoptions')
    public getMinStepsOptions(): any {
        /**/
    }
}

class SupportAccessControllerBlueprint implements SupportAccessController {
    @Post('/supportaccess', true)
    public redeemSupportAccessToken(_p0: any): any {
        /**/
    }
}

class TenantControllerBlueprint implements TenantController {
    @Get('/api/v1/tenants', true)
    public getTenants(_p0: any): any {
        /**/
    }
}

class TenantLicenseControllerBlueprint implements TenantLicenseController {
    @Get('/api/v1/tenants/current/license-usage-info')
    public getCurrentTenantLicenseUsageInfo(): any {
        /**/
    }
}

class TourGuideControllerBlueprint implements TourGuideController {
    @Post('/api/v1/tour-guides/{tourId}/complete')
    public complete(_p0: any): any {
        /**/
    }

    @Get('/api/v1/tour-guides/{tourId}/status')
    public getStatus(_p0: any): any {
        /**/
    }

    @Post('/api/v1/tour-guides/{tourId}/skip')
    public skip(_p0: any): any {
        /**/
    }

    @Post('/api/v1/tour-guides/{tourId}/view')
    public view(_p0: any): any {
        /**/
    }
}

// tslint:enable:no-any

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = client => client
): MySportsCorporateAPI {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        AchievementConfigController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(AchievementConfigControllerBlueprint, endpoint),
        AnalyticsController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(AnalyticsControllerBlueprint, endpoint),
        AuthorizationEndpoint: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(AuthorizationEndpointBlueprint, endpoint),
        BusinessUnitController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(BusinessUnitControllerBlueprint, endpoint),
        CdnImageController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CdnImageControllerBlueprint, endpoint),
        ChallengeController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ChallengeControllerBlueprint, endpoint),
        ChallengePhotoController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ChallengePhotoControllerBlueprint, endpoint),
        CompanyAccountController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CompanyAccountControllerBlueprint, endpoint),
        CompanyAccountEmailController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CompanyAccountEmailControllerBlueprint, endpoint),
        CompanyAccountPasswordController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CompanyAccountPasswordControllerBlueprint, endpoint),
        CompanyController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CompanyControllerBlueprint, endpoint),
        ConfigurationController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ConfigurationControllerBlueprint, endpoint),
        CountryController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CountryControllerBlueprint, endpoint),
        CurrencyController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(CurrencyControllerBlueprint, endpoint),
        EmployeeController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(EmployeeControllerBlueprint, endpoint),
        EmployeeGroupController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(EmployeeGroupControllerBlueprint, endpoint),
        NewsAdminController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(NewsAdminControllerBlueprint, endpoint),
        OrganizerController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(OrganizerControllerBlueprint, endpoint),
        ParticipantController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ParticipantControllerBlueprint, endpoint),
        ParticipantEmailController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ParticipantEmailControllerBlueprint, endpoint),
        ParticipantLicenseController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ParticipantLicenseControllerBlueprint, endpoint),
        PasswordResetController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(PasswordResetControllerBlueprint, endpoint),
        PayoutEntryController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(PayoutEntryControllerBlueprint, endpoint),
        PayoutRunController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(PayoutRunControllerBlueprint, endpoint),
        PromoterController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(PromoterControllerBlueprint, endpoint),
        PromoterPresentationController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(PromoterPresentationControllerBlueprint, endpoint),
        ReportsController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ReportsControllerBlueprint, endpoint),
        RequestChallengeController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(RequestChallengeControllerBlueprint, endpoint),
        RewardConfigV1Controller: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(RewardConfigV1ControllerBlueprint, endpoint),
        RewardConfigV2Controller: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(RewardConfigV2ControllerBlueprint, endpoint),
        ServicePackageController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(ServicePackageControllerBlueprint, endpoint),
        SponsorController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(SponsorControllerBlueprint, endpoint),
        SponsorshipProgramController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(SponsorshipProgramControllerBlueprint, endpoint),
        StepsSponsorshipController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(StepsSponsorshipControllerBlueprint, endpoint),
        SupportAccessController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(SupportAccessControllerBlueprint, endpoint),
        TenantController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(TenantControllerBlueprint, endpoint),
        TenantLicenseController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(TenantLicenseControllerBlueprint, endpoint),
        TourGuideController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(TourGuideControllerBlueprint, endpoint)
    };
}
