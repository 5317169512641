import {
    ChallengeStatus,
    ChallengeType,
    MembershipStatus,
    TeamAccessibility,
    TeamVisibility
} from '@api/ChallengeClient';
import { TLanguage } from '../components/language-select';
import {
    SponsorshipProgramDto,
    CompanyAccountDto,
    PayoutRunDetailsDto,
    ChallengeListDto,
    ChallengeRulesStep,
    ChallengeParticipantTagDto
} from './api/dtos';
import { I18n } from './i18n';
import { injectTSDI } from './tsdi';
import { StepStatus } from '@src/challenges/challenge-form/store';

export type Period = Exclude<
    SponsorshipProgramDto['maxContributionPeriod'],
    undefined
>;

export function translatePeriod(period: Period) {
    const { __ } = injectTSDI(I18n);

    switch (period) {
        case 'DAY':
            return __('commons.day');
        case 'WEEK':
            return __('commons.week');
        case 'MONTH':
            return __('commons.month');
    }
}

export function translateAccountState(state: CompanyAccountDto['state']) {
    const { __ } = injectTSDI(I18n);

    switch (state) {
        case 'ACTIVE':
            return __('commons.active');
        case 'INACTIVE':
            return __('commons.inactive');
        case 'BLOCKED':
            return __('commons.blocked');
        case 'DELETED':
            return __('commons.deleted');
        case 'INVITED':
            return __('commons.invited');
        default:
            return undefined;
    }
}

export function translateUserRole(state: CompanyAccountDto['role']) {
    const { __ } = injectTSDI(I18n);

    switch (state) {
        case 'EXTERNAL_SUPPORT':
            return __('cp.user.role.externalsupport');
        case 'ADMIN':
            return __('cp.user.role.admin');
        case 'EMPLOYEE':
            return __('cp.user.role.employee');
        case 'USER':
            return __('cp.user.role.user');
        case 'ANALYST':
            return __('cp.user.role.analyst');
        case 'MANAGER':
            return __('cp.user.role.manager');
        default:
            return undefined;
    }
}

export function translatePayoutState(state: PayoutRunDetailsDto['status']) {
    const { __ } = injectTSDI(I18n);

    switch (state) {
        case 'PAID':
            return __('cp.payout.status.paid');
        case 'GENERATED':
            return __('cp.payout.status.generated');
        case 'DELETED':
            return __('cp.payout.status.deleted');
        case 'IN_GENERATION':
            return __('cp.payout.status.in.generation');
        default:
            return undefined;
    }
}

export function translateChallengeStatus(
    status: ChallengeListDto['challengeStatus']
) {
    const { __ } = injectTSDI(I18n);

    switch (status) {
        case 'CANCELED':
            return __('cp.challenge.status.canceled');
        case 'DRAFT':
            return __('cp.challenge.status.draft');
        case 'FINISHED':
            return __('cp.challenge.status.finished');
        case 'IN_PROGRESS':
            return __('cp.challenge.status.inprogress');
        case 'SCHEDULED':
            return __('cp.challenge.status.scheduled');
        default:
            return undefined;
    }
}

export function translateChallengeType(
    type: ChallengeRulesStep['challengeType']
) {
    const { __ } = injectTSDI(I18n);
    switch (type) {
        case 'TARGET':
            return __('challenge.type.target');
        case 'HIGH_SCORE':
            return __('challenge.type.high.score');
        case 'RACE':
            return __('challenge.type.race');
        default:
            return undefined;
    }
}

export function translateRewardType(type: SponsorshipProgramDto['rewardType']) {
    const { __ } = injectTSDI(I18n);

    switch (type) {
        case 'HIGHEST_DAILY':
            return __('cp.sponsorship.reward.type.highest.rewards');
        default:
            return __('cp.sponsorship.reward.type.total.rewards');
    }
}

export function translateChallengeTag(tag: ChallengeParticipantTagDto['tag']) {
    const { __ } = injectTSDI(I18n);
    switch (tag) {
        case 'ATHLETE':
            return __('cp.athlete');
        case 'MANUAL_WINNER':
            return __('cp.manual.winner');
        case 'INTERNAL':
        default:
            return __('cp.internal');
    }
}

export function getLanguageTranslation(languageKey: TLanguage) {
    const { __ } = injectTSDI(I18n);
    switch (languageKey) {
        case 'de':
            return __('language.de');
        case 'pl':
            return __('language.pl');
        case 'es':
            return __('language.es');
        case 'en':
        default:
            return __('language.en');
    }
}

export function translateTeamAccessibility(state: TeamAccessibility) {
    const { __ } = injectTSDI(I18n);

    switch (state) {
        case TeamAccessibility.OPEN:
            return __('teams.accessibility.option.open');
        case TeamAccessibility.CLOSED:
            return __('teams.accessibility.option.closed');
    }
}

export function translateTeamVisibility(state: TeamVisibility) {
    const { __ } = injectTSDI(I18n);

    switch (state) {
        case TeamVisibility.PRIVATE:
            return __('teams.visibility.option.private');
        case TeamVisibility.PUBLIC:
            return __('teams.visibility.option.public');
    }
}
export function translateMembershipStatus(state: MembershipStatus) {
    const { __ } = injectTSDI(I18n);

    switch (state) {
        case MembershipStatus.INVITED:
            return __('common.invited');
        case MembershipStatus.MEMBER:
            return __('common.member');
    }
}
export function translateChallengeState(status: ChallengeStatus) {
    const { __ } = injectTSDI(I18n);

    switch (status) {
        case ChallengeStatus.CANCELED:
            return __('cp.challenge.status.canceled');
        case ChallengeStatus.DRAFT:
            return __('cp.challenge.status.draft');
        case ChallengeStatus.FINISHED:
            return __('cp.challenge.status.finished');
        case ChallengeStatus.IN_PROGRESS:
            return __('cp.challenge.status.inprogress');
        case ChallengeStatus.SCHEDULED:
            return __('cp.challenge.status.scheduled');
        case ChallengeStatus.PUBLISHED:
            return __('common.published');
        default:
            return '';
    }
}

export function getChallengeStatusOptions() {
    return Object.values(ChallengeStatus).map((value) => ({
        label: translateChallengeState(value),
        value
    }));
}

export function translateChallengeV2Type(type: ChallengeType) {
    const { __ } = injectTSDI(I18n);

    switch (type) {
        case ChallengeType.HIGH_SCORE:
            return __('challenge.type.high.score');
        case ChallengeType.RACE:
            return __('challenge.type.race');
    }
}

export function translateChallengeStepStatus(status: StepStatus) {
    const { __ } = injectTSDI(I18n);

    switch (status) {
        case StepStatus.ERROR:
            return __('challenge.step.status.invalid');

        case StepStatus.INCOMPLETE:
            return __('challenge.step.status.incomplete');
        case StepStatus.MODIFIED:
            return __('challenge.step.status.modified');

        case StepStatus.UPDATING:
            return __('challenge.step.status.updating');
        case StepStatus.COMPLETED:
            return __('challenge.step.status.complete');
    }
}
