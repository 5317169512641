import { computed, Lambda, observable, reaction } from 'mobx';
import { component, inject } from 'tsdi';
import { AppStore } from '../../app/store';
import { theme } from '../../app/theme';
import { I18n } from '../i18n';
import '../location';
import { autodisposer } from '../reactions';
import { TenantStore } from '../tenant';
import { injectTSDI } from '../tsdi';

@component
export class ZendeskStore {
    @observable
    public isLoaded = false;

    @observable
    public isOpen = false;

    @inject
    private i18nStore!: I18n;

    @inject
    private appStore!: AppStore;

    private get tenant() {
        return injectTSDI(TenantStore).tenant;
    }

    private get location() {
        return injectTSDI(Location, 'Location');
    }

    @computed
    public get settings(): ZendeskSettings | undefined {
        const { userAgent, appVersion, platform } = navigator;
        const parsedTags = [];

        parsedTags.push(
            'ms-corporate-b2b',
            `currentUrl_${this.location.href}`,
            `tenant_${this.tenant}`,
            `browser_${escape(userAgent.replace(/\s/g, '_'))}`,
            `browserVersion_${escape(appVersion.replace(/\s/g, '_'))}`,
            `operatingSystem_${escape(platform.replace(/\s/g, '_'))}`,
            'device_desktop'
        );

        return {
            contactForm: {
                tags: parsedTags
            },
            navigation: {
                popoutButton: { enabled: false }
            },
            color: {
                theme: theme.palette.primary.main,
                textColor: '#fff'
            },
            position: {
                horizontal: 'left',
                vertical: 'bottom'
            },
            chat: {
                tags: parsedTags,
                departments: {
                    enabled: ['MySports Corporate B2B'],
                    select: 'MySports Corporate B2B'
                }
            },
            offset: {
                horizontal: this.appStore.drawerOpen ? '260px' : '52px',
                mobile: {
                    horizontal: '0'
                }
            }
        };
    }

    @autodisposer.tsdi
    public reactions(): Lambda[] {
        return [
            reaction(
                () => [
                    this.settings,
                    this.appStore.drawerOpen,
                    this.location.href
                ],
                ([settings]) => {
                    if (typeof window.zE === 'function') {
                        window.zE('webWidget', 'updateSettings', settings);
                    }
                }
            ),
            reaction(
                () => this.i18nStore.currentLocale,
                (currentLocale) => {
                    if (typeof window.zE === 'function') {
                        window.zE('webWidget', 'setLocale', currentLocale);
                    }
                }
            )
        ];
    }

    public toggleWidget = () => {
        if (typeof window.zE !== 'function') {
            return;
        }
        window.zE('webWidget', 'toggle');
    };
}
