import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CardMediaProps
} from '@mui/material';
import * as React from 'react';
import styled, { css } from 'styled-components';

interface ImageProps extends CardMediaProps {
    height?: string;
    width?: string;
}

interface ActionProps {
    className?: string;
    children?: React.ReactNode;
    image?: ImageProps;
    disabled?: boolean;
    onClick?(): void;
}

const StyledCardMedia = styled(CardMedia)<ImageProps>`
    ${({ height, width }) =>
        css`
            ${height && `height: ${height};`}
            ${width && `width: ${width};`}
        `}
`;

export const Action = ({
    className,
    children,
    image,
    disabled,
    onClick
}: ActionProps) => (
    <Card
        variant="outlined"
        className={className}
        sx={{ opacity: disabled ? 0.6 : 1 }}
    >
        <CardActionArea onClick={onClick} disabled={disabled}>
            {image && <StyledCardMedia {...image} />}
            {children && <CardContent>{children}</CardContent>}
        </CardActionArea>
    </Card>
);
