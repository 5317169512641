import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Avatar,
    Box,
    IconButton,
    Toolbar,
    Button,
    useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { ContextMenu } from '../components';
import { Api } from '../core/api';
import { I18n } from '../core/i18n';
import { injectTSDI } from '../core/tsdi';
import { UserStore } from '../core/user';
import { drawerWidth } from './nav';
import { AppStore } from './store';

export const Header = observer(() => {
    const theme = useTheme();
    const { loggedIn, logout } = injectTSDI(Api);
    const { me } = injectTSDI(UserStore);

    const { __ } = injectTSDI(I18n);
    const appStore = injectTSDI(AppStore);
    const email = appStore.mobile ? undefined : me.$ ? me.$.email : undefined;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                sx={{
                    width: appStore.drawerOpen
                        ? `calc(100% - ${drawerWidth}px)`
                        : `calc(100% - 50px)`,
                    marginLeft: appStore.drawerOpen ? drawerWidth : 50,
                    transition: appStore.drawerOpen
                        ? theme.transitions.create(['margin', 'width'], {
                              easing: theme.transitions.easing.easeOut,
                              duration:
                                  theme.transitions.duration.enteringScreen
                          })
                        : theme.transitions.create(['margin', 'width'], {
                              easing: theme.transitions.easing.sharp,
                              duration: theme.transitions.duration.leavingScreen
                          }),
                    backgroundColor: '#fff',
                    height: 50
                }}
                color="default"
                position="fixed"
                elevation={0}
            >
                <Toolbar variant="dense">
                    {loggedIn && (
                        <Box
                            component="div"
                            display="flex"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            sx={{ flexGrow: 1 }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: theme.spacing(0, 1),
                                    ...theme.mixins.toolbar,
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <IconButton
                                    onClick={appStore.drawerToggle}
                                    size="large"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}

                    {loggedIn ? (
                        <ContextMenu
                            items={[
                                {
                                    label: __('commons.logout'),
                                    action: logout,
                                    dataTestId: 'logout-button'
                                }
                            ]}
                        >
                            {email ? (
                                <Button data-testid="user-button">
                                    <Typography color="black" variant="body2">
                                        {email}
                                    </Typography>
                                    <Avatar
                                        sx={{
                                            width: theme.spacing(4),
                                            height: theme.spacing(4),
                                            marginLeft: theme.spacing(1)
                                        }}
                                    >
                                        <Typography variant="body1">
                                            {email.substr(0, 2).toUpperCase()}
                                        </Typography>
                                    </Avatar>
                                </Button>
                            ) : (
                                <ExitToAppIcon />
                            )}
                        </ContextMenu>
                    ) : null}
                </Toolbar>
            </AppBar>
        </Box>
    );
});
