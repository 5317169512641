import { FieldState, FormState } from 'formstate';
import { ObservableMap } from 'mobx';
import { I18n } from '../i18n';
import { injectTSDI } from './../tsdi';

export const isFormStateDirty = (
    formState:
        | {
              [K: string]: FieldState<any> | FormState<any>;
          }
        | ObservableMap
): boolean => {
    let fields;

    if (formState instanceof ObservableMap) {
        fields = Array.from(formState);
    } else {
        fields = Object.entries(formState);
    }
    return fields.some(([_0, field]) => {
        if (field instanceof FieldState) {
            return field.dirty || false;
        } else if (field instanceof FormState) {
            return isFormStateDirty(field.$);
        }
        return false;
    });
};

export const formErrors = (form: FormState<any>) => {
    const { __ } = injectTSDI(I18n);
    // tslint:disable-next-line
    const entries = Object.values(form.$) as FieldState<any>[];

    return entries
        .map((entry) => entry.error)
        .filter(Boolean)
        .map((entry) => __(entry as I18nKey) || entry)
        .join(', ');
};
export const makeFormClean = (form: FormState<any>) => {
    const entries = Object.values(form.$) as FieldState<any>[];
    entries.forEach((field: FieldState<any>) => {
        if (field instanceof FieldState) {
            field.dirty = false;
            // tslint:disable-next-line
        } else if ((field as any) instanceof FormState) {
            makeFormClean(field);
        }
    });
};
