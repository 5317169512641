import { action, makeObservable, observable, reaction } from 'mobx';
import { component, initialize } from 'tsdi';
import { injectTSDI } from './tsdi';

@component
export class TenantStore {
    private get storage() {
        return injectTSDI(Storage, 'LocalStorage');
    }

    public tenant = this.retrieveTenant();

    public updateTenant(tenant: string | undefined) {
        this.tenant = tenant;
    }

    private retrieveTenant() {
        return this.storage.getItem('tenant') || undefined;
    }

    private persistTenant(tenant: string | undefined) {
        if (tenant) {
            this.storage.setItem('tenant', tenant);
        } else {
            this.storage.removeItem('tenant');
        }
    }

    @initialize
    public init(): void {
        makeObservable(this, {
            tenant: observable,
            updateTenant: action
        });
        reaction(
            () => this.tenant,
            (tenant) => {
                this.persistTenant(tenant);
            }
        );
    }
}
