import {
    LocalDate,
    toZonedDateTimeWithCustomFormat,
    ZonedDateTime
} from '@ms-rewards/date-lib';
import { reaction, makeAutoObservable, observable, runInAction } from 'mobx';
import { component, initialize } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import { I18nText, MoneyDto, ZonedDateTimeRangeDto } from './api/dtos';
import { TLanguage } from '../components/language-select';
import { injectTSDI } from './tsdi';
import { UserStore } from './user';

type Manifest = { path: string; hashedPath: string }[];

type TranslateFn = (
    key: I18nKey | null | undefined,
    // tslint:disable-next-line:no-any
    ...params: any[]
) => string;

function extractLang(locale: string): string {
    return locale.split('-')[0];
}

const excludedCurrencyKeys = ['DSTR', 'STR', 'SEUR'];

function getFullLocale(lang: string) {
    const extracted = extractLang(lang);

    switch (extracted) {
        case 'de':
            return 'de-DE';
        case 'en':
            return 'en-GB';
        case 'ca':
            return 'es-ES';
        case 'cs':
            return 'cs-CZ';
        case 'cs':
            return 'cs-CZ';
        case 'sv':
            return 'sv-SE';
        case 'ro':
            return 'ro';
        default:
            return `${extracted.toLowerCase()}-${extracted.toUpperCase()}`;
    }
}

export function getLanguageKey(language: string) {
    switch (language) {
        case 'de_DE':
        case 'de':
            return 'de';
        case 'en_EN':
        case 'en':
            return 'en';
        case 'pl_PL':
        case 'pl':
            return 'pl';
        case 'es':
            return 'es';
        default:
            return 'en';
    }
}

@component
export class I18n {
    public currentLocale = getFullLocale(navigator.language);

    private version = 0;

    private manifest: Manifest | undefined;

    public __: TranslateFn = () => '';

    @initialize
    protected init(): void {
        makeAutoObservable(this, { __: observable }, { autoBind: true });

        reaction(
            () => ({
                locale: this.currentLocale,
                manifest: this.manifest
            }),
            ({ locale, manifest }) => {
                this.setHtmlTagLanguage(locale);
                if (manifest && locale) {
                    this.translationBundle.request(locale);
                }
            },
            {
                name: 'I18n#init',
                fireImmediately: true
            }
        );

        this.loadManifest();
    }

    private setHtmlTagLanguage(lang: string) {
        document.documentElement.setAttribute('lang', lang);
    }

    private async loadManifest(): Promise<void> {
        const data = await fetch('/assets/localizations/manifest.json', {
            headers: new Headers({
                'cache-control': 'no-cache'
            })
        });
        runInAction(async () => {
            this.manifest = (await data.json()) as Manifest;
        });
    }

    public get translationsReady(): boolean {
        return this.version > 0;
    }

    private wrap(
        data: {
            key: I18nKey;
            value: string;
        }[]
    ): TranslateFn {
        const fn: TranslateFn = (
            key: I18nKey | null | undefined,
            // tslint:disable-next-line:no-any
            ...params: any[]
        ) => {
            const entry = data.find((entry) => entry.key === key);

            if (entry) {
                return params.reduce(
                    (memo, value, i) =>
                        memo.replace(`{${String.fromCharCode(97 + i)}}`, value),
                    entry.value
                );
            }

            if (key) {
                return key;
            }

            return '';
        };

        return (key, ...params) => {
            if (key === null || key === undefined || key === '') {
                return '';
            }

            const value = fn(key, ...params);

            if (key === value) {
                console.warn(
                    `%ci18n missing: %c${value}`,
                    'color:#94999d',
                    'color:#000000'
                );
            }

            return value;
        };
    }

    public translationBundle = wrapRequest(async (localeParam: string) => {
        if (typeof localeParam !== 'string') {
            return;
        }
        const locale = localeParam.replace(/"/g, '');

        if (this.manifest) {
            let entry = this.manifest.find(
                (entry) => entry.path === `/${locale}.json`
            );

            if (!entry) {
                entry = this.manifest.find(
                    (entry) => entry.path === `/en-GB.json`
                )!;
            }

            const data = await (
                await fetch(`/assets/localizations${entry.hashedPath}`)
            ).json();

            this.__ = this.wrap(data);
            this.version++;
        }
    });

    public formatPrice(money?: MoneyDto) {
        if (!money) {
            return '–';
        }

        const currency =
            money.currency === 'UNDEFINED' ||
            excludedCurrencyKeys.includes(money.currency)
                ? 'EUR'
                : money.currency;

        const formatter = new Intl.NumberFormat(this.currentLocale, {
            currency,
            style: 'currency',
            ...(money.precision
                ? { maximumFractionDigits: money.precision }
                : {}),
            ...(money.precision
                ? { minimumFractionDigits: money.precision }
                : {})
        });

        return formatter
            .formatToParts(money.value)
            .map((part) => {
                if (part.type === 'currency') {
                    return { ...part, value: money.currencySymbol };
                }

                return part;
            })
            .reduce((acc, part) => acc + part.value, '');
    }

    public formatCurrency(value: number, symbol = 'EUR') {
        const formatter = new Intl.NumberFormat(this.currentLocale);

        return formatter
            .formatToParts(value)
            .map((part) => {
                if (part.type === 'currency') {
                    return { ...part, value: symbol };
                }
                return part;
            })
            .filter((part) => !['decimal', 'fraction'].includes(part.type))
            .reduce((acc, part) => acc + part.value, '');
    }

    public formatNumber(num: number, maximumFractionDigits?: number) {
        const formatter = new Intl.NumberFormat(this.currentLocale, {
            ...(typeof maximumFractionDigits === 'number' && {
                maximumFractionDigits: 0
            })
        });

        return formatter.format(num);
    }

    public formatLongNumber(num: number, maximumFractionDigits = 2) {
        const formatter = Intl.NumberFormat(this.currentLocale, {
            notation: 'compact',
            maximumFractionDigits
        } as Intl.NumberFormatOptions);
        return formatter.format(num);
    }

    public formatShortMonth(date?: LocalDate | ZonedDateTime) {
        if (!date) {
            return '-';
        }

        const formatter = new Intl.DateTimeFormat(this.currentLocale, {
            month: 'short'
        });

        return formatter.format(date.toDate());
    }

    public formatShortMonthFromString(date: string) {
        return this.formatShortMonth(
            toZonedDateTimeWithCustomFormat(date, 'yyyy-MM')
        );
    }

    public formatYearMonthFromString(date: string) {
        return this.formatYearMonth(
            toZonedDateTimeWithCustomFormat(date, 'yyyy-MM')
        );
    }

    public formatYearMonth(date?: LocalDate | ZonedDateTime) {
        if (!date) {
            return '-';
        }

        const formatter = this.yearMonthFormat;

        return formatter.format(date.toDate());
    }

    public formatDate(date?: LocalDate | ZonedDateTime) {
        if (!date) {
            return '-';
        }

        const formatter = this.dateFormat;

        return formatter.format(date.toDate());
    }

    public formatDateAndTime(date?: LocalDate | ZonedDateTime) {
        if (!date) {
            return '-';
        }

        const formatter = this.dateTimeFormat;

        return formatter.format(new Date(date.toDate()));
    }

    public formatDayAndShortMonthYear(date?: LocalDate | ZonedDateTime) {
        if (!date) {
            return '-';
        }

        const formatter = new Intl.DateTimeFormat(this.currentLocale, {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        return formatter.format(new Date(date.toDate()));
    }

    public formatDayAndShortMonth(date?: LocalDate | ZonedDateTime) {
        if (!date) {
            return '-';
        }

        const formatter = new Intl.DateTimeFormat(this.currentLocale, {
            day: 'numeric',
            month: 'short'
        });

        return formatter.format(new Date(date.toDate()));
    }

    public formatDayAndShortMonthFromString(date: string) {
        return this.formatDayAndShortMonth(
            toZonedDateTimeWithCustomFormat(date, 'yyyy-MM')
        );
    }

    private get yearMonthFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            month: 'long',
            year: 'numeric'
        });
    }
    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    public formatDateRange({ from, to }: ZonedDateTimeRangeDto) {
        if (from && to) {
            if (from.getFullYear() !== to.getFullYear()) {
                return `${this.formatDayAndShortMonthYear(
                    from
                )} - ${this.formatDayAndShortMonthYear(to)}`;
            } else if (from.getMonth() !== to.getMonth()) {
                return `${this.formatDayAndShortMonth(
                    from
                )} - ${this.formatDayAndShortMonthYear(to)}`;
            } else if (from.getDate() !== to.getDate()) {
                return `${from.getDate()} - ${this.formatDayAndShortMonthYear(
                    to
                )}`;
            }

            return this.formatDayAndShortMonthYear(from);
        }
        return '';
    }
}

export function i18nTextToStringConverter(text: I18nText): string {
    const { mainLanguageKey } = injectTSDI(UserStore);
    return text[mainLanguageKey] || '';
}

export function textToI18nTextConverter(
    i18nValue?: I18nText,
    legacyValue?: string
): I18nText {
    const { initialLanguageData, mainLanguageKey } = injectTSDI(UserStore);
    return (
        i18nValue || {
            ...initialLanguageData,
            ...(legacyValue
                ? {
                      [mainLanguageKey]: legacyValue
                  }
                : {})
        }
    );
}

export function textToMainI18nTextConverter(text: string) {
    const { mainLanguageKey } = injectTSDI(UserStore);

    return {
        [mainLanguageKey]: text
    };
}

export function initMainLanguageSelection(
    value: TLanguage[] | undefined,
    mainLanguage: TLanguage
): TLanguage[] {
    if (!value) {
        return [mainLanguage];
    }
    return value.includes(mainLanguage) ? value : [mainLanguage, ...value];
}
