import { StickyBar } from '@components/sticky-bar';
import { Box, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { rotate } from '@components/animation';
import { Button } from '@components/button';
import { navigate } from 'takeme';
import { links } from '@core/router';
import { injectTSDI } from '@core/tsdi';
import { I18n } from '@core/i18n';

type Props = {
    isUpdating?: boolean;
    onChallengeSchedule(): Promise<void>;
    scheduleDisabled?: boolean;
    showScheduleButton?: boolean;
    onChallengeCancel(): Promise<void>;
    cancelDisabled?: boolean;
    showCancelButton?: boolean;
    onChallengeToDraft(): Promise<void>;
    toDraftDisabled?: boolean;
    showToDraftButton?: boolean;
    maxWidth: number;
};

export const SpinIcon = styled(AutorenewIcon)`
    animation: ${rotate} 2s infinite linear;
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const ChallengeActions = ({
    maxWidth,
    isUpdating,
    scheduleDisabled,
    showScheduleButton,
    showCancelButton,
    cancelDisabled,
    toDraftDisabled,
    showToDraftButton,
    onChallengeToDraft,
    onChallengeCancel,
    onChallengeSchedule
}: Props) => {
    const { __ } = injectTSDI(I18n);

    return (
        <StickyBar>
            <Box
                display="flex"
                component="div"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                maxWidth={maxWidth}
            >
                <Box display="flex" alignItems="center">
                    <Box mr={1}>
                        <Button
                            variant="text"
                            fullWidth={false}
                            onClick={() => void navigate(links.challengesV2())}
                        >
                            {__('commons.back')}
                        </Button>
                    </Box>
                    {isUpdating && <SpinIcon color="disabled" />}
                </Box>

                <Stack direction="row" alignItems="center" spacing={2}>
                    {scheduleDisabled && (
                        <Typography
                            variant="body2"
                            sx={{
                                fontStyle: 'italic'
                            }}
                        >
                            {__('challenge.changes.message')}
                        </Typography>
                    )}
                    {showScheduleButton && (
                        <Button
                            fullWidth={false}
                            data-testid="schedule-challenge-button"
                            disabled={scheduleDisabled}
                            onClick={onChallengeSchedule}
                        >
                            {__('commons.schedule')}
                        </Button>
                    )}

                    {showCancelButton && (
                        <Button
                            fullWidth={false}
                            color="error"
                            data-testid="cancel-challenge-button"
                            disabled={cancelDisabled}
                            onClick={onChallengeCancel}
                        >
                            {__('cp.challenge.abort')}
                        </Button>
                    )}
                    {showToDraftButton && (
                        <Button
                            fullWidth={false}
                            data-testid="cancel-challenge-button"
                            disabled={toDraftDisabled}
                            onClick={onChallengeToDraft}
                        >
                            {__('cp.challenge.convert.to.draft')}
                        </Button>
                    )}
                </Stack>
            </Box>
        </StickyBar>
    );
};
