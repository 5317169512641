import React from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import {
    clearOutParentId,
    getChildById,
    goThroughAllNodes
} from './tree-utils';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel
} from '@mui/material';

export interface TreeNode {
    id: number;
    name: string;
    children?: TreeNode[];
}

interface TreeSelectProps {
    label: string;
    options: TreeNode[];
    value: number[];
    dataTestId?: string;
    disabled?: boolean;
    required?: boolean;
    error?: string;
    onChange: (value: number[]) => void;
}
export const TreeSelect = ({
    options,
    value,
    dataTestId,
    disabled,
    error,
    required,
    label,
    onChange
}: TreeSelectProps) => {
    const selectedSet = React.useMemo(() => new Set(value), [value]);

    const parentMap = React.useMemo(() => {
        return goThroughAllNodes(options);
    }, []);

    const onTreeNodeToggle = React.useCallback(
        (checked: boolean, nodes: TreeNode) => {
            const { childNodesToToggle, path } = getChildById(
                options,
                nodes.id
            );

            let array = checked
                ? [...value, ...childNodesToToggle]
                : value
                      .filter((value) => !childNodesToToggle.includes(value))
                      .filter((value) => !path.includes(value));

            array = array.filter((v, i) => array.indexOf(v) === i);

            onChange(clearOutParentId(nodes, array));
        },
        [onChange]
    );

    const TreeNode = React.useCallback(
        (nodes: TreeNode) => {
            const allSelectedChildren = parentMap[nodes.id]?.every(
                (childNodeId: number) => selectedSet.has(childNodeId)
            );
            const checked =
                selectedSet.has(nodes.id) || allSelectedChildren || false;

            const indeterminate =
                parentMap[nodes.id]?.some((childNodeId: number) =>
                    selectedSet.has(childNodeId)
                ) || false;

            return (
                <TreeItem
                    key={nodes.id}
                    itemId={nodes.id.toString()}
                    label={
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    disabled={disabled}
                                    indeterminate={!checked && indeterminate}
                                    onChange={(event) =>
                                        onTreeNodeToggle(
                                            event.currentTarget.checked,
                                            nodes
                                        )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                />
                            }
                            label={<>{nodes.name}</>}
                            key={nodes.id}
                        />
                    }
                >
                    {Array.isArray(nodes.children)
                        ? nodes.children.map((node) => TreeNode(node))
                        : null}
                </TreeItem>
            );
        },
        [parentMap, selectedSet, disabled, onTreeNodeToggle]
    );

    return (
        <FormControl variant="standard" fullWidth data-testid={dataTestId}>
            <InputLabel
                shrink
                error={Boolean(error)}
                required={required}
                disabled={disabled}
            >
                {label}
            </InputLabel>
            <SimpleTreeView
                multiSelect
                disableSelection={disabled}
                sx={{ mt: 2 }}
            >
                {options.map((category: TreeNode) => TreeNode(category))}
            </SimpleTreeView>
        </FormControl>
    );
};

export default TreeSelect;
