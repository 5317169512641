import {
    AccountBalance,
    Business,
    Face,
    Settings,
    SupervisedUserCircle,
    BarChart,
    AppsRounded,
    Feed,
    Paid,
    LocalActivity,
    Groups,
    SvgIconComponent
} from '@mui/icons-material';
import * as React from 'react';
import {
    RouteBeforeEnterResult,
    RouteEnterEvent as TakeMeRouteEnterEvent
} from 'takeme';
import { component, initialize } from 'tsdi';
import { BadgeIcon } from './../components/svg-icons/badge';
import { Api, RoutePermission } from './api';
import { I18n } from './i18n';
import { Links, links, Params } from './router';
import { injectTSDI } from './tsdi';
import { UserStore } from './user';
import { autodisposer } from './reactions';
import { reaction } from 'mobx';

interface RouteEnterEvent extends TakeMeRouteEnterEvent {
    permissions?: boolean;
}

export interface Route {
    link: keyof Links;
    children?: Route[];
    parent?: Route;
    permissions?: RoutePermission;
    // tslint:disable-next-line:no-any
    Component?: React.LazyExoticComponent<any>;
    showInNav?: boolean;
    divider?: boolean;
    externalSupportOnly?: boolean;
    Icon?: SvgIconComponent;
    linkParams?(...params: unknown[]): Params;
    title(params?: Params): Promise<string>;
    beforeEnter?(options: RouteEnterEvent): RouteBeforeEnterResult;
    enter?(options: RouteEnterEvent): void;
}

@component
export class Routes {
    public routes: Route[] = [];

    private get i18n() {
        return injectTSDI(I18n);
    }

    private get api() {
        return injectTSDI(Api);
    }

    private get user() {
        return injectTSDI(UserStore);
    }

    @initialize
    public async init() {
        this.generateRoutes();
    }

    private generateRoutes() {
        this.routes = [this.getPrivateRoutes(), this.getPublicRoutes()];
        this.assignRouteParents();
    }

    private getPrivateRoutes(): Route {
        return this.user.isTeamsPortal
            ? this.getTeamsRoutes()
            : this.getCorporateRoutes();
    }

    private getTeamsRoutes(): Route {
        return {
            link: 'indexPrivate',
            title: async () => 'Home',
            Component: React.lazy(() => import('../home')),
            children: [
                {
                    link: 'promoter',
                    title: async () =>
                        this.user.promoterType === 'SPORT_ASSOCIATION'
                            ? this.i18n.__(
                                  'enums.promotertype.sportassociation'
                              )
                            : this.i18n.__('enums.promotertype.company'),
                    beforeEnter: () => {
                        return {
                            redirect: links.dashboard()
                        };
                    },
                    showInNav: true,
                    children: [
                        {
                            link: 'dashboard',
                            title: async () => this.i18n.__('cp.overview'),
                            Component: React.lazy(() => import('../dashboard')),
                            Icon: BarChart,
                            showInNav: true
                        },
                        {
                            link: 'promoterUserAccounts',
                            Component: React.lazy(
                                () => import('../promoter/user-accounts')
                            ),
                            Icon: SupervisedUserCircle,
                            title: async () => this.i18n.__('cp.user.accounts'),
                            showInNav: true
                        },
                        {
                            link: 'promoterData',
                            Icon: Business,
                            title: async () => this.i18n.__('cp.company.data'),
                            Component: React.lazy(
                                () => import('../promoter/promoter-data')
                            ),
                            showInNav: true
                        }
                    ]
                },
                {
                    link: 'templates',
                    title: async () => this.i18n.__('common.templates'),
                    beforeEnter: () => {
                        return {
                            redirect: links.templatesNews()
                        };
                    },
                    permissions: {
                        all: ['NEWS_TEMPLATE', 'EXTERNAL_SUPPORT']
                    },
                    showInNav: true,
                    children: [
                        {
                            link: 'templatesNews',
                            Component: React.lazy(
                                () => import('../templates/news')
                            ),
                            Icon: Feed,
                            showInNav: true,
                            permissions: {
                                all: ['NEWS_TEMPLATE', 'EXTERNAL_SUPPORT']
                            },
                            title: async () => this.i18n.__('news.templates'),
                            children: [
                                {
                                    link: 'templatesNewsCreate',
                                    permissions: {
                                        all: [
                                            'NEWS_TEMPLATE',
                                            'EXTERNAL_SUPPORT'
                                        ]
                                    },
                                    Component: React.lazy(
                                        () => import('../templates/news/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.news.create')
                                },
                                {
                                    link: 'templatesNewsReleaseLog',
                                    permissions: {
                                        all: [
                                            'NEWS_TEMPLATE',
                                            'EXTERNAL_SUPPORT'
                                        ]
                                    },
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../templates/news/release-log'
                                            )
                                    ),
                                    title: async () => this.i18n.__('')
                                },
                                {
                                    link: 'templatesNewsEdit',
                                    permissions: {
                                        all: [
                                            'NEWS_TEMPLATE',
                                            'EXTERNAL_SUPPORT'
                                        ]
                                    },
                                    Component: React.lazy(
                                        () => import('../templates/news/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.news.edit')
                                }
                            ]
                        }
                    ]
                },
                {
                    link: 'participants',
                    title: async () => this.i18n.__('cp.participants'),
                    beforeEnter: () => {
                        return {
                            redirect: links.participantsList()
                        };
                    },
                    showInNav: true,
                    children: [
                        {
                            link: 'participantsList',
                            Component: React.lazy(
                                () => import('../employees/list')
                            ),
                            Icon: Face,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.participants')
                        },
                        {
                            link: 'teams',
                            Component: React.lazy(() => import('../teams')),
                            Icon: Groups,
                            showInNav: true,
                            permissions: 'LEAGUES_AND_TEAMS',
                            title: async () => this.i18n.__('common.teams'),
                            children: [
                                {
                                    link: 'teamsLeague',
                                    Component: React.lazy(
                                        () => import('../teams')
                                    ),
                                    permissions: 'LEAGUES_AND_TEAMS',
                                    showInNav: false,
                                    title: async () =>
                                        this.i18n.__('common.teams')
                                }
                            ]
                        },
                        {
                            link: 'teamMembers',
                            Component: React.lazy(
                                () => import('../teams/team-members')
                            ),
                            showInNav: false,
                            permissions: 'LEAGUES_AND_TEAMS',
                            title: async () => this.i18n.__('team.members')
                        },
                        {
                            link: 'teamCreate',
                            Component: React.lazy(
                                () => import('../teams/teams-form')
                            ),
                            showInNav: false,
                            permissions: 'LEAGUES_AND_TEAMS',
                            title: async () => this.i18n.__('common.teams'),
                            children: [
                                {
                                    link: 'teamEdit',
                                    Component: React.lazy(
                                        () => import('../teams/teams-form')
                                    ),
                                    permissions: 'LEAGUES_AND_TEAMS',
                                    showInNav: false,
                                    title: async () =>
                                        this.i18n.__('common.teams')
                                }
                            ]
                        },
                        {
                            link: 'participantsConfig',
                            Component: React.lazy(
                                () => import('../employees/config')
                            ),
                            Icon: Settings,
                            showInNav: true,
                            title: async () =>
                                this.i18n.__('cp.employees.config')
                        },
                        {
                            link: 'news',
                            Component: React.lazy(
                                () => import('../employees/news')
                            ),
                            Icon: Feed,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.news'),
                            children: [
                                {
                                    link: 'newsCreate',
                                    Component: React.lazy(
                                        () => import('../employees/news/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.news.create')
                                },
                                {
                                    link: 'newsEdit',
                                    Component: React.lazy(
                                        () => import('../employees/news/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.news.edit')
                                }
                            ]
                        },

                        {
                            link: 'challenges',
                            Component: React.lazy(
                                () => import('../employees/challenges')
                            ),
                            Icon: BadgeIcon as SvgIconComponent,
                            showInNav: true,
                            permissions: {
                                atLeastOne: ['CHALLENGES', 'EXTERNAL_SUPPORT']
                            },
                            title: async () => this.i18n.__('cp.challenges'),

                            children: [
                                {
                                    link: 'challengeEdit',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../employees/challenges/edit/index'
                                            )
                                    ),
                                    title: async () => 'Challenge Edit'
                                },
                                {
                                    link: 'challengeDetails',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../employees/challenges/detail/index'
                                            )
                                    ),
                                    title: async () => 'Challenge Details'
                                }
                            ]
                        },
                        {
                            link: 'sponsors',
                            Component: React.lazy(() => import('../sponsors')),
                            Icon: Paid,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.sponsors'),
                            permissions: 'SPONSOR',
                            children: [
                                {
                                    link: 'sponsorCreate',
                                    Component: React.lazy(
                                        () => import('../sponsors/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.sponsor.create')
                                },
                                {
                                    link: 'sponsorEdit',
                                    Component: React.lazy(
                                        () => import('../sponsors/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.sponsor.edit')
                                }
                            ]
                        },
                        {
                            link: 'organizers',
                            Component: React.lazy(
                                () => import('../organizers')
                            ),
                            Icon: LocalActivity,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.organizers'),
                            permissions: 'ORGANIZER',
                            children: [
                                {
                                    link: 'organizerCreate',
                                    Component: React.lazy(
                                        () => import('../organizers/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.organizer.create')
                                },
                                {
                                    link: 'organizerEdit',
                                    Component: React.lazy(
                                        () => import('../organizers/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.organizer.edit')
                                }
                            ]
                        },
                        {
                            link: 'servicePackages',
                            Component: React.lazy(
                                () => import('../employees/service-packages')
                            ),
                            Icon: AppsRounded,
                            showInNav: true,
                            externalSupportOnly: true,
                            permissions: 'EXTERNAL_SUPPORT',
                            title: async () =>
                                this.i18n.__('cp.service.packages'),
                            children: [
                                {
                                    link: 'servicePackagesUnit',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../employees/service-packages'
                                            )
                                    ),
                                    showInNav: false,
                                    externalSupportOnly: true,
                                    title: async () =>
                                        this.i18n.__('cp.service.packages')
                                }
                            ]
                        },
                        {
                            link: 'servicePackageEdit',
                            Component: React.lazy(
                                () =>
                                    import(
                                        '../employees/service-packages/service-package-form'
                                    )
                            ),
                            showInNav: false,
                            externalSupportOnly: true,
                            title: async () =>
                                this.i18n.__('cp.service.package.edit')
                        },
                        {
                            link: 'servicePackageCreate',
                            Component: React.lazy(
                                () =>
                                    import(
                                        '../employees/service-packages/service-package-form'
                                    )
                            ),
                            externalSupportOnly: true,
                            showInNav: false,
                            title: async () =>
                                this.i18n.__('cp.service.package.create')
                        },
                        {
                            link: 'servicePackageCreateWithBusinessUnit',
                            Component: React.lazy(
                                () =>
                                    import(
                                        '../employees/service-packages/service-package-form'
                                    )
                            ),
                            externalSupportOnly: true,
                            showInNav: false,
                            title: async () =>
                                this.i18n.__('cp.service.package.create')
                        },
                        {
                            link: 'challengesV2',
                            Component: React.lazy(
                                () => import('../challenges')
                            ),
                            Icon: BadgeIcon as SvgIconComponent,
                            showInNav: true,
                            permissions: 'EXTERNAL_SUPPORT',
                            externalSupportOnly: true,
                            beforeEnter(options) {
                                if (!options.permissions) {
                                    return {
                                        redirect: links.dashboard()
                                    };
                                }
                                return;
                            },
                            title: async () =>
                                `${this.i18n.__('cp.challenges')} - V2`,
                            children: [
                                {
                                    link: 'challengePreviewV2',
                                    Component: React.lazy(
                                        () => import('../challenges/preview')
                                    ),
                                    permissions: 'EXTERNAL_SUPPORT',

                                    title: async () =>
                                        this.i18n.__('challenge.field.details')
                                },
                                {
                                    link: 'challengeEditV2',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../challenges/challenge-form'
                                            )
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.challenge.edit'),
                                    permissions: 'EXTERNAL_SUPPORT'
                                },
                                {
                                    link: 'challengeCreationV2',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../challenges/challenge-form'
                                            )
                                    ),
                                    title: async () =>
                                        this.i18n.__(
                                            'cp.challenge.create.button'
                                        ),
                                    permissions: 'EXTERNAL_SUPPORT'
                                }
                            ]
                        }
                    ]
                },
                {
                    link: 'sponsorship',
                    title: async () => this.i18n.__('cp.stats'),
                    beforeEnter: () => {
                        return {
                            redirect: links.statsOverview()
                        };
                    },
                    showInNav: true,
                    externalSupportOnly: true,
                    permissions: 'EXTERNAL_SUPPORT',
                    children: [
                        {
                            title: async () => this.i18n.__('cp.stats'),
                            link: 'stats',
                            Icon: BarChart,
                            showInNav: true,
                            externalSupportOnly: true,
                            beforeEnter: () => {
                                if (this.user.liveDataStatsEnabled) {
                                    return {
                                        redirect: links.statsOverview()
                                    };
                                }
                                return {
                                    redirect: links.statsEmployeeParticipation()
                                };
                            },
                            children: [
                                {
                                    title: async () =>
                                        this.i18n.__('cp.overview'),
                                    link: 'statsOverview',
                                    children: [],
                                    permissions: 'LIVE_DATA_STATS',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/stats/overview'
                                            )
                                    )
                                },
                                {
                                    title: async () =>
                                        this.i18n.__('cp.stats.participation'),
                                    link: 'statsEmployeeParticipation',
                                    children: [],
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/stats/employee-participation'
                                            )
                                    )
                                },
                                {
                                    title: async () =>
                                        this.i18n.__('cp.stats.rewards'),
                                    link: 'statsEmployeeRewards',
                                    children: [],
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/stats/employee-rewards'
                                            )
                                    )
                                }
                            ]
                        },
                        {
                            title: async () =>
                                this.i18n.__('cp.sponsorship.payout.reports'),
                            link: 'sponsorshipPayoutReports',
                            Icon: AccountBalance,
                            permissions: 'EXTERNAL_SUPPORT',
                            showInNav: true,
                            externalSupportOnly: true,
                            Component: React.lazy(
                                () => import('../sponsorship/payout-reports')
                            ),
                            children: [
                                {
                                    title: async () =>
                                        this.i18n.__(
                                            'cp.sponsorship.payout.reports'
                                        ),
                                    link: 'sponsorshipPayoutReportDetail',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/payout-reports/payout-run/list'
                                            )
                                    ),
                                    children: [
                                        {
                                            title: async () =>
                                                this.i18n.__(
                                                    'cp.sponsorship.payout.reports'
                                                ),
                                            link: 'sponsorshipPayoutReportDetailAction',
                                            Component: React.lazy(
                                                () =>
                                                    import(
                                                        '../sponsorship/payout-reports/payout-run/action'
                                                    )
                                            )
                                        }
                                    ]
                                }
                            ]
                        },

                        {
                            title: async () =>
                                this.i18n.__('cp.stats.participation'),
                            link: 'liveStatsTemplate',
                            permissions: 'CHALLENGES',
                            Component: React.lazy(
                                () =>
                                    import(
                                        '../sponsorship/stats/live-stats-template'
                                    )
                            )
                        }
                    ]
                }
            ]
        };
    }
    private getCorporateRoutes(): Route {
        return {
            link: 'indexPrivate',
            title: async () => 'Home',
            Component: React.lazy(() => import('../home')),
            children: [
                {
                    link: 'promoter',
                    title: async () => this.i18n.__('cp.company'),
                    beforeEnter: () => {
                        return {
                            redirect: links.promoterUserAccounts()
                        };
                    },
                    showInNav: true,
                    children: [
                        {
                            link: 'promoterUserAccounts',
                            Component: React.lazy(
                                () => import('../promoter/user-accounts')
                            ),
                            Icon: SupervisedUserCircle,
                            title: async () => this.i18n.__('cp.user.accounts'),
                            showInNav: true
                        },
                        {
                            link: 'promoterData',
                            Icon: Business,
                            title: async () => this.i18n.__('cp.company.data'),
                            Component: React.lazy(
                                () => import('../promoter/promoter-data')
                            ),
                            showInNav: true
                        }
                    ]
                },
                {
                    link: 'employees',
                    title: async () => this.i18n.__('cp.employees'),
                    beforeEnter: () => {
                        return {
                            redirect: links.employeesList()
                        };
                    },
                    showInNav: true,
                    children: [
                        {
                            link: 'employeesList',
                            Component: React.lazy(
                                () => import('../employees/list')
                            ),
                            Icon: Face,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.employees')
                        },
                        {
                            link: 'employeeConfig',
                            Component: React.lazy(
                                () => import('../employees/config')
                            ),
                            Icon: Settings,
                            showInNav: true,
                            title: async () =>
                                this.i18n.__('cp.employees.config')
                        },
                        {
                            link: 'challenges',
                            Component: React.lazy(
                                () => import('../employees/challenges')
                            ),
                            Icon: BadgeIcon as SvgIconComponent,
                            showInNav: true,
                            permissions: {
                                atLeastOne: ['CHALLENGES', 'EXTERNAL_SUPPORT']
                            },
                            title: async () => this.i18n.__('cp.challenges'),
                            children: [
                                {
                                    link: 'challengeEdit',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../employees/challenges/edit/index'
                                            )
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.challenge.edit')
                                },
                                {
                                    link: 'challengeDetails',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../employees/challenges/detail/index'
                                            )
                                    ),
                                    title: async () => 'Challenge Details'
                                }
                            ]
                        },
                        {
                            link: 'sponsors',
                            Component: React.lazy(() => import('../sponsors')),
                            Icon: Paid,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.sponsors'),
                            permissions: 'SPONSOR',
                            children: [
                                {
                                    link: 'sponsorCreate',
                                    Component: React.lazy(
                                        () => import('../sponsors/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.sponsor.create')
                                },
                                {
                                    link: 'sponsorEdit',
                                    Component: React.lazy(
                                        () => import('../sponsors/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.sponsor.edit')
                                }
                            ]
                        },
                        {
                            link: 'organizers',
                            Component: React.lazy(
                                () => import('../organizers')
                            ),
                            Icon: LocalActivity,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.organizers'),
                            permissions: 'ORGANIZER',
                            children: [
                                {
                                    link: 'organizerCreate',
                                    Component: React.lazy(
                                        () => import('../organizers/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.organizer.create')
                                },
                                {
                                    link: 'organizerEdit',
                                    Component: React.lazy(
                                        () => import('../organizers/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.organizer.edit')
                                }
                            ]
                        },
                        {
                            link: 'news',
                            Component: React.lazy(
                                () => import('../employees/news')
                            ),
                            Icon: Feed,
                            showInNav: true,
                            title: async () => this.i18n.__('cp.news'),
                            children: [
                                {
                                    link: 'newsCreate',
                                    Component: React.lazy(
                                        () => import('../employees/news/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.news.create')
                                },
                                {
                                    link: 'newsEdit',
                                    Component: React.lazy(
                                        () => import('../employees/news/edit')
                                    ),
                                    title: async () =>
                                        this.i18n.__('cp.news.edit')
                                }
                            ]
                        }
                    ]
                },
                {
                    link: 'sponsorship',
                    title: async () => this.i18n.__('cp.sponsorship'),
                    beforeEnter: () => {
                        return {
                            redirect: links.sponsorshipConfig()
                        };
                    },
                    showInNav: true,
                    children: [
                        {
                            title: async () =>
                                this.i18n.__('cp.sponsorship.config'),
                            link: 'sponsorshipConfig',
                            Icon: Settings,

                            showInNav: true,
                            Component: React.lazy(
                                () => import('../sponsorship/config')
                            )
                        },
                        {
                            title: async () =>
                                this.i18n.__('cp.sponsorship.config'),
                            link: 'sponsorshipConfigDetail',
                            showInNav: false,
                            Component: React.lazy(
                                () => import('../sponsorship/config')
                            )
                        },
                        {
                            title: async () =>
                                this.i18n.__('cp.sponsorship.payout.reports'),
                            link: 'sponsorshipPayoutReports',
                            Icon: AccountBalance,
                            showInNav: true,
                            Component: React.lazy(
                                () => import('../sponsorship/payout-reports')
                            ),
                            children: [
                                {
                                    title: async () =>
                                        this.i18n.__(
                                            'cp.sponsorship.payout.reports'
                                        ),
                                    link: 'sponsorshipPayoutReportDetail',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/payout-reports/payout-run/list'
                                            )
                                    ),
                                    children: [
                                        {
                                            title: async () =>
                                                this.i18n.__(
                                                    'cp.sponsorship.payout.reports'
                                                ),
                                            link: 'sponsorshipPayoutReportDetailAction',
                                            Component: React.lazy(
                                                () =>
                                                    import(
                                                        '../sponsorship/payout-reports/payout-run/action'
                                                    )
                                            )
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            title: async () => this.i18n.__('cp.stats'),
                            link: 'stats',
                            Icon: BarChart,
                            showInNav: true,
                            beforeEnter: () => {
                                if (this.user.liveDataStatsEnabled) {
                                    return {
                                        redirect: links.statsOverview()
                                    };
                                }
                                return {
                                    redirect: links.statsEmployeeParticipation()
                                };
                            },
                            children: [
                                {
                                    title: async () =>
                                        this.i18n.__('cp.overview'),
                                    link: 'statsOverview',
                                    children: [],
                                    permissions: 'LIVE_DATA_STATS',
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/stats/overview'
                                            )
                                    )
                                },
                                {
                                    title: async () =>
                                        this.i18n.__('cp.stats.participation'),
                                    link: 'statsEmployeeParticipation',
                                    children: [],
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/stats/employee-participation'
                                            )
                                    )
                                },
                                {
                                    title: async () =>
                                        this.i18n.__('cp.stats.rewards'),
                                    link: 'statsEmployeeRewards',
                                    children: [],
                                    Component: React.lazy(
                                        () =>
                                            import(
                                                '../sponsorship/stats/employee-rewards'
                                            )
                                    )
                                }
                            ]
                        },
                        {
                            title: async () =>
                                this.i18n.__('cp.stats.participation'),
                            link: 'liveStatsTemplate',
                            permissions: 'CHALLENGES',
                            Component: React.lazy(
                                () =>
                                    import(
                                        '../sponsorship/stats/live-stats-template'
                                    )
                            )
                        }
                    ]
                }
            ]
        };
    }
    private getPublicRoutes(): Route {
        return {
            link: 'indexPublic',
            title: async () => 'Home',
            children: [
                {
                    link: 'login',
                    Component: React.lazy(() => import('../login')),
                    title: async () => this.i18n.__('login'),
                    beforeEnter: () => {
                        if (this.api.loggedIn) {
                            return { redirect: links.indexPrivate() };
                        }
                        return;
                    }
                },
                {
                    link: 'passwordForgotPublic',
                    Component: React.lazy(() => import('../password-forgot')),
                    title: async () => this.i18n.__('cp.password.forgot')
                },
                {
                    link: 'passwordSetPublic',
                    Component: React.lazy(() => import('../password-set')),
                    title: async () => this.i18n.__('cp.password.set')
                },
                {
                    link: 'welcomePagePublic',
                    Component: React.lazy(() => import('../welcome')),
                    title: async () => this.i18n.__('cp.welcome.page')
                },
                {
                    link: 'companyAccountPasswordSetPublic',
                    Component: React.lazy(
                        () => import('../promoter/user-accounts/password-reset')
                    ),
                    title: async () => this.i18n.__('cp.password.set')
                }
            ]
        };
    }

    private assignRouteParents(
        routes = this.routes,
        parent: Route | null = null
    ): void {
        routes.forEach((route) => {
            if (!route.parent && parent) {
                route.parent = parent;
            }

            if (route.children) {
                this.assignRouteParents(route.children, route);
            }
        });
    }

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.user.isTeamsPortal,
                async () => {
                    this.generateRoutes();
                }
            )
        ];
    }
}
