import { ButtonProps } from '@mui/material';
import { makeAutoObservable } from 'mobx';
import { component, initialize } from 'tsdi';
import { backdropProps } from '.';

// tslint:disable-next-line:no-any
type FirstParam<T> = T extends (arg1: infer U) => any ? U : any;

export type ModalSize = 'S' | 'M';

export interface ModalOptions<
    // tslint:disable-next-line:no-any
    C extends React.ComponentType<any> = any
> {
    componentProps?: Omit<FirstParam<C>, 'onModel'>;
    title?: React.ReactNode;
    cancelLabel?: string;
    size?: ModalSize;
    noContentSpacing?: boolean;
    closeButton?: boolean;
    onClose?(): void;
    noValidate?: boolean;
    customActions?: React.ReactNode;
    submit?: {
        label?: string;
        color?: ButtonProps['color'];
        disabled?: boolean;
        onAction?(
            model?: FirstParam<FirstParam<C>['onModel']>
        ): Promise<string | void>;
        onSuccess?(): void;
    };
}

@component
export class ModalStore {
    public open = false;

    public options!: ModalOptions;

    public Component?: React.ComponentType<any>;

    public error?: string;

    @initialize
    public init() {
        makeAutoObservable(this, {}, { autoBind: true });
        this.reset();
    }

    public onClose = () => {
        this.open = false;

        setTimeout(() => {
            if (this.open === false) {
                this.reset();
            }
        }, backdropProps.timeout);
    };

    public resetError() {
        this.error = undefined;
    }
    private reset() {
        this.open = false;
        this.Component = undefined;
        this.options = { submit: { label: 'Submit' } };
        this.error = undefined;
    }
}
