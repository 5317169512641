import 'core-js/features/array';
import 'core-js/features/promise';
import 'core-js/features/string';
import 'mobx-wrap-request';
import { render } from 'react-dom';
import { TSDI } from 'tsdi';
import 'whatwg-fetch';
import { App } from './app';
import { Api } from './core/api';
import { TsdiLifeCycle } from './core/reactions';
import { injectTSDI } from './core/tsdi';
import { UserStore } from './core/user';
import { workaroundProcessEnv } from './core/window';
import './fonts.css';
import { GatewayApi } from '@core/gateway-api';

const tsdi = new TSDI();

tsdi.enableComponentScanner();
injectTSDI(Api);
injectTSDI(GatewayApi);
injectTSDI(UserStore);
tsdi.get(TsdiLifeCycle);

workaroundProcessEnv();

render(<App />, document.getElementById('app'));
