import { observer } from 'mobx-react';
import * as React from 'react';

interface BrowserTitleProps {
    title?: string;
}

export const BrowserTitle = observer((props: BrowserTitleProps) => {
    const defaultTitle = 'Corporate Portal';

    React.useEffect(() => {
        const titles = [defaultTitle, props.title].filter((t) => Boolean(t));

        document.title = titles.join(' | ');

        return () => {
            document.title = defaultTitle;
        };
    }, [defaultTitle, props.title]);

    return <></>;
});
