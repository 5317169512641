import { FieldState } from 'formstate';
import * as React from 'react';

export interface FormField<T> {
    value: T;
    fromInput: string;
    error: string | undefined;
    onChange(value: T): void;
    hasValue(value: T): boolean;
}

export function wrapInFormField<T>(field: FieldState<T>): FormField<T> {
    return {
        get value(): T {
            return field.value;
        },

        set value(value: T) {
            field.onChange(value);
        },

        /** use when setting values from input-fields */
        set fromInput(value: string) {
            // tslint:disable-next-line:no-any
            field.onChange(value as any);
        },

        get error(): string | undefined {
            return field.error;
        },

        onChange(value: T): void {
            field.onChange(value);
        },

        hasValue(value: T): boolean {
            return this.value === value;
        }
    };
}

// tslint:disable-next-line:no-any
export function fieldHasValue<T = any>(field: FormField<T>, val: T): boolean {
    return field.value === val;
}

// tslint:disable-next-line:no-any cyclomatic-complexity
export function compare(valA: any, valB: any): boolean {
    if (valA === valB) {
        return true;
    }

    if (
        (valA === undefined || valA === null || valA === '') &&
        (valB === undefined || valB === null || valB === '')
    ) {
        return true;
    }

    return false;
}

// tslint:disable-next-line:no-any
export function hasValue(val: any): boolean {
    return val !== null && val !== undefined;
}

export function strToNumber(val: string): number | undefined {
    if (val === '') {
        return undefined;
    }

    return Number(val);
}

export function strToNumberStrict(val: string): number {
    if (val === '') {
        return 0;
    }

    return Number(val);
}

export function useModel<T>(
    fromFn: () => T,
    // tslint:disable-next-line:no-any
    props?: { onModel(model: any): void },
    deps: any[] = []
) {
    const model = React.useMemo<T>(fromFn, []);

    React.useEffect(() => {
        if (props) {
            props.onModel(model);
        }
    }, deps);

    return model;
}
