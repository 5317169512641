import {
    FormControl,
    SwitchProps as MaterialSwitchProps,
    Switch as MateriaSwitch
} from '@mui/material';
import { Label } from '../label';

interface SwitchProps extends MaterialSwitchProps {
    label: string;
    required?: boolean;
    error?: string;
    row?: boolean;
    'data-testid'?: string;
    disabled?: boolean;
}

export const Switch = ({
    label,
    required,
    checked,
    onChange,
    error,
    'data-testid': dataTestId,
    disabled
}: SwitchProps) => {
    return (
        <FormControl
            component="fieldset"
            required={required}
            data-testid={dataTestId}
            disabled={disabled}
        >
            <Label
                required={required}
                shrink
                error={Boolean(error)}
                variant="standard"
                sx={{ marginBottom: 0 }}
                disabled={disabled}
            >
                {label}
            </Label>

            <MateriaSwitch
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                data-testid={dataTestId}
            />
        </FormControl>
    );
};
