import { reaction, makeAutoObservable, runInAction } from 'mobx';
import { link, RouteConfig, Router as TakemeRouter } from 'takeme';
import { component, initialize, TSDI } from 'tsdi';
import { Api } from './api';
import { Route, Routes } from './routes';
import { TenantStore } from './tenant';
import { injectTSDI } from './tsdi';
import { Url } from './url';
import './window';
import './location';

export interface Breadcrumb {
    link: keyof Links;
    params: Params;
    title: string;
}

export interface RouteEntry {
    title: string;
    active: boolean;
    showInNav: boolean;
    externalSupportOnly?: boolean;
    divider?: boolean;
    Icon: Route['Icon'];
    link: keyof Links;
}

export interface Params {
    // tslint:disable-next-line:no-any
    [param: string]: any;
}

export interface Links {
    indexPrivate(): string;
    indexPublic(): string;
    home(): string;
    dashboard(): string;
    passwordSetPublic(tenant?: string, email?: string, code?: string): string;
    welcomePagePublic(tenant?: string, email?: string, code?: string): string;
    companyAccountPasswordSetPublic(
        tenant?: string,
        email?: string,
        code?: string
    ): string;
    passwordForgotPublic(): string;
    login(): string;
    promoter(): string;
    promoterData(): string;
    promoterUserAccounts(): string;
    employees(): string;
    employeesList(): string;
    participants(): string;
    participantsList(): string;
    participantsConfig(): string;
    employeeConfig(): string;
    employeeGroups(): string;
    employeeGroupEdit(id?: string): string;
    employeeGroupCreation(): string;
    servicePackages(): string;
    servicePackagesUnit(id?: string): string;
    servicePackageEdit(id?: string, unit?: string): string;
    servicePackageCreate(): string;
    servicePackageCreateWithBusinessUnit(id?: string): string;
    challenges(): string;
    challengeCreation(): string;
    challengeEdit(id?: string): string;
    challengeDetails(id?: string): string;
    challengesV2(): string;
    challengeCreationV2(): string;
    challengeEditV2(id?: string): string;
    challengePreviewV2(id?: string): string;
    templates(): string;
    templatesNews(): string;
    templatesNewsCreate(): string;
    templatesNewsEdit(id?: string): string;
    templatesNewsReleaseLog(id?: string): string;
    news(): string;
    newsCreate(): string;
    newsEdit(id?: string): string;
    sponsors(): string;
    sponsorCreate(): string;
    sponsorEdit(id?: string): string;
    organizers(): string;
    organizerCreate(): string;
    organizerEdit(id?: string): string;
    teams(): string;
    teamsLeague(id?: string): string;
    teamCreate(id?: string): string;
    teamEdit(id?: string, leagueId?: string): string;
    teamMembers(id?: string): string;
    teamEdit(id?: string, leagueId?: string): string;
    sponsorship(): string;
    sponsorshipConfig(): string;
    sponsorshipConfigDetail(id?: string): string;
    sponsorshipPayoutReports(): string;
    sponsorshipPayoutReportDetail(id?: string): string;
    sponsorshipPayoutReportDetailAction(id?: string): string;
    stats(): string;
    statsEmployeeRewards(): string;
    statsEmployeeParticipation(): string;
    statsOverview(): string;
    liveStatsTemplate(): string;
    test(id?: string): string;
}

export const links: Links = {
    indexPrivate: () => '/',
    indexPublic: () => '/',
    home: () => '/home',
    dashboard: () => '/dashboard',
    passwordSetPublic: (tenant = ':tenant', email = ':email', code = ':code') =>
        `/password-set/${tenant}/${email}/${code}`,
    welcomePagePublic: (tenant = ':tenant', email = ':email', code = ':code') =>
        `/welcome/${tenant}/${email}/${code}`,
    companyAccountPasswordSetPublic: (
        email = ':email',
        code = ':code',
        tenant = ':tenant'
    ) => `/company-account-password-set/${tenant}/${email}/${code}`,
    passwordForgotPublic: () => '/password-forgot',
    login: () => '/login',
    promoter: () => '/promoter',
    promoterData: () => '/promoter/data',
    promoterUserAccounts: () => '/promoter/user-accounts',
    employees: () => '/employees',
    employeesList: () => '/employees/list',
    participants: () => '/participants',
    participantsList: () => '/participants/list',
    participantsConfig: () => '/participants/config',
    employeeConfig: () => '/employees/config',
    employeeGroups: () => '/employees/groups',
    employeeGroupCreation: () => '/employees/groups/new',
    employeeGroupEdit: (id = ':id') => `/employees/groups/${id}`,
    teams: () => '/teams',
    teamsLeague: (id = ':id') => `/teams?league=${id}`,
    teamCreate: (leagueId = ':leagueId') => `/team-create?leagueId=${leagueId}`,
    teamEdit: (id = ':id', leagueId = ':leagueId') =>
        `/team-edit/${id}?leagueId=${leagueId}`,
    teamMembers: (id = ':id') => `/team-members/${id}`,
    servicePackages: () => '/employees/service-packages',
    servicePackagesUnit: (id = ':id') => `/employees/service-packages/${id}`,
    servicePackageEdit: (id = ':id') =>
        `/employees/service-packages-edit/${id}`,
    servicePackageCreate: () => `/employees/create-service-package`,
    servicePackageCreateWithBusinessUnit: (id = ':id') =>
        `/employees/create-service-package-with-business-unit/${id}`,
    challenges: () => '/employees/challenges',
    challengeCreation: () => '/employees/challenges/new',
    challengeEdit: (id = ':id') => `/employees/challenges/edit/${id}`,
    challengeDetails: (id = ':id') => `/employees/challenges/${id}`,
    challengePreviewV2: (id = ':id') => `/challenges/${id}`,
    challengesV2: () => '/challenges',
    challengeCreationV2: () => '/challenges/new',
    challengeEditV2: (id = ':id') => `/challenges/edit/${id}`,
    templates: () => '/templates/',
    templatesNews: () => '/templates/news',
    templatesNewsCreate: () => '/templates/news/create',
    templatesNewsEdit: (id = ':id') => `/templates/news/edit/${id}`,
    templatesNewsReleaseLog: (id = ':id') =>
        `/templates/news/release-log/${id}`,
    news: () => '/employees/news',
    newsEdit: (id = ':id') => `/employees/news/edit/${id}`,
    newsCreate: () => '/employees/news/create',
    sponsors: () => '/sponsors',
    sponsorEdit: (id = ':id') => `/sponsors/edit/${id}`,
    sponsorCreate: () => '/sponsors/create',
    organizers: () => '/organizers',
    organizerEdit: (id = ':id') => `/organizers/edit/${id}`,
    organizerCreate: () => `/organizers/create`,
    sponsorship: () => '/sponsorship',
    sponsorshipConfig: () => '/sponsorship/config',
    sponsorshipConfigDetail: (id = ':id') => `/sponsorship/config/${id}`,
    sponsorshipPayoutReports: () => '/sponsorship/payout-reports',
    sponsorshipPayoutReportDetail: (id = ':id') =>
        `/sponsorship/payout-reports/${id}`,
    sponsorshipPayoutReportDetailAction: (id = ':id') =>
        `/sponsorship/payout-reports/${id}/action`,
    stats: () => '/sponsorship/stats',
    statsEmployeeParticipation: () =>
        '/sponsorship/stats/employee-participation',
    statsEmployeeRewards: () => '/sponsorship/stats/employee-rewards',
    statsOverview: () => '/sponsorship/stats/overview',
    liveStatsTemplate: () => '/sponsorship/live-stats-template',
    test: (id = ':id') => `/test/${id}`
};

@component
export class AppRouter {
    private get tsdi() {
        return injectTSDI(TSDI);
    }
    private get url() {
        return injectTSDI(Url);
    }
    private get routes() {
        return injectTSDI(Routes);
    }

    private get location() {
        return injectTSDI(Location, 'Location');
    }
    private get window() {
        return injectTSDI(Window, 'Window');
        // if (typeof window !== 'undefined') {
        //     return injectTSDI(Window, 'Window');
        // } else {
        //     // Handle the case where Window is not defined
        //     // For example, you could return a mock object or throw a different error
        //     throw new Error('Window is not defined');
        // }
    }

    public route = '';

    public path = '';

    public params: Params = {};

    private locationSearch!: string;

    public router = new TakemeRouter(this.createRoutes());

    private scopes: string[] = [];

    @initialize
    public init(): void {
        makeAutoObservable(this, {}, { autoBind: true });

        this.router.init();

        reaction(
            () => this.route,
            (route) => this.manageScopes(route),
            { fireImmediately: true }
        );

        // Note: calling history.pushState() or history.replaceState() won't trigger a popstate event.
        // The popstate event is only triggered by performing a browser action,
        // such as clicking on the back button (or calling history.back() in JavaScript),
        // when navigating between two history entries for the same document.
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
        reaction(
            () => this.url.historyVersion,
            () => this.onpopstate()
        );

        this.locationSearch = this.location.search;
        this.window.onpopstate = this.onpopstate;
    }

    private onpopstate(): void {
        this.locationSearch = this.location.search;
    }

    private createRoute(route: string): RouteConfig {
        return {
            $: route,
            beforeEnter: async (ev) => {
                const { newPath, oldPath, params } = ev;
                if (params?.tenant) {
                    injectTSDI(TenantStore).tenant = params?.tenant;
                }

                const routeObj = this.getRouteObj(newPath);

                if (routeObj) {
                    if (routeObj.beforeEnter) {
                        let permissions;
                        if (routeObj.permissions) {
                            permissions = injectTSDI(Api).hasPermission(
                                routeObj.permissions
                            );
                        }
                        const beforeEnterResult = routeObj.beforeEnter({
                            newPath,
                            oldPath,
                            params,
                            permissions
                        });

                        if (beforeEnterResult) {
                            return beforeEnterResult;
                        }
                    }

                    const [publicRoute] = this.getRoutePath('indexPublic');
                    const publicLinks = (publicRoute.children || []).map(
                        (route) => route.link
                    );

                    if (
                        injectTSDI(Api).loggedIn === false &&
                        !publicLinks.some((link) => routeObj.link === link)
                    ) {
                        return {
                            redirect: links.login()
                        };
                    }
                }

                this.path = newPath;
                runInAction(() => {
                    this.params = this.cleanUpParams(params);
                });

                this.window.scrollTo(0, 0);

                return;
            },
            enter: (ev) => {
                runInAction(() => {
                    this.route = route;

                    const routeObj = this.getRouteObj(this.path);

                    if (routeObj && routeObj.enter) {
                        routeObj.enter(ev);
                    }
                });
            }
        };
    }

    private createRoutes(): RouteConfig[] {
        return Object.keys(links).map((key) =>
            this.createRoute(links[key as keyof Links]())
        );
    }

    private cleanUpParams(params: Params) {
        return Object.entries(params).reduce<Params>((acc, [key, value]) => {
            acc[key] = value.replace(/\?(.*)/, '');
            return acc;
        }, {});
    }

    private getRouteObj(path: string) {
        const link = this.getLink(path);

        if (link) {
            return this.getRoute(link);
        }

        return undefined;
    }

    public get createLink(): (
        path: keyof Links,
        ...params: (string | number)[]
    ) => string {
        // tslint:disable-next-line:no-any
        return (path, ...params: any[]) =>
            link(links[path](...params)).replace(
                /^\.\//,
                `.${this.location.pathname}${this.locationSearch}`
            );
    }

    public get currentLink(): keyof Links | undefined {
        return this.getLink(this.route);
    }

    public get isPrivatePath() {
        return this.getRoutePath().some(
            (route) => route.link === 'indexPrivate'
        );
    }

    public getLink(path: string) {
        const res = Object.entries(links).find(
            ([_, value]) => value() === path
        );

        if (res) {
            return res[0] as keyof Links;
        }

        return undefined;
    }

    public getRoutePath(
        link: keyof Links = this.currentLink || 'indexPublic'
    ): Route[] {
        const route = this.getRoute(link);
        const routes: Route[] = [];

        if (route) {
            routes.push(route);

            let parent = route.parent;

            while (parent) {
                routes.push(parent);

                parent = parent.parent;
            }
        }

        return routes.reverse();
    }

    public linkIsActive(link: keyof Links) {
        if (this.currentLink) {
            const routes = this.getRoutePath(this.currentLink);

            return routes.some((route) => route.link === link);
        }

        return false;
    }

    private enterScope(scope: string): void {
        if (!this.scopes.includes(scope)) {
            this.tsdi.getScope(scope).enter();

            this.scopes.push(scope);
        }
    }

    private leaveScope(scope: string, checkRoute = false): void {
        if (checkRoute && this.route === scope) {
            return;
        }

        if (this.scopes.includes(scope)) {
            this.tsdi.getScope(scope).leave();

            this.scopes = this.scopes.filter((s) => scope !== s);
        }
    }

    private manageScopes(route?: string): void {
        if (!route) {
            return;
        }

        // leave scopeIds
        const scopesToLeave = new Set<string>();
        const scopesToEnter = new Set<string>();

        Object.keys(links).forEach((key) => {
            const linkFn = links[key as keyof Links];
            const link = linkFn();
            const scope = route === link ? link : undefined;

            scope ? scopesToEnter.add(scope) : scopesToLeave.add(link);
        });

        scopesToLeave.forEach((scope) => this.leaveScope(scope));
        scopesToEnter.forEach((scope) => this.enterScope(scope));
    }

    public getRoute(
        link: keyof Links,
        routes = this.routes.routes
    ): Route | null {
        return routes.reduce((memo, route) => {
            if (memo) {
                return memo;
            }

            if (route.link === link) {
                return route;
            }

            if (route.children) {
                return this.getRoute(link, route.children);
            }

            return memo;
        }, null as Route | null);
    }

    public async createRouteEntries(
        link = this.currentLink!
    ): Promise<RouteEntry[]> {
        const { currentLink } = this;

        if (currentLink) {
            const routePath = this.getRoutePath(currentLink);
            const route = this.getRoute(link);

            if (route && route.children) {
                return (
                    await Promise.all(
                        route.children
                            .filter((route) => {
                                if (route.permissions) {
                                    return injectTSDI(Api).hasPermission(
                                        route.permissions
                                    );
                                }

                                return true;
                            })
                            .map(async (route) => {
                                const active = routePath.some(
                                    (item) => item.link === route.link
                                );
                                const title = await route.title?.();

                                return {
                                    title,
                                    active,
                                    Icon: route.Icon,
                                    showInNav: Boolean(route.showInNav),
                                    externalSupportOnly:
                                        route.externalSupportOnly,
                                    link: route.link,
                                    ...(route.divider && {
                                        divider: route.divider
                                    })
                                };
                            })
                    )
                ).filter((item) => item.title && item.showInNav);
            }
        }

        return [];
    }
}
