import { Alert, AlertTitle, Grid, Stack, Container } from '@mui/material';
import * as React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { BrowserTitle, Button } from '../components';
import { I18n } from '../core/i18n';
import { useMixPanel } from '../core/mixpanel';
import { injectTSDI } from '../core/tsdi';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    const { __ } = injectTSDI(I18n);
    const { track } = useMixPanel();
    console.log(error);
    React.useEffect(() => {
        track('www-error-boundary', { errorInfo: JSON.stringify(error) });
    }, []);
    return (
        <Container maxWidth="sm">
            <Grid container spacing={4} mt={4}>
                <BrowserTitle title="error" />
                <Grid item xs={12}>
                    <Grid container>
                        <Alert severity="error" sx={{ width: '100%' }}>
                            <AlertTitle>
                                {__('commons.error.runtime')}
                            </AlertTitle>
                            {error.message}
                        </Alert>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            sx={{ width: '100%' }}
                            mt={2}
                        >
                            <Button
                                fullWidth={false}
                                onClick={resetErrorBoundary}
                            >
                                {__('cp.reload.app')}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
