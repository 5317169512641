import { Grid, Typography } from '@mui/material';
import { ZonedDateTime } from '@ms-rewards/date-lib';
import { observer } from 'mobx-react';
import LocalizedTextField from '@components/textfield/localized_textfield';
import { getTimeZone } from '@core/config';
import { injectTSDI } from '@core/tsdi';
import { I18n } from '@core/i18n';
import { ChallengePresentationStore } from './store';
import { ImageModalSelect } from '@components/image-modal-select';
import LocalizedTextArea from '@components/text-area/localized-text-area';
import { Button } from '@components/button';
import { StepStatus } from '../store';
import { Stack } from '@mui/system';
import { SpinIcon } from '../challange-actions';
import { ChallengeDataStore } from '../data-store';
import { ChallengeRulesStore } from '../rules/store';

export const PresentationStep = observer(() => {
    const { disabledPresentationEdit } = injectTSDI(ChallengeDataStore);

    const {
        fields: { description, photo, prizeDescription, name },
        challengePhotos,
        imageUploadRequest,
        challengePresentationRequest,
        state,
        errors,
        updateChallengePresentation,
        onCollapsedToggle,
        validatePresentation
    } = injectTSDI(ChallengePresentationStore);

    const { openStep: openRulesStep } = injectTSDI(ChallengeRulesStore);

    const { __, formatShortMonth } = injectTSDI(I18n);

    const now = ZonedDateTime.now(getTimeZone());

    return (
        <Grid spacing={3} container>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <LocalizedTextField
                        type="text"
                        label={__('cp.challenge.field.name')}
                        placeholder={__(
                            'cp.challenge.field.name.placeholder',
                            formatShortMonth(now),
                            now.getFullYear()
                        )}
                        value={name.value}
                        onChange={(value) => {
                            name.onChange(value);
                            validatePresentation();
                        }}
                        disabled={disabledPresentationEdit}
                        error={name.error}
                        required
                        InputLabelShrink
                        data-testid="challenge-name-field"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ImageModalSelect
                    label={__('cp.challenge.field.photo')}
                    onChange={(value) => {
                        photo.onChange(value);
                        validatePresentation();
                    }}
                    value={photo.value}
                    options={challengePhotos.$}
                    request={imageUploadRequest}
                    error={photo.error}
                    disabled={disabledPresentationEdit}
                    required
                    dataTestId="challenge-photo-field"
                />
            </Grid>

            <Grid item xs={12}>
                <LocalizedTextArea
                    label={__('challenge.field.description')}
                    onChange={(value) => {
                        description.onChange(value);
                        validatePresentation();
                    }}
                    value={description.value}
                    error={description.error}
                    disabled={disabledPresentationEdit}
                    height={200}
                    dataTestId="challenge-description-field"
                />
            </Grid>
            <Grid item xs={12}>
                <LocalizedTextArea
                    label={__('cp.challenge.field.prize')}
                    onChange={(value) => {
                        prizeDescription.onChange(value);
                        validatePresentation();
                    }}
                    value={prizeDescription.value}
                    error={prizeDescription.error}
                    disabled={disabledPresentationEdit}
                    height={200}
                    dataTestId="prize-description-field"
                />
            </Grid>
            <Grid item xs={12} justifyContent="flex-end" display="flex">
                <Stack direction="row" spacing={2} alignItems="center">
                    {challengePresentationRequest.loading && (
                        <SpinIcon color="disabled" />
                    )}
                    {errors && <Typography color="error">{errors}</Typography>}
                    <Button
                        onClick={async () => {
                            const update = await updateChallengePresentation();
                            if (update) {
                                onCollapsedToggle();
                                openRulesStep();
                            }
                        }}
                        fullWidth={false}
                        disabled={
                            state === StepStatus.ERROR ||
                            state === StepStatus.COMPLETED ||
                            challengePresentationRequest.loading ||
                            disabledPresentationEdit
                        }
                    >
                        {__('challenge.presentation.save')}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
});
