/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CdnObject {
    bucketName: string;
    objectKey: string;
}

export interface I18NText {
    en?: string;
    de?: string;
    pl?: string;
    es?: string;
}

export enum TeamAccessibility {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED'
}

export interface TeamManagementUpdateRequestDto {
    name: string;
    displayName: I18NText;
    description: I18NText;
    image: CdnObject;
    visibility: TeamVisibility;
    accessibility: TeamAccessibility;
}

export enum TeamVisibility {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE'
}

export interface TeamManagementDto {
    /** @format int64 */
    id: number;
    name: string;
    displayName: I18NText;
    description: I18NText;
    image: CdnObject;
    visibility: TeamVisibility;
    accessibility: TeamAccessibility;
}

export interface LeagueRequestDto {
    name: string;
    displayName: I18NText;
}

export interface LeagueDto {
    /** @format int64 */
    id: number;
    name: string;
    displayName: I18NText;
}

export enum ChallengeActivityType {
    STEPS = 'STEPS',
    CYCLING = 'CYCLING',
    STUDIO_VISIT = 'STUDIO_VISIT'
}

export interface ChallengePersonalRuleSetDto {
    leaderboardEnabled: boolean;
    strategyConfig: ChallengeStrategyConfig;
    qualificationThreshold?: number;
}

export interface ChallengeRuleConfigDto {
    supportedActivityTypes: ChallengeActivityType[];
    dateTimeRange: ZonedDateTimeRangeDto;
    personalRuleSet?: ChallengePersonalRuleSetDto;
    teamRuleSet?: ChallengeTeamRuleSetDto;
}

export interface ChallengeStrategyConfig {
    strategy: ChallengeType;
    pointsGoal?: number;
    /** @format int32 */
    allowedWinnersCount?: number;
}

export interface ChallengeTeamRuleSetDto {
    leaderboardEnabled: boolean;
    strategyConfig: ChallengeStrategyConfig;
    teamIds: number[];
}

export enum ChallengeType {
    RACE = 'RACE',
    HIGH_SCORE = 'HIGH_SCORE'
}

export interface ZonedDateTimeRangeDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface CdnObjectDto {
    bucketName: string;
    objectKey: string;
}

export interface ChallengeDetailsManagementDto {
    /** @format int64 */
    id: number;
    name: string;
    state: ChallengeStateDto;
    presentation?: ChallengePresentationManagementDto;
    ruleConfig?: ChallengeRuleConfigDto;
}

export interface ChallengePresentationManagementDto {
    displayName: I18NText;
    description?: I18NText;
    prizeDescription?: I18NText;
    photo: CdnObjectDto;
}

export interface ChallengeStateDto {
    status: ChallengeStatus;
    /** @format date-time */
    publicationDateTime?: string;
    /** @format date-time */
    finishedDateTime?: string;
}

export enum ChallengeStatus {
    DRAFT = 'DRAFT',
    SCHEDULED = 'SCHEDULED',
    PUBLISHED = 'PUBLISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED'
}

export interface ChallengePublicationUpdateManagementDto {
    /** @format date-time */
    publishDateTime?: string;
    autoRenew: boolean;
}

export interface ChallengePresentationUpdateManagementDto {
    name: string;
    displayName: I18NText;
    description?: I18NText;
    prizeDescription?: I18NText;
    photo: CdnObjectDto;
}

export interface TeamManagementCreateRequestDto {
    /** @format int64 */
    leagueId: number;
    name: string;
    displayName: I18NText;
    description: I18NText;
    image: CdnObject;
    visibility: TeamVisibility;
    accessibility: TeamAccessibility;
}

export interface TeamMemberInviteEntryDto {
    userId: string;
    personalData: TeamMemberPersonalData;
}

export interface TeamMemberPersonalData {
    firstName: string;
    lastName: string;
    email: string;
}

export interface TeamMembersInviteRequestDto {
    entries: TeamMemberInviteEntryDto[];
}

export enum MembershipStatus {
    INVITED = 'INVITED',
    MEMBER = 'MEMBER'
}

export interface TeamMemberDto {
    /** @format int64 */
    id: number;
    userId: string;
    personalData: TeamMemberPersonalData;
    membershipStatus: MembershipStatus;
}

export interface ChallengeCreateRequestDto {
    name: string;
}

export interface PageTeamManagementPageEntryDto {
    content: TeamManagementPageEntryDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface TeamManagementPageEntryDto {
    /** @format int64 */
    id: number;
    name: string;
    /** @format int32 */
    memberCount: number;
    visibility: TeamVisibility;
    accessibility: TeamAccessibility;
}

export interface PageTeamMemberDto {
    content: TeamMemberDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface LeaguePageEntryDto {
    /** @format int64 */
    id: number;
    name: string;
}

export interface PageLeaguePageEntryDto {
    content: LeaguePageEntryDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export interface LeagueEntryDto {
    /** @format int64 */
    id: number;
    name: string;
    teams: LeagueTeamEntryDto[];
}

export interface LeagueTeamEntryDto {
    /** @format int64 */
    id: number;
    name: string;
}

export interface ChallengePageEntryDto {
    /** @format int64 */
    id: number;
    name: string;
    state?: ChallengeStateDto;
    presentation?: ChallengePresentationManagementDto;
    ruleConfig?: ChallengeRuleConfigDto;
}

export interface PageChallengePageEntryDto {
    content: ChallengePageEntryDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export enum ChallengeSubscriptionType {
    TEAM = 'TEAM',
    PERSONAL = 'PERSONAL'
}

export interface ChallengeProgressDto {
    pointsValue: number;
    pointsGoal: number;
    progress: number;
}

export interface LeaderboardEntryManagementDto {
    /** @format int64 */
    id: number;
    name: string;
    image: CdnObjectDto;
    /** @format int32 */
    position: number;
    progress: ChallengeProgressDto;
    qualifiedForWin: boolean;
    /** @format date-time */
    wonDate?: string;
    reward?: number;
}

export interface PageLeaderboardEntryManagementDto {
    content: LeaderboardEntryManagementDto[];
    /** @format int32 */
    number: number;
    /** @format int32 */
    size: number;
    /** @format int32 */
    totalPages: number;
    /** @format int64 */
    totalElements: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/challenge-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(
            typeof value === 'number' ? value : `${value}`
        )}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${
                queryString ? `?${queryString}` : ''
            }`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal: cancelToken
                    ? this.createAbortSignal(cancelToken)
                    : requestParams.signal,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl /challenge-api
 */
export class Api<SecurityDataType extends unknown> {
    http: HttpClient<SecurityDataType>;

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http;
    }

    management = {
        /**
         * No description
         *
         * @tags team-management-controller
         * @name TeamManagementControllerGetTeam
         * @request GET:/management/v1/teams/{id}
         * @secure
         * @response `200` `TeamManagementDto` OK
         */
        teamManagementControllerGetTeam: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<TeamManagementDto, any>({
                path: `/management/v1/teams/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-controller
         * @name TeamManagementControllerUpdateTeam
         * @request PUT:/management/v1/teams/{id}
         * @secure
         * @response `200` `TeamManagementDto` OK
         */
        teamManagementControllerUpdateTeam: (
            id: number,
            data: TeamManagementUpdateRequestDto,
            params: RequestParams = {}
        ) =>
            this.http.request<TeamManagementDto, any>({
                path: `/management/v1/teams/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-controller
         * @name TeamManagementControllerDeleteTeam
         * @request DELETE:/management/v1/teams/{id}
         * @secure
         * @response `204` `void` No Content
         */
        teamManagementControllerDeleteTeam: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/teams/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags league-management-controller
         * @name LeagueManagementControllerGetLeague
         * @request GET:/management/v1/leagues/{id}
         * @secure
         * @response `200` `LeagueDto` OK
         */
        leagueManagementControllerGetLeague: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<LeagueDto, any>({
                path: `/management/v1/leagues/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags league-management-controller
         * @name LeagueManagementControllerUpdateLeague
         * @request PUT:/management/v1/leagues/{id}
         * @secure
         * @response `200` `LeagueDto` OK
         */
        leagueManagementControllerUpdateLeague: (
            id: number,
            data: LeagueRequestDto,
            params: RequestParams = {}
        ) =>
            this.http.request<LeagueDto, any>({
                path: `/management/v1/leagues/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags league-management-controller
         * @name LeagueManagementControllerDeleteLeague
         * @request DELETE:/management/v1/leagues/{id}
         * @secure
         * @response `204` `void` No Content
         */
        leagueManagementControllerDeleteLeague: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/leagues/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerFillRuleConfig
         * @request PUT:/management/v1/challenges/{challengeId}/rule-config
         * @secure
         * @response `200` `ChallengeDetailsManagementDto` OK
         */
        challengeManagementControllerFillRuleConfig: (
            challengeId: number,
            data: ChallengeRuleConfigDto,
            params: RequestParams = {}
        ) =>
            this.http.request<ChallengeDetailsManagementDto, any>({
                path: `/management/v1/challenges/${challengeId}/rule-config`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerChangePublication
         * @request PUT:/management/v1/challenges/{challengeId}/publication
         * @secure
         * @response `200` `ChallengeDetailsManagementDto` OK
         */
        challengeManagementControllerChangePublication: (
            challengeId: number,
            data: ChallengePublicationUpdateManagementDto,
            params: RequestParams = {}
        ) =>
            this.http.request<ChallengeDetailsManagementDto, any>({
                path: `/management/v1/challenges/${challengeId}/publication`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerFillPresentation
         * @request PUT:/management/v1/challenges/{challengeId}/presentation
         * @secure
         * @response `200` `ChallengeDetailsManagementDto` OK
         */
        challengeManagementControllerFillPresentation: (
            challengeId: number,
            data: ChallengePresentationUpdateManagementDto,
            params: RequestParams = {}
        ) =>
            this.http.request<ChallengeDetailsManagementDto, any>({
                path: `/management/v1/challenges/${challengeId}/presentation`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-controller
         * @name TeamManagementControllerGetTeams
         * @request GET:/management/v1/teams
         * @secure
         * @response `200` `PageTeamManagementPageEntryDto` OK
         */
        teamManagementControllerGetTeams: (
            query?: {
                /** @format int64 */
                leagueId?: number;
                ids?: number[];
                tenantIds?: string[];
                membershipStatus?: MembershipStatus;
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<PageTeamManagementPageEntryDto, any>({
                path: `/management/v1/teams`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-controller
         * @name TeamManagementControllerCreateTeam
         * @request POST:/management/v1/teams
         * @secure
         * @response `201` `TeamManagementDto` Created
         */
        teamManagementControllerCreateTeam: (
            data: TeamManagementCreateRequestDto,
            params: RequestParams = {}
        ) =>
            this.http.request<TeamManagementDto, any>({
                path: `/management/v1/teams`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-member-controller
         * @name TeamManagementMemberControllerGetTeamMembers
         * @request GET:/management/v1/teams/{teamId}/members
         * @secure
         * @response `200` `PageTeamMemberDto` OK
         */
        teamManagementMemberControllerGetTeamMembers: (
            teamId: number,
            query?: {
                membershipStatuses?: MembershipStatus[];
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<PageTeamMemberDto, any>({
                path: `/management/v1/teams/${teamId}/members`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-member-controller
         * @name TeamManagementMemberControllerInviteTeamMembers
         * @request POST:/management/v1/teams/{teamId}/members
         * @secure
         * @response `200` `(TeamMemberDto)[]` OK
         */
        teamManagementMemberControllerInviteTeamMembers: (
            teamId: number,
            data: TeamMembersInviteRequestDto,
            params: RequestParams = {}
        ) =>
            this.http.request<TeamMemberDto[], any>({
                path: `/management/v1/teams/${teamId}/members`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-management-member-controller
         * @name TeamManagementMemberControllerRemoveTeamMembers
         * @request DELETE:/management/v1/teams/{teamId}/members
         * @secure
         * @response `204` `void` No Content
         */
        teamManagementMemberControllerRemoveTeamMembers: (
            teamId: number,
            query: {
                teamMemberIds: number[];
            },
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/teams/${teamId}/members`,
                method: 'DELETE',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags team-image-controller
         * @name TeamImageControllerUpload
         * @request POST:/management/v1/team-images
         * @secure
         * @response `200` `CdnObjectDto` OK
         */
        teamImageControllerUpload: (
            data: {
                /** @format binary */
                file: File;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<CdnObjectDto, any>({
                path: `/management/v1/team-images`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.FormData,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags league-management-controller
         * @name LeagueManagementControllerGetLeagues
         * @request GET:/management/v1/leagues
         * @secure
         * @response `200` `PageLeaguePageEntryDto` OK
         */
        leagueManagementControllerGetLeagues: (
            query?: {
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<PageLeaguePageEntryDto, any>({
                path: `/management/v1/leagues`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags league-management-controller
         * @name LeagueManagementControllerCreateLeague
         * @request POST:/management/v1/leagues
         * @secure
         * @response `201` `LeagueDto` Created
         */
        leagueManagementControllerCreateLeague: (
            data: LeagueRequestDto,
            params: RequestParams = {}
        ) =>
            this.http.request<LeagueDto, any>({
                path: `/management/v1/leagues`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerGetPage
         * @request GET:/management/v1/challenges
         * @secure
         * @response `200` `PageChallengePageEntryDto` OK
         */
        challengeManagementControllerGetPage: (
            query?: {
                statuses?: ChallengeStatus[];
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<PageChallengePageEntryDto, any>({
                path: `/management/v1/challenges`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerCreateDraft
         * @request POST:/management/v1/challenges
         * @secure
         * @response `201` `ChallengeDetailsManagementDto` Created
         */
        challengeManagementControllerCreateDraft: (
            data: ChallengeCreateRequestDto,
            params: RequestParams = {}
        ) =>
            this.http.request<ChallengeDetailsManagementDto, any>({
                path: `/management/v1/challenges`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-state-management-controller
         * @name ChallengeStateManagementControllerSchedule
         * @request POST:/management/v1/challenges/{id}/schedule-requests
         * @secure
         * @response `201` `void` Created
         */
        challengeStateManagementControllerSchedule: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/challenges/${id}/schedule-requests`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-state-management-controller
         * @name ChallengeStateManagementControllerConvertToDraft
         * @request POST:/management/v1/challenges/{id}/convert-to-draft-requests
         * @secure
         * @response `201` `void` Created
         */
        challengeStateManagementControllerConvertToDraft: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/challenges/${id}/convert-to-draft-requests`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-state-management-controller
         * @name ChallengeStateManagementControllerCancel
         * @request POST:/management/v1/challenges/{id}/cancel-requests
         * @secure
         * @response `201` `void` Created
         */
        challengeStateManagementControllerCancel: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/challenges/${id}/cancel-requests`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-image-management-controller
         * @name ChallengeImageManagementControllerGetDefaultImages
         * @request GET:/management/v1/challenge-images
         * @secure
         * @response `200` `(CdnObjectDto)[]` OK
         */
        challengeImageManagementControllerGetDefaultImages: (
            params: RequestParams = {}
        ) =>
            this.http.request<CdnObjectDto[], any>({
                path: `/management/v1/challenge-images`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-image-management-controller
         * @name ChallengeImageManagementControllerUpload
         * @request POST:/management/v1/challenge-images
         * @secure
         * @response `200` `CdnObjectDto` OK
         */
        challengeImageManagementControllerUpload: (
            data: {
                /** @format binary */
                file: File;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<CdnObjectDto, any>({
                path: `/management/v1/challenge-images`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.FormData,
                format: 'json',
                ...params
            }),

        /**
         * No description
         *
         * @tags league-management-controller
         * @name LeagueManagementControllerGetLeaguesEntries
         * @request GET:/management/v1/league-entries
         * @secure
         * @response `200` `(LeagueEntryDto)[]` OK
         */
        leagueManagementControllerGetLeaguesEntries: (
            params: RequestParams = {}
        ) =>
            this.http.request<LeagueEntryDto[], any>({
                path: `/management/v1/league-entries`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerGetDetails
         * @request GET:/management/v1/challenges/{id}
         * @secure
         * @response `200` `ChallengeDetailsManagementDto` OK
         */
        challengeManagementControllerGetDetails: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<ChallengeDetailsManagementDto, any>({
                path: `/management/v1/challenges/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-management-controller
         * @name ChallengeManagementControllerDelete
         * @request DELETE:/management/v1/challenges/{id}
         * @secure
         * @response `204` `void` No Content
         */
        challengeManagementControllerDelete: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.http.request<void, any>({
                path: `/management/v1/challenges/${id}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags challenge-leaderboard-management-controller
         * @name ChallengeLeaderboardManagementControllerGetChallengeLeaderboard
         * @request GET:/management/v1/challenges/{challengeId}/leaderboards/{type}
         * @secure
         * @response `200` `PageLeaderboardEntryManagementDto` OK
         */
        challengeLeaderboardManagementControllerGetChallengeLeaderboard: (
            challengeId: number,
            type: ChallengeSubscriptionType,
            query?: {
                /** @format int32 */
                page?: number;
                /** @format int32 */
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.http.request<PageLeaderboardEntryManagementDto, any>({
                path: `/management/v1/challenges/${challengeId}/leaderboards/${type}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            })
    };
}
