import CssBaseline from '@mui/material/CssBaseline';
import { createGlobalStyle } from 'styled-components';
import { ZendeskGlobalStyle } from '../core/zendesk';

const Global = createGlobalStyle`
  body {
    &.no-scroll{
        overflow-y: hidden !important;
    }
  }
  ol { 
    padding-left: 18px 
  }
  .mdxeditor-popup-container {
    z-index: 9999;
  }
  `;

export const GlobalStyles = () => (
    <>
        <CssBaseline />
        <ZendeskGlobalStyle />
        <Global />
    </>
);
