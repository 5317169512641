export const capitalizeFirstLetter = (str: string) => {
    if (!str) {
        return '';
    }
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const textCleanup = (str: string) => {
    if (!str) {
        return '';
    }
    return str.replace(/\\n/g, '<br />');
};
