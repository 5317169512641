import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Grid,
    IconButton,
    StyledEngineProvider,
    useMediaQuery
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { navigate } from 'takeme';
import { BrowserTitle } from '../components';
import { Spinner } from '../components/spinner';
import { Api } from '../core/api';
import { Modal } from '../core/modal';
import { AppRouter, links } from '../core/router';
import { injectTSDI } from '../core/tsdi';
import { UserStore } from '../core/user';
import '../core/window';
import { TourGuide } from '../tour-guide/tour-guide';
import { ErrorFallback } from './error-boundary';
import { GlobalStyles } from './global-styles';
import { Header } from './header';
import { drawerWidth, Nav } from './nav';
import { AppStore } from './store';
import { theme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

function getComponent(path: string) {
    const router = injectTSDI(AppRouter);
    const link = router.getLink(path);

    if (link) {
        const route = router.getRoute(link);

        if (route) {
            return route.Component || null;
        }
    }

    return null;
}

function getComponentProps() {
    const { route, params } = injectTSDI(AppRouter);
    const id = params.id ? parseInt(params.id, 10) : undefined;
    const defaultProps = { id };

    switch (route) {
        default:
            return defaultProps;
    }
}

const RouteContent = observer((props: { route: string }) => {
    const { Component, componentProps } = React.useMemo(
        () => ({
            Component: getComponent(props.route) as any,
            componentProps: getComponentProps()
        }),
        [props.route]
    );

    return (
        <React.Suspense fallback={<div />}>
            {Component ? <Component {...componentProps} /> : null}
        </React.Suspense>
    );
});

export const App = observer(() => {
    const { route, isPrivatePath } = injectTSDI(AppRouter);
    const appStore = injectTSDI(AppStore);
    const { initialDataLoaded, loggedIn } = injectTSDI(Api);
    const { tourGuideFeatEnabled, canEdit } = injectTSDI(UserStore);
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const isReady = !isPrivatePath || initialDataLoaded;

    const notiStackRef = React.createRef<SnackbarProvider>();

    function onErrorChange() {
        navigate(links.indexPublic());
    }

    const snackbarAction = (key: SnackbarKey) => (
        <IconButton
            color="secondary"
            component="span"
            onClick={() => {
                closeSnackbar(key);
            }}
        >
            <CloseIcon fontSize="small" sx={{ color: 'white' }} />
        </IconButton>
    );

    React.useEffect(() => {
        appStore.mobile = mobile;

        if (loggedIn) {
            if (mobile) {
                appStore.drawerOpen = false;
            }
        }
    }, [mobile]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
        >
            <StyledThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider
                            ref={notiStackRef}
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            action={snackbarAction}
                        >
                            <GlobalStyles />
                            {isReady ? (
                                <>
                                    {canEdit && tourGuideFeatEnabled && (
                                        <TourGuide />
                                    )}
                                    <BrowserTitle title="Home" />
                                    <ErrorBoundary
                                        FallbackComponent={ErrorFallback}
                                        onError={onErrorChange}
                                    >
                                        {isPrivatePath && (
                                            <>
                                                {loggedIn && <Header />}

                                                <Nav />
                                            </>
                                        )}
                                        <Box
                                            component="main"
                                            sx={{
                                                minHeight: '100vh',
                                                background:
                                                    theme.palette.background
                                                        .default,
                                                flexGrow: 1,
                                                transition:
                                                    isPrivatePath &&
                                                    appStore.drawerOpen
                                                        ? theme.transitions.create(
                                                              'margin',
                                                              {
                                                                  easing: theme
                                                                      .transitions
                                                                      .easing
                                                                      .easeOut,
                                                                  duration:
                                                                      theme
                                                                          .transitions
                                                                          .duration
                                                                          .enteringScreen
                                                              }
                                                          )
                                                        : theme.transitions.create(
                                                              'margin',
                                                              {
                                                                  easing: theme
                                                                      .transitions
                                                                      .easing
                                                                      .sharp,
                                                                  duration:
                                                                      theme
                                                                          .transitions
                                                                          .duration
                                                                          .leavingScreen
                                                              }
                                                          ),
                                                ...(isPrivatePath
                                                    ? {
                                                          marginLeft:
                                                              appStore.drawerOpen
                                                                  ? `${drawerWidth}px`
                                                                  : theme.spacing(
                                                                        7
                                                                    )
                                                      }
                                                    : { margin: 0 }),

                                                padding: loggedIn
                                                    ? theme.spacing(13, 3, 3, 3)
                                                    : 'initial'
                                            }}
                                        >
                                            <RouteContent route={route} />
                                        </Box>
                                        <Modal />
                                    </ErrorBoundary>
                                </>
                            ) : (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100vh' }}
                                >
                                    <Spinner />
                                </Grid>
                            )}
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </StyledThemeProvider>
        </LocalizationProvider>
    );
});
