import {
    BoldItalicUnderlineToggles,
    CreateLink,
    headingsPlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    maxLengthPlugin,
    MDXEditor,
    MDXEditorMethods,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/system';
import { interFont } from '@src/app/theme';
import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export interface TextAreaProps {
    label: string;
    value?: string;
    height?: number;
    required?: boolean;
    error?: string;
    placeholder?: string;
    dataTestId?: string;
    characterLimit?: number;
    disabled?: boolean;
    onChange(value: string): void;
}
interface ContainerProps {
    focused: boolean;
    error: boolean;
    height?: number;
}

const Container = styled(Stack)<ContainerProps>`
    --accentBase: var(--tomato-1);

    ${({ focused, error, height, theme }) => css`
        position: relative;

        &::before {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }
        &::after {
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            transform: scaleX(0);
            transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            border-bottom: 2px solid #11b5ec;
            pointer-events: none;

            transform: ${focused || error ? 'scaleX(1)' : ''};
            border-bottom-color: ${error
                ? theme.palette.error.main
                : theme.palette.primary.main};
        }

        .mdxeditor {
            font-family: ${interFont};
        }
        .mdxeditor-toolbar {
            border-radius: 4px;
        }

        ._contentEditable_uazmk_379 {
            padding: 0;
            min-height: ${height || 200}px;
        }
    `}
`;

export const TextArea = ({
    label,
    value,
    required,
    error,
    placeholder,
    dataTestId,
    disabled,
    characterLimit,
    onChange
}: TextAreaProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const ref = useRef<MDXEditorMethods>(null);

    return (
        <Container
            spacing={1}
            focused={isFocused}
            error={Boolean(error)}
            data-testid={dataTestId}
            onClick={() => {
                setIsFocused(true);
            }}
        >
            <InputLabel
                shrink
                error={Boolean(error)}
                required={required}
                focused={isFocused}
                disabled={disabled}
            >
                {label}
            </InputLabel>
            <MDXEditor
                ref={ref}
                markdown={value || ''}
                onChange={onChange}
                onBlur={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsFocused(false);
                }}
                readOnly={disabled}
                placeholder={placeholder}
                plugins={[
                    headingsPlugin(),
                    maxLengthPlugin(characterLimit),
                    linkPlugin(),
                    listsPlugin(),
                    linkDialogPlugin(),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                                <BoldItalicUnderlineToggles
                                    options={['Bold', 'Italic']}
                                />
                                <ListsToggle options={['bullet', 'number']} />
                                <CreateLink />
                                <UndoRedo />
                            </>
                        )
                    })
                ]}
            />
        </Container>
    );
};

export default TextArea;
