import CloseIcon from '@mui/icons-material/Close';
import {
    Backdrop,
    Fade,
    Grid,
    IconButton,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import MaterialModal from '@mui/material/Modal';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, ErrorMessage } from '../../components';
import { I18n } from '../i18n';
import { injectTSDI } from '../tsdi';
import { ModalOptions, ModalStore, ModalSize } from './store';

export const backdropProps = {
    timeout: 200
};

const defaultOnAction = async () => {
    return '';
};

export const Modal = observer(() => {
    const store = injectTSDI(ModalStore);
    const { enqueueSnackbar } = useSnackbar();
    const { options, open, resetError } = store;
    const { __ } = injectTSDI(I18n);
    const theme = useTheme();
    const [submitInProgress, setSubmitInProgress] = React.useState(false);
    const [model, setModel] = React.useState();
    const componentProps = options.componentProps || {};

    React.useEffect(() => {
        if (!open) {
            setModel(undefined);
        }
    }, [open]);

    React.useEffect(() => {
        if (store.error) {
            enqueueSnackbar(`${store.error}`, {
                variant: 'error',
                persist: true
            });
        }
    }, [store.error]);

    React.useEffect(() => {
        setSubmitInProgress(false);
    }, []);

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setSubmitInProgress(true);
        if (options.submit) {
            const { onAction = defaultOnAction } = options.submit;

            try {
                const error = await onAction(model);

                if (error) {
                    store.error = error;
                } else {
                    store.onClose();

                    if (options.submit.onSuccess) {
                        options.submit.onSuccess();
                    }
                }
            } catch (e: any) {
                store.error = e.message;
            } finally {
                setTimeout(() => {
                    setSubmitInProgress(false);
                }, 200);
            }
        }
    }

    function onClose() {
        options.onClose?.();
        store.onClose();
    }

    return (
        <MaterialModal
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={backdropProps}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: theme.shadows[5],
                        borderRadius: '4px',
                        maxWidth: '96%',
                        maxHeight: '75%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 10,
                        '&:focus': {
                            outline: 'none'
                        }
                    }}
                    style={{ width: getModalSize(options.size) }}
                >
                    <Box
                        component="form"
                        onSubmit={onSubmit}
                        sx={{
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column'
                        }}
                        noValidate={options.noValidate}
                    >
                        <Grid
                            sx={{
                                alignItems: 'center',
                                padding: theme.spacing(2),
                                position: 'sticky',
                                top: 0,
                                zIndex: 2,
                                background: '#fff'
                            }}
                            container
                            item
                            justifyContent="space-between"
                            style={{ padding: options.title ? 16 : 0 }}
                        >
                            {typeof options.title === 'string' ? (
                                <Typography
                                    variant="h5"
                                    sx={{
                                        paddingRight: 3
                                    }}
                                >
                                    {options.title}
                                </Typography>
                            ) : (
                                <React.Fragment key="title">
                                    {options.title}
                                </React.Fragment>
                            )}
                            {options.closeButton !== false && (
                                <Box position="absolute" right="16px" top="9px">
                                    <IconButton
                                        sx={{
                                            padding: theme.spacing(1),
                                            marginRight: theme.spacing(-1)
                                        }}
                                        onClick={onClose}
                                        size="large"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Grid>
                        <Box sx={{ overflowY: 'auto' }}>
                            <Grid
                                sx={{
                                    ...(options.noContentSpacing
                                        ? {}
                                        : { padding: theme.spacing(2) })
                                }}
                                xs={12}
                                item
                            >
                                {store.Component && (
                                    <store.Component
                                        onModel={setModel}
                                        resetError={resetError}
                                        {...(componentProps.onChange
                                            ? {
                                                  onChange:
                                                      componentProps.onChange
                                              }
                                            : {})}
                                        {...componentProps}
                                    />
                                )}
                            </Grid>
                        </Box>
                        {store.error && (
                            <Grid xs={12} pl={2} pr={2} mb={2} item>
                                <ErrorMessage data-testid="modal-error">
                                    {store.error}
                                </ErrorMessage>
                            </Grid>
                        )}
                        <Grid
                            sx={{
                                padding: `0 ${theme.spacing(2)} ${theme.spacing(
                                    2
                                )}`,
                                flex: '42px 0 0 '
                            }}
                            item
                            xs={12}
                            container
                        >
                            {options.customActions && (
                                <Box marginBottom={2}>
                                    {options.customActions}
                                </Box>
                            )}
                            <Grid
                                container
                                spacing={2}
                                paddingTop={options.submit ? 2 : 0}
                                justifyContent="flex-end"
                                flexDirection={
                                    options.submit ? 'row' : 'column'
                                }
                            >
                                {submitInProgress && (
                                    <Grid
                                        item
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <CircularProgress size={24} />
                                    </Grid>
                                )}

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        onClick={onClose}
                                        color="secondary"
                                    >
                                        {options.cancelLabel ||
                                            __('commons.cancel')}
                                    </Button>
                                </Grid>

                                {options.submit && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color={
                                                options.submit.color ||
                                                'primary'
                                            }
                                            type="submit"
                                            disabled={
                                                options.submit.disabled ||
                                                submitInProgress
                                            }
                                            capitalize
                                            data-testid="modal-submit"
                                        >
                                            {options.submit.label}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Fade>
        </MaterialModal>
    );
});

function getModalSize(size?: ModalSize): number {
    switch (size) {
        case 'M':
            return 800;
        default:
        case 'S':
            return 600;
    }
}

export function openModal<C extends React.ComponentType<any>>(
    Component: C,
    options: ModalOptions<C>
) {
    const store = injectTSDI(ModalStore);

    store.Component = undefined;
    store.options = options;
    store.Component = Component;
    store.open = true;

    return store;
}
