import { FieldState } from 'formstate';
import { I18n } from '../i18n';
import { injectTSDI } from './../tsdi';
import { I18nText } from '../api/dtos';
import { UserStore } from '../user';
import { ZonedDateTime, toZonedDateTime } from '@ms-rewards/date-lib';
import { getTimeZone } from '@core/config';
import { I18NText } from '@api/TenantClient';

export const required = <T = string | number | boolean | Date | undefined>(
    error: I18nKey = 'commons.validation.fields.required'
) => {
    const { __ } = injectTSDI(I18n);
    return (value: T) => {
        if (typeof value === 'number') {
            return isFinite(value) ? false : __(error);
        }

        return value ? false : __(error);
    };
};

export const requiredI18n = (
    error: I18nKey = 'commons.validation.fields.required'
) => {
    const { __ } = injectTSDI(I18n);
    const { mainLanguageKey } = injectTSDI(UserStore);
    return (value: I18nText | I18NText) => {
        const mainValue = value[mainLanguageKey];

        return mainValue ? false : __(error);
    };
};

export const allFieldsFilled = (fields: { [key: string]: FieldState<any> }) => {
    return Object.values(fields).reduce((acc, field) => {
        if (
            (field.error !== undefined && field.error !== null) ||
            !field.dirty ||
            !String(field.$)
        ) {
            acc = false;
        }
        return acc;
    }, true);
};

export const minLengthValidation = (value: string, length: number) => {
    const pattern = new RegExp(`^.{${length},}$`);
    return pattern.test(value);
};

export const numberValidation = (value: string) => /[\d]/.test(value);

export const whiteSpaceValidation = (value: string) => /\s/.test(value);

export const alphabeticalCharacterValidation = (value: string) =>
    /[a-z]|[A-Z]/.test(value);

export const atLeastOne = (message: string) => (val: any[]) =>
    !val.length && message;

export const positiveNumberValidation = () => {
    const { __ } = injectTSDI(I18n);
    return (value: number | undefined) => {
        if (value !== undefined) {
            return value > 0 ? false : __('commons.validation.positive');
        }
        return false;
    };
};

export const maxNumberValidation = (max: number) => {
    const { __ } = injectTSDI(I18n);
    return (value: number | undefined) => {
        if (value !== undefined) {
            return value > max
                ? __('commons.validation.max.number', max)
                : false;
        }
        return false;
    };
};

export const emailRegex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const validateEmail = (value: string) => value.match(emailRegex);

export const emailValidator =
    (error = 'errormessage.user.invalid.email') =>
    (value?: string): false | string => {
        if (value && !validateEmail(value)) {
            return error;
        }
        return false;
    };

export const emailsValidator = (values?: string[]): false | string => {
    const { __ } = injectTSDI(I18n);

    if (values && values.length > 0) {
        const errorCount = values.reduce<number>((acc, value) => {
            const valid = validateEmail(value);
            if (!valid) {
                return acc + 1;
            }
            return acc;
        }, 0);
        return errorCount > 0
            ? __('errormessage.user.invalid.emails', errorCount)
            : false;
    }
    return false;
};

export const futureDateValidation = () => {
    const { __ } = injectTSDI(I18n);
    return (value: ZonedDateTime | undefined) => {
        const now = toZonedDateTime(new Date(), getTimeZone()).subMinutes(1);

        if (value?.isBefore(now)) {
            return __('cp.challenge.field.start.end.date.error');
        }

        return false;
    };
};
