import {
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AppRouter, Links } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';
import { useRouteEntries } from './useRouteEntries';
import { Tooltip } from '../../components/tooltip';
import { I18n } from '../../core/i18n';

interface NavListProps {
    link: keyof Links;
    marginTop?: number;
}
export const NavList = observer(({ link, marginTop = 0 }: NavListProps) => {
    const router = injectTSDI(AppRouter);
    const { __ } = injectTSDI(I18n);
    const { routeEntries, onLinkClick } = useRouteEntries(link);

    if (routeEntries.length === 0) {
        return null;
    }

    return (
        <List sx={{ marginTop }}>
            {routeEntries.map((entry) => {
                const selected = router.linkIsActive(entry.link);

                return (
                    <React.Fragment key={entry.title}>
                        {entry.divider && (
                            <Divider
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    backgroundColor: 'rgba(255,255,255, 0.3)'
                                }}
                            />
                        )}
                        <ListItemButton
                            selected={selected}
                            onClick={() => void onLinkClick(entry.link)}
                            className={`menuItem-${entry.link}`}
                        >
                            {entry.Icon && (
                                <ListItemIcon>
                                    <entry.Icon color="secondary" />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                sx={{
                                    color: 'white',
                                    whiteSpace: 'nowrap'
                                }}
                                primary={entry.title}
                                primaryTypographyProps={{
                                    variant: 'body2'
                                }}
                            />
                            {entry.externalSupportOnly && (
                                <Tooltip
                                    title={__('cp.external.support.only')}
                                    showIcon={false}
                                >
                                    <SupportIcon
                                        fontSize="small"
                                        sx={{ color: 'mint.main' }}
                                    />
                                </Tooltip>
                            )}
                        </ListItemButton>
                    </React.Fragment>
                );
            })}
        </List>
    );
});
