import { TreeNode } from '.';

export function goThroughAllNodes(
    nodes: TreeNode[],
    map: Record<number, any> = {}
) {
    nodes.forEach((item) => {
        if (!item.children) {
            return;
        }
        map[item.id] = getAllChild(item).splice(1);
        for (let childNode of item.children) {
            goThroughAllNodes([childNode], map);
        }
    });

    return map;
}

function getAllChild(childNode: TreeNode | null, collectedNodes: any[] = []) {
    if (childNode === null) return collectedNodes;

    collectedNodes.push(childNode.id);

    if (Array.isArray(childNode.children)) {
        for (const node of childNode.children) {
            getAllChild(node, collectedNodes);
        }
    }

    return collectedNodes;
}

function getNodeById(node: TreeNode, id: number, parentsPath: number[]) {
    let result: any = null;

    if (node.id === id) {
        return node;
    } else if (Array.isArray(node.children)) {
        for (let childNode of node.children) {
            result = getNodeById(childNode, id, parentsPath);

            if (!!result) {
                parentsPath.push(node.id);
                return result;
            }
        }

        return result;
    }

    return result;
}

export const getChildById = (nodes: TreeNode[], id: number) => {
    let array: number[] = [];
    let path: number[] = [];

    // recursive DFS
    let nodeToToggle = null;
    nodes.forEach((item) => {
        const result = getNodeById(item, id, path);
        if (result) {
            nodeToToggle = result;
            return;
        }
    });
    return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
};

export const clearOutParentId = (node: TreeNode, values: number[]) => {
    if (!node.children) {
        return values;
    }
    return values.filter((value) => value !== node.id);
};
