import {
    FormControl,
    FormHelperText,
    IconButton,
    Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { round } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { CdnImageDto } from '../../core/api/dtos';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';
import { getImage } from '../../core/utils/image';
import { Label } from '../label';
import { Spinner } from '../spinner';
interface ImageContainerProps {
    background?: string;
}

const ImageContainer = styled.div<ImageContainerProps>`
    ${({ background }) =>
        css`
            background: #fff
                ${background &&
                `url(${background}) no-repeat
                center center`};
        `}
    height: 100%;
    width: 100%;
    position: relative;
    background-size: cover;
    cursor: pointer;
    &&:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        opacity: 0;
        transition: opacity 100ms;
    }
    &&:hover {
        &&:after {
            opacity: 1;
        }
    }
`;

interface ImageUploadProps {
    label: string;
    required?: boolean;
    loading?: boolean;
    error?: string;
    height?: string;
    maxHeight?: string;
    sizeLimit?: number;
    value?: CdnImageDto;
    dropAreaText?: string;
    disabled?: boolean;
    'data-testid'?: string;
    request?(formData: FormData, file: File): Promise<CdnImageDto | undefined>;
    onChange?(image?: CdnImageDto): void;
}

export const ImageUpload = observer((props: ImageUploadProps) => {
    const {
        height,
        onChange,
        required,
        error,
        label,
        request,
        value,
        maxHeight,
        sizeLimit,
        dropAreaText,
        loading,
        disabled
    } = props;
    const { __ } = injectTSDI(I18n);
    const theme = useTheme();
    const [image, setImage] = React.useState<CdnImageDto | undefined>(
        undefined
    );
    const [sizeError, setSizeError] = React.useState(false);

    const uploadImage = async (file: File) => {
        if (request) {
            if (sizeLimit && file.size > sizeLimit) {
                setSizeError(true);
            } else {
                const formData = new FormData();
                formData.append('file', file);

                setSizeError(false);
                const image = await request(formData, file);
                setImage(image);
            }
        }
    };

    React.useEffect(() => {
        if (onChange && image) {
            onChange(image);
        }
    }, [image]);

    const clearImage = (
        event: React.MouseEvent<HTMLLabelElement, MouseEvent>
    ) => {
        event.stopPropagation();
        onChange?.(undefined);
    };

    return (
        <FormControl
            required={required}
            error={Boolean(error)}
            disabled={disabled}
            data-testid={props['data-testid']}
            fullWidth
        >
            <Label
                required={required}
                shrink
                error={Boolean(error)}
                disabled={disabled}
            >
                {label}
            </Label>
            <Box position="relative">
                {loading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                            left: '50%',
                            zIndex: 2
                        }}
                    >
                        <Spinner />
                    </Box>
                )}
                {value && !disabled && (
                    <IconButton
                        color="primary"
                        component="label"
                        onClick={clearImage}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 3
                        }}
                        data-testid="image-cancel-button"
                    >
                        <CancelIcon
                            sx={{ color: 'primary.main' }}
                            fontSize="large"
                        />
                    </IconButton>
                )}
                <Box
                    sx={{
                        height: height || 300,
                        maxHeight,
                        position: 'relative'
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%'
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {value ? (
                            <Spinner />
                        ) : (
                            <DragDropFile
                                onChange={uploadImage}
                                dropAreaText={dropAreaText}
                            />
                        )}
                    </Box>

                    <ImageContainer
                        background={value && getImage(value)}
                        data-testid={`${props['data-testid']}-image-container`}
                    />
                </Box>
            </Box>
            {sizeLimit && sizeError && (
                <FormHelperText
                    sx={{
                        color: theme.palette.error.main,
                        marginLeft: 0,
                        marginRight: 0
                    }}
                >
                    {__('cp.image.size.error', round(sizeLimit / 1000000, 2))}
                </FormHelperText>
            )}
        </FormControl>
    );
});

interface DragDropFileProps {
    onChange(file: File): void;
    dropAreaText?: string;
}
function DragDropFile({ onChange, dropAreaText }: DragDropFileProps) {
    const [dragActive, setDragActive] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { __ } = injectTSDI(I18n);
    const areaText = dropAreaText || __('cp.file.drag.and.drop.text');
    const handleDrag: React.DragEventHandler<HTMLFormElement | HTMLDivElement> =
        function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === 'dragenter' || e.type === 'dragover') {
                setDragActive(true);
            } else if (e.type === 'dragleave') {
                setDragActive(false);
            }
        };

    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onChange(e.dataTransfer.files[0]);
        }
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = function (
        e
    ) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onChange(e.target.files[0]);
        }
    };

    return (
        <StyledForm onDragEnter={handleDrag}>
            <StyledFileUpload
                ref={inputRef}
                type="file"
                multiple={true}
                onChange={handleChange}
                id="input-file-upload"
                data-testid="input-file-upload"
            />
            <StyledLabel htmlFor="input-file-upload" dragActive={dragActive}>
                <Box>
                    <DownloadForOfflineIcon
                        color="action"
                        sx={{ fontSize: 65 }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {areaText}
                    </Typography>
                </Box>
            </StyledLabel>
            {dragActive && (
                <StyledDragFileElement
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></StyledDragFileElement>
            )}
        </StyledForm>
    );
}

const StyledForm = styled.form`
    z-index: 2;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;
    text-align: center;
`;

const StyledFileUpload = styled.input`
    display: none;
`;

const StyledLabel = styled.label<{ dragActive?: boolean }>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: ${({ dragActive }) => (dragActive ? '#f8fafc' : '#fff')};
    cursor: pointer;
    &:hover {
        background-color: rgba(17, 25, 39, 0.04);
    }
`;

const StyledDragFileElement = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;
