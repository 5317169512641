import MapIcon from '@mui/icons-material/Map';
import { ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import styled from 'styled-components';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';
import { AppStore } from '../store';
import { SectionTitle } from './section-nav-list';
import { UserStore } from '../../core/user';
import { observer } from 'mobx-react';
import { TourStore } from '../../tour-guide/tour-store';

const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const SupportNavItem = observer(() => {
    // const { toggleWidget } = injectTSDI(ZendeskStore);
    const { drawerOpen } = injectTSDI(AppStore);
    const { tourGuideFeatEnabled } = injectTSDI(UserStore);
    const { startTourGuide } = injectTSDI(TourStore);
    const { __ } = injectTSDI(I18n);
    const theme = useTheme();

    return (
        <ChatWrapper>
            {tourGuideFeatEnabled && (
                <ListItem
                    onClick={() => startTourGuide(true)}
                    sx={{ cursor: 'pointer' }}
                >
                    <ListItemIcon>
                        <MapIcon color="secondary" />
                    </ListItemIcon>

                    <ListItemText
                        color="secondary"
                        sx={{
                            whiteSpace: 'nowrap',
                            color: theme.palette.secondary.light
                        }}
                        primary={
                            <SectionTitle
                                open={drawerOpen}
                                title={__('cp.portal.tour')}
                                topLevel={false}
                            />
                        }
                        primaryTypographyProps={{
                            variant: 'body2'
                        }}
                    />
                </ListItem>
            )}

            {/* <ListItem onClick={toggleWidget} sx={{ cursor: 'pointer' }}>
                <Zendesk>
                    <ListItemIcon>
                        <ContactSupportIcon color="secondary" />
                    </ListItemIcon>

                    <ListItemText
                        color="secondary"
                        className={classes.listItemText}
                        primary={
                            <SectionTitle
                                open={drawerOpen}
                                title={__('cp.contact.support')}
                                topLevel={false}
                            />
                        }
                        primaryTypographyProps={{
                            variant: 'body2'
                        }}
                    />
                </Zendesk>
            </ListItem> */}
        </ChatWrapper>
    );
});
