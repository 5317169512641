import {
    Box,
    FormGroup,
    TextField as MaterialTextField,
    Typography
} from '@mui/material';
import { StandardTextFieldProps } from '@mui/material/TextField';
import * as React from 'react';
import { NumericFormat } from 'react-number-format';

import styled from 'styled-components';
import { Tooltip } from '../tooltip';

export interface TextFieldProps {
    className?: string;
    label?: string;
    type: 'email' | 'text' | 'number' | 'password';
    value: string | number;
    error?: string;
    placeholder?: string;
    rows?: number;
    required?: boolean;
    disabled?: boolean;
    size?: StandardTextFieldProps['size'];
    InputProps?: StandardTextFieldProps['InputProps'];
    InputLabelShrink?: boolean;
    suffix?: string;
    onKeyUp?: React.KeyboardEventHandler;
    onKeyDown?: React.KeyboardEventHandler;
    tooltipText?: string;
    helperText?: boolean;
    'data-testid'?: string;
    onChange(value: string): void;
    onBlur?(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void | undefined;
}
interface LabelWithTooltipProps {
    tooltipText: string;
    label?: string;
}

const StyleTextField = styled(MaterialTextField)`
    .MuiFormLabel-root {
        display: flex;
    }

    .MuiInputLabel-shrink {
        transform: translate(0, 0) scale(0.75);
        .MuiSvgIcon-root {
            margin-right: 6px;
            transform: scale(1.34) translateX(2px);
        }
    }
`;

const LabelWithTooltip = ({ tooltipText, label }: LabelWithTooltipProps) => (
    <Box display="inline-flex" alignItems="center">
        <Tooltip title={tooltipText} />
        <Typography color="textSecondary">{label}</Typography>
    </Box>
);

export const TextField = React.forwardRef(
    (props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => {
        const { suffix, type = 'text', InputProps = {}, rows } = props;

        return (
            <FormGroup>
                <StyleTextField
                    fullWidth
                    className={props.className}
                    required={props.required}
                    variant="standard"
                    label={
                        props.tooltipText ? (
                            <LabelWithTooltip
                                label={props.label}
                                tooltipText={props.tooltipText}
                            />
                        ) : (
                            props.label
                        )
                    }
                    InputLabelProps={{
                        shrink: props.InputLabelShrink
                    }}
                    size={props.size}
                    type={type === 'number' ? 'text' : type}
                    value={props.value}
                    disabled={props.disabled}
                    autoComplete="off"
                    error={Boolean(props.error)}
                    onChange={(e) => props.onChange(e.target.value)}
                    onBlur={props.onBlur}
                    inputRef={ref}
                    onKeyUp={props.onKeyUp}
                    onKeyDown={props.onKeyDown}
                    inputProps={{ suffix }}
                    placeholder={props.placeholder}
                    data-testid={props['data-testid']}
                    rows={rows}
                    multiline={rows !== undefined && rows > 0}
                    helperText={
                        props.helperText && Boolean(props.error) && props.error
                    }
                    InputProps={{
                        ...InputProps,
                        ...(type === 'number' && {
                            inputComponent: NumberFormatCustom as any
                        })
                    }}
                />
            </FormGroup>
        );
    }
);

interface NumberFormatCustomProps {
    suffix?: string;
    name: string;
    inputRef(instance: typeof NumericFormat<any> | null): void;
    onChange(event: { target: { name: string; value: string } }): void;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, suffix, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            valueIsNumericString
            suffix={suffix}
        />
    );
}
