import { ErrorResponse, ValidationErrorResponse } from '@api/dtos';

export function assertDefined<T>(
    val: T | undefined | null,
    msg?: string
): asserts val is T {
    if (val === undefined || val === null) {
        throw new Error(msg ?? `val cant be undefined or null`);
    }
}

function isValidationErrorResponse(
    error: any
): error is ValidationErrorResponse {
    return (error as ValidationErrorResponse).violations !== undefined;
}

function isErrorResponse(error: any): error is ErrorResponse[] {
    return Array.isArray(error) && error.length > 0 && error[0].errorMessage;
}

export function subtractErrorMessage(error: any): string | undefined {
    error = error.error || error;
    if (isErrorResponse(error)) {
        return error[0].defaultMessage;
    } else if (isValidationErrorResponse(error) && error.violations) {
        return error.violations
            .map((violation) => {
                return violation.message?.defaultMessage;
            })
            .filter((m) => Boolean(m))
            .join('<br>');
    } else if (error.defaultMessage) {
        return error.defaultMessage;
    } else if (error.message) {
        return error.message;
    }
    return undefined;
}
