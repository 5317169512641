import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { injectTSDI } from '@core/tsdi';
import { I18n } from '@core/i18n';
import { ChallengeRulesStore, DEFAULT_CHALLENGE_TIME } from './store';
import { CheckBoxGroup } from '@components/checkbox-group';
import { ChallengeActivityType } from '@core/api/ChallengeClient';
import { RangePicker } from '@components/range-picker';
import { toZonedDateTime } from '@ms-rewards/date-lib';
import { getTimeZone } from '@core/config';
import TeamRules from './team-rules';
import { Button } from '@components/button';
import { StepStatus } from '../store';
import { Stack } from '@mui/system';
import { SpinIcon } from '../challange-actions';
import { ChallengeDataStore } from '../data-store';

export const RulesStep = observer(() => {
    const { disabledRulesEdit } = injectTSDI(ChallengeDataStore);
    const {
        fields: { timeFrom, timeTo, supportedActivityTypes },
        challengeRulesRequest,
        validateRules,
        updateChallengeRules,
        state
    } = injectTSDI(ChallengeRulesStore);
    const { __ } = injectTSDI(I18n);

    return (
        <Grid spacing={3} container>
            <Grid item xs={12}>
                <RangePicker
                    startDate={timeFrom.value}
                    endDate={timeTo.value}
                    label={__('cp.challenge.field.start.end.date')}
                    timeRange={DEFAULT_CHALLENGE_TIME}
                    onChange={(range) => {
                        timeFrom.onChange(range?.startDate);
                        timeTo.onChange(range?.endDate);
                        validateRules();
                    }}
                    minDate={toZonedDateTime(new Date(), getTimeZone())
                        .subDays(1)
                        .toDate()}
                    error={
                        (timeFrom.error && __(timeFrom.error as I18nKey)) ||
                        (timeTo.error && __(timeTo.error as I18nKey))
                    }
                    disabled={disabledRulesEdit}
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <CheckBoxGroup<ChallengeActivityType>
                    label={__('challenge.field.activity.types')}
                    required
                    options={[
                        {
                            label: __('cp.steps'),
                            value: ChallengeActivityType.STEPS
                        },
                        {
                            label: __('cp.bike'),
                            value: ChallengeActivityType.CYCLING
                        },
                        {
                            label: __('cp.checkin'),
                            value: ChallengeActivityType.STUDIO_VISIT
                        }
                    ]}
                    value={supportedActivityTypes.value}
                    onChange={(value) => {
                        supportedActivityTypes.onChange(value);
                        validateRules();
                    }}
                    error={supportedActivityTypes.error}
                    disabled={disabledRulesEdit}
                    dataTestId="challenge-activity-types"
                />
            </Grid>
            <Grid item xs={12}>
                <TeamRules />
            </Grid>

            <Grid item xs={12} justifyContent="flex-end" display="flex">
                <Stack direction="row" spacing={2} alignItems="center">
                    {challengeRulesRequest.loading && (
                        <SpinIcon color="disabled" />
                    )}

                    <Button
                        onClick={() => {
                            updateChallengeRules();
                        }}
                        fullWidth={false}
                        disabled={
                            state === StepStatus.ERROR ||
                            state === StepStatus.COMPLETED ||
                            challengeRulesRequest.loading ||
                            disabledRulesEdit
                        }
                    >
                        {__('challenge.rules.save')}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
});
