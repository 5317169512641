import { Theme, useTheme } from '@mui/material';
import * as React from 'react';
import { navigate } from 'takeme';
import { Api } from '../../core/api';
import { I18n } from '../../core/i18n';
import { AppRouter, links, Links, RouteEntry } from '../../core/router';
import { injectTSDI } from '../../core/tsdi';
import { AppStore } from '../store';

export function useRouteEntries(link: keyof Links) {
    const { permissions } = injectTSDI(Api);
    const router = injectTSDI(AppRouter);
    const appStore = injectTSDI(AppStore);
    const i18n = injectTSDI(I18n);
    const theme = useTheme<Theme>();

    const [routeEntries, setRouteEntries] = React.useState<RouteEntry[]>([]);

    React.useEffect(() => {
        if (i18n.translationsReady) {
            (async () => {
                const entries = await router.createRouteEntries(link);

                setRouteEntries(entries);
            })();
        }
    }, [link, permissions, i18n.translationsReady]);

    function onLinkClick(link: keyof Links) {
        navigate(links[link]());

        if (appStore.mobile) {
            setTimeout(() => {
                appStore.drawerOpen = false;
            }, theme.transitions.duration.enteringScreen);
        }
    }

    return { routeEntries, onLinkClick };
}
