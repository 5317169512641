import * as React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CallBackProps, ACTIONS, EVENTS, StoreHelpers } from 'react-joyride';
import { navigate } from 'takeme';
import { component } from 'tsdi';
import { Api } from '../core/api';
import { injectTSDI } from '../core/tsdi';
import { UserStore } from '../core/user';
import {
    corporateFirstLoginTour,
    FullCallBackProps,
    FullStep,
    rewardsFirstLoginTour
} from './tours';
import { Typography } from '@mui/material';

@component
export class TourStore {
    public steps: FullStep[] = [];
    public stepIndex = 0;
    public run = false;
    public continuous = true;
    public scrollToFirstStep = true;
    public showProgress = true;
    public helpers?: StoreHelpers;
    public currentTourId?: string;

    private get user() {
        return injectTSDI(UserStore);
    }

    private get api() {
        return injectTSDI(Api);
    }

    constructor() {
        makeObservable(this, {
            steps: observable,
            setSteps: action,
            stepIndex: observable,
            setStepIndex: action,
            run: observable,
            setRun: action,
            continuous: observable,
            setContinuous: action,
            scrollToFirstStep: observable,
            setScrollToFirstStep: action,
            showProgress: observable,
            setShowProgress: action,
            callback: observable,
            setCallback: action
        });

        this.startTourGuide();
    }

    public getHelpers = (helpers: StoreHelpers) => {
        this.helpers = helpers;
    };

    public callback = (props: FullCallBackProps) => {
        const { action, type } = props;
        ``;
        if (action === ACTIONS.CLOSE || type === EVENTS.TOUR_END) {
            this.setRun(false);
            this.setStepIndex(0);
            document.body.classList.remove('no-scroll');
        } else if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
            this.handleNext(props);
        } else if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
            this.handleNext(props);
        }

        if (type === EVENTS.TOUR_END && this.currentTourId) {
            this.saveTourComplete();
        }
    };

    private handleNext = (props: FullCallBackProps) => {
        const { index, step, action } = props;

        runInAction(() => {
            this.setRun(false);
            const currentPath = step.path;
            const increment = action === ACTIONS.NEXT ? 1 : -1;
            const nextStep = this.steps[index + increment];
            const nextRoute = nextStep?.path;

            this.setStepIndex(index + increment);

            if (nextRoute && currentPath !== nextRoute) {
                navigate(nextRoute);
            }
            if (nextStep && !nextStep.manualTrigger) {
                setTimeout(() => {
                    document.body.classList.add('no-scroll');

                    this.setRun(true);
                }, 200);
            }
        });
    };

    public setSteps = (steps: FullStep[]) => {
        this.steps = steps.map((step) => {
            return {
                ...step,
                content: this.breakLines(step.content)
            };
        });
    };

    private breakLines = (node: React.ReactNode) => {
        if (!React.isValidElement(node) && typeof node === 'string') {
            return (
                <Typography variant="body1" whiteSpace="pre-line">
                    {node.replace(/\\n/g, '\n')}
                </Typography>
            );
        }
        return node;
    };

    public setStepIndex = (stepIndex: number) => {
        this.stepIndex = stepIndex;
    };

    public setRun = (run: boolean) => {
        this.run = run;
    };

    public setContinuous = (continuous: boolean) => {
        this.continuous = continuous;
    };

    public setScrollToFirstStep = (scrollToFirstStep: boolean) => {
        this.scrollToFirstStep = scrollToFirstStep;
    };

    public setShowProgress = (showProgress: boolean) => {
        this.scrollToFirstStep = showProgress;
    };

    public setCallback = (callback: (props: CallBackProps) => void) => {
        this.callback = callback;
    };

    private saveTourView = async () => {
        return await this.api.client.TourGuideController.view('FIRST_LOGIN');
    };

    private saveTourComplete = async () => {
        return await this.api.client.TourGuideController.complete(
            'FIRST_LOGIN'
        );
    };

    private checkTourState = async () => {
        const request = await this.api.client.TourGuideController.getStatus(
            'FIRST_LOGIN'
        );
        return request?.status;
    };

    public startTourGuide = async (force = false) => {
        const { isAdmin, isManager, tourGuideFeatEnabled } = this.user;

        if (force || ((isAdmin || isManager) && tourGuideFeatEnabled)) {
            const tour = this.user.isTeamsPortal
                ? rewardsFirstLoginTour()
                : corporateFirstLoginTour();
            this.currentTourId = tour.id;
            const tourState = await this.checkTourState();

            if (
                (tourState === 'VIEWED' || tourState === 'COMPLETED') &&
                !force
            ) {
                return;
            }

            this.setSteps(tour.steps);
            this.setStepIndex(0);
            document.body.classList.add('no-scroll');
            this.setRun(true);
            this.saveTourView();
        }
    };
}
