import { Box, FormControl, InputLabel } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from '../checkbox';

// tslint:disable-next-line:no-any
export interface Option<T = any> {
    label: string;
    value: T;
}

// tslint:disable-next-line:no-any
interface CheckBoxGroupProps<T = any> {
    label: string;
    options: Option<T>[];
    required?: boolean;
    disabled?: boolean;
    value?: T[];
    error?: string;
    dataTestId?: string;
    onChange(value: T[]): void;
}

const StyledInputLabel = styled(InputLabel)`
    position: static;
`;

export const CheckBoxGroup = <T extends any>({
    required,
    disabled,
    error,
    label,
    options,
    value = [],
    dataTestId,
    onChange
}: CheckBoxGroupProps<T>) => {
    const isChecked = (option: T) => value?.includes(option) || false;
    const changeHandler = (
        event: React.ChangeEvent<HTMLInputElement>,
        checkBoxValue: T
    ) => {
        if (event.target.checked) {
            return onChange([...value, checkBoxValue]);
        }
        return onChange(value.filter((item) => item !== checkBoxValue));
    };

    return (
        <FormControl
            component="fieldset"
            required={required}
            error={Boolean(error)}
            data-testid={dataTestId}
        >
            <StyledInputLabel
                required={required}
                shrink
                error={Boolean(error)}
                variant="standard"
            >
                {label}
            </StyledInputLabel>
            <Box display="flex">
                {options.map(({ label, value }) => (
                    <Box display="flex" mr={1} key={`${value}`}>
                        <Checkbox
                            checked={isChecked(value)}
                            center
                            disabled={disabled}
                            onChange={(event) => changeHandler(event, value)}
                        >
                            {label}
                        </Checkbox>
                    </Box>
                ))}
            </Box>
        </FormControl>
    );
};
