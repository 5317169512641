import {
    Card as MaterialCard,
    CardContent,
    CardHeader,
    SxProps
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { ContextMenu, ContextMenuOption } from '../context-menu';

interface CardProps {
    title?: string;
    contextMenu?: ContextMenuOption[];
    headerContent?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    overflowHidden?: boolean;
    showHeader?: boolean;
    fullHeight?: boolean;
    'data-testid'?: string;
    sx?: SxProps;
}

const StyledContainer = styled(MaterialCard)<{
    $overflowHidden?: boolean;
    $fullHeight?: boolean;
}>`
    height: 100%;
    height: ${({ $fullHeight }) => ($fullHeight ? '100%' : 'auto')};
    overflow: ${({ $overflowHidden }) =>
        $overflowHidden ? 'hidden' : 'visible'};
`;

export function Card(props: CardProps) {
    const {
        contextMenu,
        title,
        headerContent,
        children,
        className,
        sx = {},
        fullHeight = true,
        overflowHidden = true,
        showHeader = true
    } = props;

    return (
        <StyledContainer
            className={className}
            $overflowHidden={overflowHidden}
            $fullHeight={fullHeight}
            data-testid={props['data-testid']}
            sx={sx}
        >
            {showHeader && (
                <CardHeader
                    action={
                        contextMenu && contextMenu.length ? (
                            <>
                                {headerContent}
                                <ContextMenu items={contextMenu} />
                            </>
                        ) : (
                            headerContent
                        )
                    }
                    titleTypographyProps={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    sx={{
                        marginBottom:
                            !title && contextMenu?.length ? '-72px' : '0',
                        '& .MuiCardHeader-content': {
                            overflow: 'hidden'
                        }
                    }}
                    title={title}
                />
            )}
            <CardContent
                sx={{
                    '&:last-child': {
                        padding: 2
                    }
                }}
            >
                {children}
            </CardContent>
        </StyledContainer>
    );
}
