import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid
} from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { CdnImageDto } from '../../core/api/dtos';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';
import { getImage } from '../../core/utils/image';
import { Button } from '../button';
import { Label } from '../label';
import { ImageUpload } from '../image-upload';
import { ErrorMessage } from '../error';
import { WrapRequest } from 'wrap-request';
import { subtractErrorMessage } from '@core/utils/error';

interface ImageContainerProps {
    background?: string;
    hover?: boolean;
    disabled?: boolean;
    dataTestId?: string;
}

const ImageContainer = styled.div<ImageContainerProps>`
    ${({ background, hover, disabled }) =>
        css`
            background: #ffffff
                url(${background || '/assets/img/placeholder.jpg'}) no-repeat
                ${background ? 'top' : 'center'} center;
            background-size: contain;
            aspect-ratio: 16 / 9;
            max-width: 240px;
            border-radius: 4px;
            ${hover &&
            css`
                cursor: ${disabled ? 'default' : 'pointer'};
                &&:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.05);
                    opacity: 0;
                    transition: opacity 100ms;
                }
                &&:hover {
                    &&:after {
                        opacity: 1;
                    }
                }
            `}
        `}
    position: relative;
    background-size: cover;
    @media (max-height: 768px) {
        max-height: 40vh;
    }
`;

interface ImageSelectProps {
    label: string;
    options: CdnImageDto[];
    required?: boolean;
    value?: CdnImageDto;
    error?: string;
    dataTestId?: string;
    loading?: boolean;
    disabled?: boolean;
    request: WrapRequest<CdnImageDto, CdnImageDto>;
    onChange(value?: CdnImageDto): void;
}

const imageOptions = {
    resize: { width: 950, height: 580 }
};

export const ImageModalSelect = observer(
    ({
        required,
        error,
        label,
        value,
        options,
        dataTestId,
        request,
        disabled,
        onChange
    }: ImageSelectProps) => {
        const { __ } = injectTSDI(I18n);
        const [open, setOpen] = React.useState(false);

        // const headerRequest = useImageUpload('CUSTOM_CHALLENGE_PHOTO');

        const selectedImage =
            options.find((option) => option.objectKey === value?.objectKey) ||
            value;

        const [selection, setSelection] = React.useState<
            CdnImageDto | undefined
        >(undefined);

        React.useEffect(() => {
            if (selectedImage) {
                setSelection(selectedImage);
            }
        }, [selectedImage]);

        const handleClickOpen = () => {
            if (!disabled) {
                setOpen(true);
            }
        };

        const handleSave = () => {
            onChange(selection);
            handleClose();
        };

        const handleClose = () => {
            setOpen(false);
        };

        const handleSelection = (image?: CdnImageDto) => {
            if (disabled) {
                return;
            }
            setSelection(image);
            request.reset();
        };

        return (
            <FormControl
                required={required}
                error={Boolean(error)}
                disabled={disabled}
                fullWidth
                data-testid={`${dataTestId}`}
            >
                <Label required={required} shrink error={Boolean(error)}>
                    {label}
                </Label>
                <ImageContainer
                    disabled={disabled}
                    background={getImage(value, imageOptions)}
                    onClick={handleClickOpen}
                    data-testid={`${dataTestId}-button`}
                    hover
                />

                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogContent data-testid={`${dataTestId}-modal`}>
                        <ImageUpload
                            height="35vw"
                            maxHeight="400px"
                            label={__('cp.challenge.field.photo')}
                            onChange={(value) => void setSelection(value)}
                            request={(formData, file) =>
                                request.request([formData, file])
                            }
                            loading={request.loading}
                            error={error}
                            value={selection}
                            dropAreaText={__(
                                'cp.challenge.file.drag.and.drop.text'
                            )}
                            disabled={disabled}
                            required
                            data-testid="challenge-upload-photo-field"
                        />
                        <FormHelperText
                            sx={{
                                fontStyle: 'italic',
                                marginTop: '5px'
                            }}
                            variant="standard"
                        >
                            {__('cp.sponsor.aspect.ratio', '16x9')}
                        </FormHelperText>
                        {request.error && (
                            <Grid xs={12} item>
                                <ErrorMessage>
                                    {request.error.message ||
                                        subtractErrorMessage(request.error) ||
                                        __('user.image.error.upload')}
                                </ErrorMessage>
                            </Grid>
                        )}
                        <Box marginBottom={2} />
                        <ImageSelectionGallery
                            options={options}
                            selected={selection}
                            dataTestId={dataTestId}
                            onChange={handleSelection}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            variant="text"
                            fullWidth={false}
                        >
                            {__('commons.cancel')}
                        </Button>
                        <Button
                            onClick={handleSave}
                            fullWidth={false}
                            data-testid="save-image-button"
                        >
                            {__('commons.save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </FormControl>
        );
    }
);

interface ImageSelectionGalleryProps {
    options: CdnImageDto[];
    value?: string;
    selected?: CdnImageDto;
    dataTestId?: string;
    onChange?(option: CdnImageDto): void;
}

const ImageSelectionGallery = ({
    options,
    onChange,
    selected,
    dataTestId
}: ImageSelectionGalleryProps) => {
    return (
        <Box display="flex" flexDirection="column" height="calc(90vh - 85px)">
            <ThumbnailsContainer item xs={12}>
                <Grid container spacing={2}>
                    {options.map((option) => {
                        const selectedImage =
                            option.objectKey === selected?.objectKey;

                        return (
                            <Grid item xs={6} md={4} key={option.objectKey}>
                                <ImageItem
                                    image={getImage(option, imageOptions) || ''}
                                    selected={selectedImage}
                                    onClick={() => onChange?.(option)}
                                    data-testid={`${dataTestId}-image`}
                                >
                                    {selectedImage && (
                                        <StyledCheckIcon color="primary" />
                                    )}
                                </ImageItem>
                            </Grid>
                        );
                    })}
                </Grid>
            </ThumbnailsContainer>
        </Box>
    );
};
const ThumbnailsContainer = styled(Grid)`
    overflow-y: auto;
    flex: 3;
`;

interface ImageItemProps {
    image: string;
    selected?: boolean;
}

const ImageItem = styled.div<ImageItemProps>`
    ${({ image, selected, theme }) => css`
        position: relative;
        background: #fff url(${image || '/assets/img/placeholder.jpg'})
            no-repeat top center;
        background-size: cover;
        box-shadow: ${selected
            ? `inset 0px 0px 0px 6px ${theme.palette.primary.main}`
            : 'none'};
        opacity: ${selected ? '1' : '0.7'};

        height: 18vw;
        ${(props) => props.theme.breakpoints.up('md')} {
            height: 150px;
        }
        cursor: pointer;
        &&:hover {
            opacity: 1;
        }
    `}
    &&:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.05);
        opacity: 0;
        transition: opacity 100ms;
    }
    &&:hover {
        &&:after {
            opacity: 1;
        }
    }
`;

const StyledCheckIcon = styled(CheckCircleIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
`;
