import { Box } from '@mui/material';
import { CallBackProps, Step } from 'react-joyride';
import { MSLogoVertical } from '../components/svg-icons/ms-logo-vertical';
import { I18n } from '../core/i18n';
import { injectTSDI } from '../core/tsdi';
import { textCleanup } from '../core/utils/strings';
export interface FullStep extends Step {
    path: string;
    manualTrigger?: boolean;
}

export interface FullCallBackProps extends Omit<CallBackProps, 'step'> {
    step: FullStep;
}

export interface Tour {
    id: string;
    steps: FullStep[];
    callback?(props: FullCallBackProps): void;
}

export const rewardsFirstLoginTour = (): Tour => {
    const { __ } = injectTSDI(I18n);

    return {
        id: 'FIRST_LOGIN',
        steps: [
            {
                content: (
                    <div>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mb={4}
                        >
                            <MSLogoVertical />
                        </Box>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: textCleanup(__('cp.tour.intro.welcome'))
                            }}
                        ></span>
                    </div>
                ),
                placement: 'center',
                target: 'body',
                path: '/company/user-accounts'
            },
            {
                content: __('cp.tour.rewards.intro.participants.menu'),
                placement: 'right-start',
                target: '.menuItem-participantsList',
                path: '/participants/list'
            },
            {
                content: __('cp.tour.rewards.intro.participants.invite'),
                placement: 'bottom-start',
                target: '.btn-participants-invite',
                path: '/participants/list'
            },
            {
                content: __(
                    'cp.tour.rewards.intro.participants.servicepackages'
                ),
                placement: 'right-end',
                target: '.menuItem-participantsList',
                path: '/participants/list'
            },
            {
                content: __('cp.tour.rewards.intro.participants.sponsoring'),
                placement: 'right-end',
                target: '.menuItem-participantsList',
                path: '/participants/list'
            },
            {
                content: __('cp.tour.rewards.intro.news.menu'),
                placement: 'right-end',
                target: '.menuItem-news',
                path: '/employees/news'
            },
            {
                content: __('cp.tour.rewards.intro.challenges.menu'),
                placement: 'top-start',
                target: '.menuItem-challenges',
                path: '/employees/challenges'
            },
            {
                content: __('cp.tour.rewards.intro.access.config.menu'),
                placement: 'right-start',
                target: '.menuItem-participantsConfig',
                path: '/participants/config'
            },
            {
                content: __('cp.tour.rewards.intro.access.config.qr.code'),
                placement: 'top-start',
                target: '.btn-qr-code-download',
                path: '/participants/config'
            }
        ]
    };
};

export const corporateFirstLoginTour = (): Tour => {
    const { __ } = injectTSDI(I18n);

    return {
        id: 'FIRST_LOGIN',
        steps: [
            {
                content: (
                    <div>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mb={4}
                        >
                            <MSLogoVertical />
                        </Box>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: textCleanup(__('cp.tour.intro.welcome'))
                            }}
                        ></span>
                    </div>
                ),
                placement: 'center',
                target: 'body',
                path: '/company/user-accounts'
            },
            {
                content: __('cp.tour.intro.employee.menu'),
                placement: 'top-start',
                target: '.menuItem-employeesList',
                path: '/employees/list'
            },
            {
                content: __('cp.tour.intro.access.config.menu'),
                placement: 'top-start',
                target: '.menuItem-employeeConfig',
                path: '/employees/config'
            },
            {
                content: __('cp.tour.intro.access.config.qr.code'),
                placement: 'top-start',
                target: '.btn-qr-code-download',
                path: '/employees/config'
            }
        ]
    };
};
