import { AddressDto } from '../../core/api/dtos';

interface AddressProps {
    address?: AddressDto;
}

export const Address = (props: AddressProps) => {
    const { address } = props;

    if (address) {
        return (
            <address>
                {address.street} {address.houseNumber}
                <br />
                {address.zipCode} {address.city}
                <br />
                {address.country}
            </address>
        );
    }

    return null;
};
