import * as React from 'react';
import { computed, makeObservable, reaction } from 'mobx';
import { Component } from 'tsdi';
import { PresentationStep } from './presentation';
import { injectTSDI } from '@core/tsdi';
import { I18n } from '@core/i18n';
import { ChallengePresentationStore } from './presentation/store';
import { bind } from 'lodash-decorators';
import { ChallengeRulesStore } from './rules/store';
import { RulesStep } from './rules';
import {
    ChallengeDetailsManagementDto,
    ChallengeStatus
} from '@core/api/ChallengeClient';
import { autodisposer } from '@core/reactions';
import { ChallengeDataStore } from './data-store';

export enum StepName {
    PRESENTATION = 'PRESENTATION',
    RULES = 'RULES'
}

export enum StepStatus {
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    MODIFIED = 'MODIFIED',
    UPDATING = 'UPDATING',
    ERROR = 'ERROR'
}
interface StepProps {
    name: string;
    key: StepName;
    error?: string;
    status: StepStatus;
    isDirty: boolean;
    isActive: boolean;
    Content: React.ComponentType;
}

@Component
export class ChallengeStore {
    constructor() {
        makeObservable(this, {
            steps: computed,
            isDirty: computed
        });
    }

    private presentationStore = injectTSDI(ChallengePresentationStore);
    private rulesStore = injectTSDI(ChallengeRulesStore);
    private challengeDataStore = injectTSDI(ChallengeDataStore);

    private get __() {
        return injectTSDI(I18n).__;
    }

    public get steps(): Record<StepName, StepProps> {
        return {
            PRESENTATION: {
                name: this.__('challenge.presentation'),
                key: StepName.PRESENTATION,
                status: this.presentationStore.state,
                isDirty: this.presentationStore.formIsDirty,
                isActive: this.presentationStore.isActive,
                Content: PresentationStep
            },
            RULES: {
                key: StepName.RULES,
                name: this.__('challenge.rules'),
                status: this.rulesStore.state,
                isDirty: this.rulesStore.formIsDirty,
                isActive: this.rulesStore.isActive,
                Content: RulesStep
            }
        };
    }
    public get stepsMap() {
        return Object.values(this.steps);
    }
    public get isUpdating() {
        return this.stepsMap.some(
            (step) => step.status === StepStatus.UPDATING
        );
    }
    public get isDirty() {
        return this.stepsMap.some((step) => step.isDirty);
    }
    public get hasErrors() {
        return this.stepsMap.some((step) => step.status === StepStatus.ERROR);
    }
    public get isModified() {
        return this.stepsMap.some(
            (step) => step.status === StepStatus.MODIFIED
        );
    }
    public get allStepsNotCompleted() {
        return this.stepsMap.some(
            (step) => step.status !== StepStatus.COMPLETED
        );
    }

    public static challengeStatusContains(
        permission: ChallengeStatus[],
        status?: ChallengeStatus
    ): boolean {
        if (status && permission.some((option) => option === status)) {
            return false;
        }

        return true;
    }

    @bind
    public async validateAllForms() {
        const presentationValidation =
            await this.presentationStore.form.validate();
        const rulesValidation = await this.rulesStore.validateRules();

        return presentationValidation.hasError || rulesValidation;
    }

    @bind
    private setStepStatus(
        configuration: ChallengeDetailsManagementDto | undefined
    ) {
        if (!configuration?.presentation) {
            this.presentationStore.openStep();
            this.rulesStore.closeStep();
        } else if (!configuration.ruleConfig) {
            this.presentationStore.closeStep();
            this.rulesStore.openStep();
        } else {
            this.presentationStore.openStep();
            this.rulesStore.closeStep();
        }
    }

    @bind
    public onStepToggle(step: StepName) {
        if (step === StepName.PRESENTATION) {
            this.presentationStore.onCollapsedToggle();
        } else if (step === StepName.RULES) {
            this.rulesStore.onCollapsedToggle();
        }
    }

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.challengeDataStore.challengeConfiguration,
                async (configuration) => {
                    if (configuration) {
                        this.setStepStatus(configuration);
                    }
                }
            )
        ];
    }
}
