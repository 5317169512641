import LanguageSelect from '../language-select';
import { Box } from '@mui/material';
import { useLocalizedField } from '../hooks/use-localised-field';
import { TextArea, TextAreaProps } from '.';
import { injectTSDI } from '../../core/tsdi';
import { UserStore } from '../../core/user';
import { I18nText } from '../../core/api/dtos';

interface LocalizedTextAreaProps
    extends Omit<TextAreaProps, 'value' | 'onChange'> {
    value: I18nText;
    disabled?: boolean;
    onChange(value: I18nText): void;
}

const LocalizedTextArea = ({
    value,
    onChange,
    disabled,
    ...props
}: LocalizedTextAreaProps) => {
    const { supportedLanguageOptions, mainLanguageKey } = injectTSDI(UserStore);

    const { options, language, setLanguage } = useLocalizedField(
        value,
        supportedLanguageOptions,
        mainLanguageKey
    );

    function handleOnChange(e: string, key: string) {
        onChange({ ...value, [key]: e });
    }

    return (
        <Box position="relative" data-testid={`${props.dataTestId}-language`}>
            <Box
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    zIndex: 8
                }}
            >
                <LanguageSelect
                    options={options}
                    value={language}
                    onChange={(language) => {
                        setLanguage(language);
                    }}
                />
            </Box>
            {value &&
                Object.entries(value).map(([key, value]) => (
                    <Box
                        sx={{ display: language === key ? 'block' : 'none' }}
                        key={key}
                        data-testid={`${key}-sponsor-description-field`}
                    >
                        <TextArea
                            {...props}
                            value={value}
                            onChange={(e) => {
                                handleOnChange(e, key);
                            }}
                            disabled={disabled}
                        />
                    </Box>
                ))}
        </Box>
    );
};

export default LocalizedTextArea;
